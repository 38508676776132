.container {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-self: center;
    gap: 20px;
    padding: 120px 0 60px;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    padding-top: 80px;
}

.sortcont {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    gap: 13px;
}

.sortcont_am {
    width: 35%;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
}

.sort {
    width: 100%;
    padding: 13px 15px;
    background: #243745;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    font-size: 23px;
    color: #728796;
    transition: 0.2s;
    cursor: pointer;
}

.rotate {
    rotate: -180deg;
    transition: 0.2s;
}

.filter {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #728796;
    display: flex;
}

.img {
    width: 19px;
    transform: translateY(3px);
    cursor: pointer;
}

.slider {
    padding-top: 40px;
}

.cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.pricecont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 20px;
}


.con {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blue {
    width: 30px;
    border-radius: 0 12px 12px 0;
    background: #162A38;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 0;
}

.filtercont {
    width: 100%;
    background: #243745;
    border-radius: 32px;
    position: absolute;
    top: 60px;
    padding: 25px 30px;
    border: 1px solid #728796;
    z-index: 10;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.item {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    color: #EBEBEB;
    cursor: pointer;
}

.item:hover {
    color: #FFC850;
}

.item:active {
    color: #FFB81E;
}

.disabled {
    opacity: .6;
    pointer-events: none;
}

.filtcont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    padding: 15px 25px 60px;
    background: #243745;
    border-radius: 32px;
}
.clear {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #728796;
    cursor: pointer;
}

.priceText {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #728796;
}

.clear:hover {
    color: #FFC850;
}

.level {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(98, 119, 133, 0.3);
}

.dollar {
    font-family: var(--main-font-family),sans-serif;
    font-size: 20px;
    color: #728796;
}

.checkcont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
}

.title {
    font-family: var(--main-font-family),sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.coursecont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    gap: 30px;
}

.typecont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    padding: 13px 0 13px 50px;
    background: #243745;
    border-radius: 32px;
}

.course {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 10px;
    height: 100%;
}

.linkcont {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-self: center;
}

.button {
    padding: 7px 14px;
    color: #FFFFFF;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    background: #243745;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    align-self: center;
}

.button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

 .button:hover{
    background: #2F495C;
}

.active {
    background: #2F495C;
    border: 1px solid #728796;
}

.selectedItem {
    color: #FFB81E;
}

.close {
    width: 25px;
    align-self: flex-end;
}

.filtresp {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(36, 55, 69, 0.5);
    z-index: 50;
}

.filcon {
    position: absolute;
    bottom: 0;
    top: 57px;
    width: 100%;
    left: 50%;
    overflow-y: scroll;
    transform: translateX(-50%);
    background: #243745;
}

.priceInput {
    border: none;
    outline: none;
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    -moz-appearance: textfield;
    appearance: textfield;
    width: 70px;
    border-radius: 12px 0 0 12px;
    background: #12222D;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 0;
}

.priceInput::-webkit-inner-spin-button,
.priceInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.priceInput::placeholder {
    color: #728796;
}

.selected {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #EBEBEB;
    cursor: pointer;
}

.selected_am {
    width: 51%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.like {
    width: 35px;
    cursor: pointer;
    position: absolute;
    right: 35px
}
.likeds {
    width: 35px;
    cursor: pointer;
}

.noCourseContainer {
    width: 100%;
    height: 100%;
    background: #243745;
    border-radius: 32px;
}


.noCourse {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 180px
}

.noCourseGirl {
    width: 40%;
}

.courseMessage {
    font-family: var(--english-font-teko), 'sans-serif';
    font-size: 57px;
    font-weight: 500;
    text-align: center;
    margin-right: -50px;
    color: #EBEBEB;
}


.loaderContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

@media only screen and (max-width: 1155px) {
    .container {
        padding: 100px 0 50px;
    }
}

@media only screen and (max-width: 1360px) {
    .course {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1000px) {
    .course {
        grid-template-columns: 1fr;
        Justify-items: center
    }

    .sortcont {
        width: 50%;
    }

    .typecont {
        align-self: center;
    }

}

@media only screen and (max-width: 780px)    {
    .courseMessage {
        font-family: var(--english-font-teko), 'sans-serif';
        font-size: 40px;
    }

    .container {
        margin-top: 100px;
    }

    .noCourse {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        padding-bottom: 120px;
    }
    .courseMessage_am {
        font-family: var(--english-font-teko), 'sans-serif';
        font-size: 37px;
        font-weight: 500;
        text-align: center;
        margin-right: -50px;
    }
    .container {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 80px;
    }


    .sortcont {
        width: 100%;
        align-self: center;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

.sortcont_am {
    width: 100%;
    align-self:start;
    justify-content: left;
    align-items: center;
    flex-direction: row;
}
    .selected{
        margin-left: 0;
    }
    .sort {
        width: 70%;
        padding: 7px 10px;
    }

.filter_am{
    font-size: 12px;
}
    .filterimg {
        width: 30px;
        margin-right: 20px;
    }

    .typecont {
        width: 100%;
        border: none;
        background: none;
        justify-content: center;
        padding: 0;
        gap: 15px;
    }

    .sorted {
        font-size: 16px !important;
    }
    .selected_am{
        font-size:12px !important;
    }
    .item {
        font-size: 13px;
    }
    .filtercont {
        width: 60%;
    }

    .filtcont {
        border-radius: 0;
        position: absolute;
        padding: 10px 25px;
        gap: 5px;
        bottom: 0;
        top: 0
    }

    .level {
        padding-bottom: 10px;
    }

    .text {
        font-size: 15px;
    }

    .title {
        font-size: 18px;
        padding-bottom: 5px;
    }

    .checkcont {
        padding-bottom: 4px;
    }

    .cont {
        padding-top: 8px;
    }
}