.homework{
    width: 100%;
    background: rgba(22, 42, 56, 1);
    border-radius: 30px;
    padding: 40px 20px;
    font-family: var(--main-font-family), sans-serif;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 2;
}

.homework::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px 1.5px;
    background: linear-gradient(-20deg, #F4B41A 0%, rgba(255, 255, 255, 0) 40%, rgba(244, 180, 26, 0) 100%);
    mask: linear-gradient(rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%) content-box,
    linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.description{
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 20px;
    padding-left: 35px;
    position: relative;
    font-size: 20px;
}

.description::before{
    content: "";
    position: absolute;
    left: 5px;
    top: 20px;
    width: 16px;
    height: 16px;
    background: rgba(114, 135, 150, 1);
    border-radius: 50%;
}

.points{
    color: rgba(18, 34, 45, 1);
    padding: 8px 16px;
    font-weight: 600;
    background: linear-gradient(95.18deg, #FFC038 5.37%, #FFDE97 100%);
    border-radius: 20px;
    white-space: nowrap;
}

.text p {
    margin-bottom: 15px;
}

.text span{
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #BCD3DB;
}
.feedbackcont {
    border-radius: 48px;
    background: #162A38;
    padding: 40px 95px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 40px;
    border: 1px solid #213E53;
}

.feedback {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038;
}

.feedtext {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
}


.feedcont {
    background: #12222D;
    border-radius: 20px;
    padding: 30px 25px;
}

.table tr td {
    width: 33%;
    text-align: center;
    padding: 23px 0;
    display: inline-block;
    max-width: 100%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
    height: 70px;
}

.table tr:not(.table thead tr):not(.table tr:last-child) {
    border-top: 1px solid #728796;
    border-bottom: 1px solid #728796;
}

.table tr td:last-child {
    border-left: 1px solid #728796;
}

.table tr td:first-child {
    border-right: 1px solid #728796;
}

.table tr:nth-child(1) td:first-child {
    text-align: left;
    padding-left: 10px;
}

.table thead tr td{
    width: 33%;
    padding: 20px 0;
    text-align: center;
    vertical-align: middle;
}

.table{
    width: 100%;
    border-collapse: collapse;
}