.heading {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-bottom: 30px;
}

.sections {
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    padding-bottom: 40px;
}
.links{
    width: 210px;
    height: 49px;
    padding-left: 10px;
    display: flex;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.links:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.links:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding: 0 3px 5px;
}

.link:hover {
    color: #FFC038;
}

.active {
    border-bottom: 2px solid #FFC038;
}

/*.container {*/
/*    padding: 40px 0;*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    z-index: 2;*/
/*}*/

/*.container::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    inset: 0;*/
/*    border-radius: 32px;*/
/*    padding: 1.5px  1.5px;*/
/*    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 120%, rgba(244, 180, 26, 0) 100%);*/
/*    mask:*/
/*            linear-gradient(rgba(255, 255, 255, 1) 0%,*/
/*            rgba(255, 255, 255, 1) 100%) content-box,*/
/*            linear-gradient(rgba(255, 255, 255, 1) 0%,*/
/*            rgba(255, 255, 255, 1) 100%);*/
/*    mask-composite: exclude;*/
/*    z-index: -1;*/
/*}*/
.cont {
    background-color: #162A38;
    border-radius: 32px;
    position: relative;
    z-index: -1;
    padding: 20px 30px 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.co{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.ti{
    color: #FFF;
    font-family: Teko, serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;

}

.left{
    width: 63%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.right{
    width: 37%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img{
    max-width: 340px;
}
.title{
    color:  #FFC038;
    font-family: Teko, serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    padding-top: 50px;
}

.text{
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.imgteacher{
    width: 190px;
}