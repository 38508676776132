.cont{
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    padding-right: 5px;
    background: #243745;
    margin-top: 40px;
    z-index: 1;
    border-radius: 5px;
}

.container{
    max-height: 130px;
    overflow-y: scroll;
}

.container::-webkit-scrollbar{
    width: 8px;
}

.container::-webkit-scrollbar-thumb{
    height: 0;
    border-radius: 5px;
    background: #1A2933;
}

.countryName{
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    color: #EBEBEB;
    margin-top: 7px;
    cursor: pointer;
    padding: 5px 11px 5px 20px;
}
.countryName:hover{
    background: #2F495C;
}