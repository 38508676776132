.bigcont {
    height: 100vh;
    width: 360px;
    background: #0f212a;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    overflow-y: auto;
}

.container {
    height: 640px;
    width: 100%;
    position: absolute;
}

.logo {
    width: 42px;
    height: 54px;
    margin-left: 10px;
}

.menu {
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin-right: 10px;
}

.cont {
    position: absolute;
    width: 100%;
    height: 88%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.contul {
    width: 95%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #143E59;
}

.contbtn {
    width: 100%;
    height: 9%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 10%;
}

.link, .linkli {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    text-align: left;
    text-decoration: none;
    color: #C4CDD3;
    padding-left: 4%;
    line-height: 1.2;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.linkli {
    font-weight: 300;
}

.link {
    font-weight: 500;
}

.linkli:hover {
    font-weight: bolder;
}

.linkli:active {
    color: #FFB81E;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.arrow {
    transition: 0.2s;
}

.linkdrop:active {
    color: #FFB81E;
}

.arrowhov {
    rotate: -180deg;
    margin-left: 1px;
    transition: 0.2ms;
}

.item {
    border-bottom: 1px solid #143E59;
}

.itemarrow, .itemopen, .item {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.itemarrow {
    border-bottom: 1px solid #143E59;
}

.icon, .iconopen {
    width: 11px;
    height: 11px;
    margin-right: 4%;
    color: #EBEBEB;
    scale: 2;
    cursor: pointer;
    transition: 0.2s;
}

.iconopen {
    rotate: -180deg;
}

.divlist {
    width: 100%;
    height: 20%;
    margin-top: -1%;
    border-bottom: 1px solid #143E59;
}

.li {
    margin-bottom: 2.5%;
    width: 100%;
}

.logicon {
    background: url("../../../../public/images/loginIcon.png");
    width: 48px;
    height: 48px;
    scale: 0.3;
}

.login {
    padding-left: 10px;
    white-space: nowrap;
    margin-right: -10px;
}

.linkbutton {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    text-decoration: none;
    border: 1px #FFB81E solid;
    border-radius: 12px;
    color: #FFFF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 25px;
    background: rgba(255, 192, 56, 0.1);
}

.linkbutton:hover {
    background: rgba(255, 192, 56, 0.95);
    color: rgb(0, 0, 0);
}

.linkbutton:active {
    background: rgba(255, 192, 56);
}

.linkbutton:hover .logicon {
    background: url("/public/images/loginIconBlack.png");
}

@media only screen and (max-width: 475px) {
    .bigcont {
        width: 100%;
        border-radius: 0;
        background: #12222D;
    }

    .logo {
        width: 35px;
        height: 43px;
        margin-left: 15%;
    }
}
