.image {
    width: 220px;
}

.quest {
    font-family: var(--main-font-family), sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.undertitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #EBEBEB;
;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-bottom: 40px;
    color: #FFC038;
}

.title_en, .title_ru {
    font-size: 50px;
}

.title_am {
    font-size: 38px;
}

.container {
    width: 100%;
    padding: 20px 30px;
    background: #243745;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
}

.cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close {
    width: 27px;
    transform: rotate(135deg);
    transition: .3s ease-in-out;
}

.open {
    transform: rotate(0);
    transition: .3s ease-in-out;
}

.closecont {
    padding: 8px;
    background: #2F495C;
    display: flex;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
}

.question {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-align: left;
    color: #EBEBEB;
}

.answercont {
    border-top: 2px solid rgba(52, 82, 102, 0.4);
    padding-top: 30px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px
}

.answer {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #BCD3DB;
}

@media only screen and (max-width: 600px) {
    .image{
        width: 150px;
    }
    .title{
        font-size:30px;
        text-align: center;
    }
    .question{
        font-size:18px;
    }
    .answer{

        font-size:16px;
    }
}