.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    font-size: 35px;
}

.table tr td:not(:last-child), .table tr th:not(:last-child) {
    border-right: 1px solid #728796;
}

.table tr td:not(:first-child), .table tr th:not(:first-child) {
    border-left: 1px solid #728796;
}

.table tr td:not(.table tr:first-child td) {
    border-top: 1px solid #728796;
}

.table tr td:not(.table tr:last-child td) {
    border-bottom: 1px solid #728796;
}

.table tr:last-child td, .table tr th {
    border-bottom: 1px solid #728796;
    text-align: center;
}

.table tr td:not([class]), .table tr a {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
}

.table tr td, .table tr th {
    text-align: center;
    padding: 25px 0;
}

.table tr th > span {
    padding: 8px 25px;
    font-size: 20px;
    border-radius: 15px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    text-align: center;
    background: #EBEBEB;
    color: #12222D;
    margin: auto;
}

.totalPoints {
    padding: 6px 30px;
    font-size: 20px;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    background: #FFC038;
    color: black;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 30px;
}

.status {
    padding: 8px 0;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    text-align: center;
}

.paid {
    color: #174E2A;
    background: #66CF8A;
}

.failed {
    color: #CF6679;
    background: #FFD1D1;
}

.subTable {
    border-collapse: collapse;
    border-top: 1px #728796 solid;
    border-bottom: 1px #728796 solid;
    margin: 5px 0;
}

.subTable th, .subTable td {
    padding: 8px;
    text-align: left;
}

.subTable th {
    font-weight: 600;
}

.adminName {
    cursor: pointer;
    font-weight: 600;
}

.adminName:hover {
    color: #f1c40f;
}

.arrow {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
    float: left;
}

.arrow.down {
    transform: rotate(90deg);
}

.arrow.right {
    transform: rotate(0deg);
}
