.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.title_en {
    font-size: 35px;
}

.title_am {
    font-size: 26px;
}

.titlecont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}