.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    font-size: 35px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;
}

.sections {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.section {
    padding: 10px 20px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.admins {
    background-color: #83B3D6;
}

.teachers {
    background-color: #B4D174;
}

.students {
    background-color: #E99ABA;
}

.courses {
    background-color: #DCC66B;
}

.groups {
    background-color: #C187E7;
}

.subscriptions {
    background-color: #EBB47A;
}

.sectionTitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #E7E7E7;
    margin-top: 5px;
}

.number {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.icon {
    width: 70px;
    height: auto;
    align-self: flex-end;
    margin-top: -10px;
}