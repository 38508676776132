.quizResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    margin-top: 60px;
}

.questionTitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    padding: 5px 30px;
    background: #162A38;
    border-radius: 45px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.questionBlock {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.options > div {
    width: 100%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    padding: 20px 30px;
    background: #162A38;
    border-radius: 45px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 2
}

.questionOrder {
    font-family: Teko, sans-serif;
    font-size: 45px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038
}

.optionOrder {
    font-family: Teko, sans-serif;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #EBEBEB;
}

.line {
    border: 2px solid #162A38;
    width: 100%;
    margin-top: 30px;
}

.green {
    color: rgba(102, 207, 138, 1);
}

.red {
    color: rgba(207, 102, 121, 1);
}

.icon {
    font-size: 25px
}

.answer::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 45px;
    padding: 0.1em;
    background: linear-gradient(-10deg, var(--gradient-color, rgba(114, 135, 150, 1)) 0%, rgba(255, 255, 255, 0) 60%, var(--gradient-color-alpha, rgba(114, 135, 150, 0.4)) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.correctAnswer::before {
    --gradient-color: rgba(102, 207, 138, 1);
    --gradient-color-alpha: rgba(102, 207, 138, 0.4);
}

.incorrectAnswer::before {
    --gradient-color: rgba(207, 102, 121, 1);
    --gradient-color-alpha: rgba(207, 102, 121, 0.4);
}
