.cont{
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    padding-right: 5px;
    background: #162A38;
    margin-top: 40px;
    z-index: 100;
    border-radius: 5px;
}


.container{
    /*background: #162A38;*/
    max-height: 195px;
    overflow-y: scroll;
    border-radius: 5px;

}

.container::-webkit-scrollbar{
    width: 8px;
}

.container::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #1a3749;
}

.countryName{
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    color: #EBEBEB;
    margin-top: 7px;
    cursor: pointer;
    padding: 5px 5px 5px 20px;
}
.checkboxContainer{
    display:flex;
    padding:10px 30px;
    align-items: center;
    color:#FFFFFF;
    font-size:20px;
    font-family: var(--main-font-family), sans-serif;
}
.countryName:hover{
    color: #f8bf06;
}

@media only screen and (max-width: 470px) {
    .countryName {
        font-size: 16px;
    }
}