
.titList{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    margin-top: 40px;
}
.titList > p {
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44.8px;
    letter-spacing: 0.64px;
}
.last{
    margin-right: 30px;
}
.titListmap{
    margin-bottom: 22px;
    border-radius: 16px;
    background: rgba(20, 62, 89, 0.50);
    width: 100%;
}
.ones{
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titlink {
    display: flex;
    align-items: center;
    column-gap: 60px;
    padding: 15px 0;
}
.studentname{
    color:  #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.imgsstudents{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.status{
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;

}
.points{
    margin-right: 30px;
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pointss{
    color: #AFB9C0;
    /* Caption text */
    font-family: var(--main-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border-radius: 10px;
    border: 2px solid #AFB9C0;
    background: rgba(175, 185, 192, 0.20);
    padding: 5px 35px;

}
.bottom{
    width: 90%;
    border-top:1px solid #BCD3DB;
    display: flex;
    gap: 42px;
    margin: 0 auto;
    padding: 32px 0;
}
.bottom > div {
    column-gap: 22px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 32px;
    background: #143E59;
}
.bottom > div > p:first-child {
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
}
.bottom > div > p:last-child {
    color:#EBEBEB;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.arrow {
    cursor: pointer;
    font-size: 35px;
    color: #869EB0;
    transition: 0.2s ease-in-out;
}
.arrowopen {
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 1450px ){
    .bottom > div > p:first-child {
        font-size: 20px;
    }
    .bottom > div > p:last-child {
        font-size: 18px;
    }
    .bottom{
        gap: 32px;
    }
}
@media only screen and (max-width: 1285px ){
    .bottom > div > p:first-child {
        font-size: 18px;
    }
    .bottom > div > p:last-child {
        font-size: 16px;
    }
    .bottom{
        gap: 22px;
    }
    .studentname{
        font-size: 22px;
    }
    .status{
        font-size: 22px;
    }
    .points{
        font-size: 22px;
    }
}
@media only screen and (max-width: 1190px ){
    .bottom > div > p:first-child {
        font-size: 18px;
    }
    .bottom > div > p:last-child {
        font-size: 16px;
    }
    .bottom{
        gap: 16px;
    }
    .bottom > div {
        gap: 10px;
    }

}