.container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.group {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.user {
    border-radius: 32px;
    background-color: #162A38;
    position: relative;
    z-index: 2;
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.user::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 70%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    color: #BCD3DB;
}

.student {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
}

.descriptionCont {
    background-color: #162A38;
    border-radius: 48px;
    padding: 25px 30px 40px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.descriptionCont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 48px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-5deg, #F4B41A 0%, rgba(255, 255, 255, 0) 50%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.pointsCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFC038;
}

.points {
    padding: 8px 12px;
    background: linear-gradient(95.18deg, #FFC038 5.37%, #FFDE97 100%);
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #12222D;
}

.description {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    width: 80%;
}

.homework img {
    width: 20%;
    height: auto;
}

.homework p, .homework h1, .homework h2, .homework ol li, .homework ul li {
    color: white;
}

.feedbackcont {
    border-radius: 48px;
    background: #162A38;
    padding: 40px 70px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #213E53;
}

.feedback {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038;
}

.textarea {
    width: 100%;
    height: 180px;
    caret-color: #FFFFFF ;
    resize: none;
    outline: none;
    padding: 10px 13px;
    border: 1.2px solid #243745;
    border-radius: 20px;
    background: #12222D;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #EBEBEB;
}


.textarea::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #728796;
    padding-left: 10px;
}

.textarea:focus {
    border: 1.2px solid #FFC038 !important;
}

.textarea:hover {
    border: 1.2px solid #728796;
}

.textarea::-webkit-scrollbar {
    width: 5px;
}

.textarea::-webkit-scrollbar-thumb {
    background-color: #728796;
    border-radius: 15px;
}

.textarea::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}

.table tr td {
    width: 33%;
    text-align: center;
    padding: 23px 0;
    display: inline-block;
    max-width: 100%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
    height: 70px;
}

.table tr:not(.table thead tr):not(.table tr:last-child) {
    border-top: 1px solid #728796;
    border-bottom: 1px solid #728796;
}

.table tr td:last-child {
    border-left: 1px solid #728796;
}

.table tr td:first-child {
    border-right: 1px solid #728796;
}

.table tr:nth-child(1) td:first-child {
    text-align: left;
    padding-left: 10px;
}

.table thead tr td{
    width: 33%;
    padding: 20px 0;
    text-align: center;
    vertical-align: middle;
}

.table{
    width: 100%;
    border-collapse: collapse;
}

.input {
    border: none;
    outline: none;
    background: transparent;
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    -moz-appearance: textfield;
    appearance: textfield;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input::placeholder {
    color: #728796;
}

.message {
    font-style: italic;
}

.fileLinks {
    display: flex;
    padding: 0 95px;
    flex-direction: column;
    gap: 30px;
}

.cont {
    display: flex;
    flex-direction: column;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    color: white;
}

.filecont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 40px;
    border-radius: 20px;
    background: #143E59;
    color: white;
}

.checkcont {
    display: flex;
    align-items: center;
    gap: 60px;
}

.check {
    width: 35px;
    height: auto;
}

.collapsibleDescription {
    text-align: center;
}

.collapsibleDescription .description {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.collapsibleDescription .description.collapsed {
    max-height: 100px;
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
    /*-webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));*/
}

.collapsibleDescription .description.expanded {
    max-height: none;
}

