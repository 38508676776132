.container {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.height_am {
    height: 700px;
    padding-top: 20px;
}

.height_en {
    height: 600px;
}

.height_ru {
    height: 620px;
}

.cont {
    padding: 30% 0;
    align-items: center;
    display: flex;
}

.textcont {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    background: #182E3D;
    border-radius: 32px 32px 32px 0;
    padding: 35px;
}

.triangle {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: -25px;
    left: -1px;
}

.namecont {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 50px;
    margin-bottom: 50px;
}

.name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #EBEBEB;
}

.prof {
    font-family: var(--main-font-family),sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #9CA3A7;
}

.image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover !important;
}

.quote {
    width: 35px;
    height: 30px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    color: #BCD3DB;
}

.starcont {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    position: absolute;
    bottom: 30px;
}

.star {
    width: 25px;
    height: 25px;
}

@media only screen and (max-width: 420px) {
    .container {
        max-width: 315px;
    }
}

@media only screen and (max-width: 850px) {
    .cont {
        padding: 10% 0;
    }
    .height_am {
        height:100%;
        padding-top: 20px;
    }
    .height_en {
        height:100%;
    }
    .starcont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        position: relative;
        /*bottom: 30px;*/
        margin-top: 20px;
    }
}