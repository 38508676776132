.student {
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}

.cont{
    padding: 40px;
    padding-inline: 40px;
    border-radius: 32px;
    background: #162A38;
}

.btn {
    border-top: 1px solid #F4B41A ;
    padding-top: 50px;
    padding-bottom: 40px;
}