.image {
    width: 48%;
    height: auto;
    transform: translate(25px, -23px);
    margin-bottom: -40px;
}

.container {
    width: 100%;
    padding: 4% 5% 8%;
    background: #243745;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 50px;
}

.failGap {
    gap: 15px;
}

.successGap {
    gap: 25px;
}

.subText {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #AFB9C0 ;
    margin: -20px 0 -8px;
}

.invoiceCont {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.img {
    width: 130px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    max-width: 600px;
    color: #EBEBEB;
    padding-bottom: 40px;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}