
.container {
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: #FFB81E;
    color: black;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    align-self: flex-end;
    margin: 0 180px 20px 0;
}

.file {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

 .container:hover  {
    background-color: #FFC850;
}

 .container:active {
    background-color: #F4B41A;
}

.fileLinks {
    display: flex;
    padding: 0 95px;
    flex-direction: column;
    gap: 30px;
}

.cont {
    display: flex;
    flex-direction: column;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    color: white;
}

.filecont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 40px;
    border-radius: 20px;
    background: #143E59;
}

.checkcont {
    display: flex;
    align-items: center;
    gap: 60px;
}

.check {
    width: 35px;
    height: auto;
}

.trash {
    width: 25px;
    height: auto;
    cursor: pointer;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    padding: 8px 25px;
    background: #143E59;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #EBEBEB;
    align-self: flex-start;
    margin-left: 35px;
}

.dates {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}