.container{
    padding-bottom: 250px;
}

.image {
    width: 57%;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, transparent, rgba(98, 119, 133, 1), transparent) 1;
    top: 180px;
    z-index: 2;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    font-size: 60px;
    text-align: center;
    letter-spacing: 0.02em;
    white-space: nowrap;
    padding-top: 48%;
    padding-bottom: 10px;
    z-index: 1;
    color: #EBEBEB;
}

.frame {
    position: absolute;
    left: 0;
    top: 0;
    width: 94%;
    height: auto;
    z-index: -1;
}

@media screen and (min-width: 1700px) {
    .frame {
        width: 88%;
    }
}

@media only screen and (max-width: 1300px) {
   .image{
       width:40%;
   }
}


@media only screen and (max-width: 950px) {
    .image{
        width:40%;
        top:150px
    }
    .head{
        font-size: 40px;
    }
}
@media only screen and (max-width: 700px) {
    .image{
        width:50%;
        top:110px
    }
    .head{
        font-size: 30px;
        padding-top: 55%;
    }
    .frame{
        height:50vh;
    }
}

@media only screen and (max-width: 500px) {

    .head{
        font-size: 30px;
        padding-top: 59%;
    }
    .head{
        font-size: 24px;
    }

}