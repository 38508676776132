.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    font-size: 35px;
}

.table tr td:not(:last-child), .table tr th:not(:last-child) {
    border-right: 1px solid #728796;
}

.table tr td:not(:first-child), .table tr th:not(:first-child) {
    border-left: 1px solid #728796;
}

.table tr td:not(.table tr:first-child td) {
    border-top: 1px solid #728796;
}

.table tr td:not(.table tr:last-child td) {
    border-bottom: 1px solid #728796;
}

.table tr:last-child td, .table tr th {
    border-bottom: 1px solid #728796;
    text-align: center;
}

.table tr td:not([class]), .table tr a {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
}

.table tr td, .table tr th {
    text-align: center;
    padding: 25px 0;
}

.table tr th > span {
    padding: 8px 25px;
    font-size: 20px;
    border-radius: 15px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    text-align: center;
    background: #EBEBEB;
    color: #12222D;
    margin: auto;
}

.totalPoints {
    padding: 6px 30px;
    font-size: 20px;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    background: #FFC038;
    color: black;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 30px;
}

.status {
    padding: 8px 25px;
    font-size: 20px;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    text-align: center;
}

.paid {
    color: #174E2A;
    background: #66CF8A;
}

.failed {
    color: #CF6679;
    background: #FFD1D1;
}

.searchBar {
    width: 100%;
    border: 1px solid #f1c40f;
    padding: 15px 30px;
    border-radius: 32px;
    background: transparent;
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
    font-size: 16px;
}

.searchBar::placeholder {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #728796;
    font-size: 16px;
}

.searchContainer {
    width: 350px;
    position: relative;
}

.searchIcon {
    position: absolute;
    top: 13px;
    right: 25px;
    font-size: 28px;
    color: #728796;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
}

.yellowDot {
    background-color: #FFC038;
}

.greenDot {
    background-color: #66CF8A;
}

.redCross {
    font-size: 20px;
    font-weight: bold;
}

.paymentProgress {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}