.container {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 12px;
    min-height: 680px;
}

.calendar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 30px 0;
    height: 100%;
}

.calendar::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 130%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.day {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    padding: 7px 12px;
}

.todayCont {
    display: flex;
    flex-direction: column;
}

.date:hover {
    color: #FFC850;
    cursor: pointer;
}

.currentDay {
    border-radius: 30px;
    border: 2px solid #728796;
    box-shadow: 0 0 6px 0 #FFC03880;
}

.day.currentDay .weekName {
    color: #BCD3DB;
}

.weekName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.today {
    font-family: var(--main-font-family), sans-serif;
    font-size: 23px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: left;
    color: #AFB9C0;
}

.todayDate {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #AFB9C0;
    padding-top: 3px;
}

.week {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
}

.schedule {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.schedule_en {
    font-size: 35px;
}

.schedule_am {
    font-size: 26px;
}

.cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 30px;
}

.seeAll {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    color: #AFB9C0;
    cursor: pointer;
    padding-bottom: 2px;
}

.seeAll:hover {
    color: #FFC850;
}

.events {
    display: flex;
    flex-direction: column;
    gap: 14px;
    overflow-y: auto;
}

.studentAndTeacher {
    max-height: 350px;
    padding: 0 30px;
}

.admin {
    max-height: 500px;
    padding: 0 30px 15px;
}

.events::-webkit-scrollbar {
    width: 5px;
}

.events::-webkit-scrollbar-thumb {
    background-color: #F4B41A;
    border-radius: 5px 0 0 5px;
}

.noEvents {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #728796;
    margin-top: 10px
}

.img {
    width: 400px;
}
@media only screen and (max-width: 650px){
    .week{
        padding:0 7px ;
    }
    .studentAndTeacher{
        padding:0 15px ;

    }
    .img{
        width: 300px;

    }
    .weekName{
        font-size: 24px
    }
}