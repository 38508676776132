.cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 30px 0;*/
}
.left{
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.btn{
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    background: none;
    font-family: var(--main-font-family), serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
}
.title{
    font-size:42px ;
    color: #FFFFFF;
    font-family: Teko, serif ;
}
.link{
    border-radius: 12px;
    border: 1px solid #FFC038;
    background: #FFC038;
    cursor: pointer;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #12222D;
    white-space: nowrap;
    padding: 10px 17px;
    font-size: 17px;
}

.link:hover {
    background: #FFC850;
}


.link:active{
    background: #FFB81E;
}