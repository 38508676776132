.container {
    height: 95vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.headcont {
    /*padding-top: 330px !important;*/
    width: 50%;
    align-self: center;
}

.head {
    width: 100%;
}

.imgcont {
    width: 50%;
}

.big {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 1.1;
    text-align: left;
    color: #FFC038;
    margin-top: -10px;
}

.big_en {
    font-size: 80px;
}

.big_ru {
    font-size: 50px;
}

.big_am {
    font-size: 50px;
    padding-bottom: 30px;
}

.small {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #EBEBEB;
}

.small_en {
    font-size: 57px;
}

.small_ru {
    font-size: 45px;
}

.small_am {
    font-size: 40px;
    padding-bottom: 10px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4;
    text-align: left;
    width: 80%;
    color: #EBEBEB;
}

.button {
    width: 100%;
    display: flex;
    gap: 25px;
    padding-top: 5%;
    z-index: 30;
}



.image {
    width: 77%;
    z-index: 5;
    padding-top: 160px;
}

.img {
    width: 56%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}



@media only screen and (max-width: 1155px) {
    .big {
        font-size: 50px;
    }

    .small {
        font-size: 40px;
    }
}
@media only screen and (max-width: 848px) {
    .image {
        position: absolute;
        right: 70px;
        width: 260px;
        transform: translateY(-100px);
    }

    .imgcont {
        width: unset;
    }

    .img {
        width: 380px;
    }

    .headcont {
        width: 100%;
        padding-top: 350px !important;
        z-index:50;
    }
    .container{
        margin-bottom:0;
        height: 70vh
    }
    .big_am {
        font-size: 22px !important;
    }
    .small_am {
        font-size: 24px;
        padding-bottom: 10px;
    }

}
@media only screen and (max-width: 450px) {
    .big {
        font-size: 32px;
        margin-top:140px;

    }

    .headcont {
        width: 100% ;
    }

    .big_am {
        font-size: 22px !important;
    }

    .imgcont {
        width: 50%;
    }

    .img {
        width: 340px;
    }

    .image {
        right: 50px;
        width: 170px;
    }

    .headcont {
        padding-top: 290px !important;
    }

    .text {
        font-size: 15px;
        width: 100%;
    }

    .small {
        font-size: 23px;
    }
}

