.container {
    width: 100%;
    padding: 30px;
    background: #12222D;
    border-radius: 48px;
    position: sticky;
    left: 2%;
    top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    z-index: 3;
    height: calc(100vh - 15px);
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 48px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 108%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.logo {
    width: 43px;
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: cover;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 25px;
    /*border-bottom: 0.5px solid rgba(20, 62, 89, 0.5);*/
}

.item {
    padding-right: 30px;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    color: #EBEBEB;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.logout {
    cursor: pointer;
    position: absolute;
    bottom: 30px;
}

.logouticon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-image: url('../../../../public/images/logOut.png');
}


.active {
    color: #FFB81E !important;
}

.logout:hover .logouticon {
    background-image: url('../../../../public/images/logoutHover.png');
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.arrow {
    position: absolute;
    right: 10px;
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.subMenuItem {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    padding-left: 40px;
    padding-top: 10px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    color: #EBEBEB;
}

.subMenuItem:hover{
    color: #FFB81E !important;

}

.arrowhov {
    rotate: -90deg;
}


@media only screen and (min-height: 800px) {
    .container {
        height: 770px;
    }
}

