.title {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-bottom: 10px;
}

.container {
    border-radius: 15px;
    padding: 165px 0 175px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.submitCont {
    padding: 95px 70px 90px 20px;
    border-radius: 32px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.numbersCont {
    border-radius: 15px;
    padding: 40px 80px;
    position: relative;
    z-index: 2;
}

.container::before, .numbersCont::before , .submitCont::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.quizTitle {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 52px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    color: #BCD3DB;
}

.text {
    width: 80%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    align-self: center;
    text-align: center;
    color: #AFB9C0;
    padding-bottom: 20px;
}

.timerCont {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
}

.textCont {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.questionTitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: white;
    margin-top: -10px;
}

.timer {
    width: 140px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.timer > img {
    width: 60px;
    height: 60px;
}

.time {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: center;
    color: white;
}

.numbers {
    display: flex;
    gap: 20px;
    padding-top: 35px;
}

.circle {
    width: 40px;
    height: 40px;
    background-color: #728796;
    padding: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: center;
    color: white;
}

.quiz {
    margin-top: 30px;
    padding: 60px 80px;
}

.quizAnswers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.answer {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 10px 30px;
    background-color: #162A38;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    text-align: left;
    color: #EBEBEB;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.answer:hover .number, .answer:hover, .selected, .selected .number {
    color: #FFC038;
}

.selected::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-4deg, #F4B41A 0%, rgba(255, 255, 255, 0) 60%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: 3;
}

.number {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #EBEBEB;
    margin-right: 10px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.selectedCircle {
    background-color: #FFC038;
    color: #12222D;
    box-shadow: 0 0 20px 0 #FFC03880;
}

.beforeCircle {
    opacity: 0.4;
}

.message {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    color: #EBEBEB;
}

.textCont {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 65%;
}

.image {
    max-width: 350px;
    height: auto;
    border-bottom: 0.5px solid #728796;
    padding-right: 50px;
}

.imageRight {
    max-width: 260px;
    height: auto;
    border-bottom: 0.5px solid #728796;
    padding-right: 50px;
}

.name {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.messageText {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.yellow {
    color: #FFC038;
}

.gray {
    color: #AFB9C0;
}

.info > img {
    width: 24px;
    height: 24px;
}

.info {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #728796;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 70px;
}