.event {
    background: #143E59;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 8;
    margin-left: 3px;
    box-shadow: 4px 4px 20px 0 #021A2B;
}

.small {
    height: 80px;
    padding: 8px 5px 13px 15px;
    gap: 1px;
}

.big {
    min-height: 95px;
    height: 100%;
    padding: 10px 5px 15px 15px;
    gap: 5px;
}

.weekHeight {
    height: 120px;
}

.longEvent {
    width: 40% !important;
}

.event:before {
    content: '';
    position: absolute;
    z-index: 10;
    width: 7px;
    top: 10px;
    bottom: 10px;
    left: -2px;
    border-radius: 15px;
    background: #FFD1D1;
}

.hours {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #AFB9C0;
}

.hoursShort {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 70%;
}

.format {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.link {
    cursor: pointer;
}

.link:hover {
    color: #FFC850 !important;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-top: 2px;
    /*height: 30px;*/
}

.homework > .title, .homework > .format{
    color: #FFD1D1;
}

.homework::before {
    background: #FFD1D1;
}

.meeting::before {
    background:  #A8D1B6;
}

.finalInterview:before {
    background: #FF90C2;
}

.finalInterview > .title, .finalInterview > .format {
    color: #FF90C2;
}

.meeting > .title, .meeting > .format {
    color: #A8D1B6;
}

.lesson > .title, .lesson > .format {
    color: #F1F7B5;
}

.lesson::before {
    background: #F1F7B5;
}

.interview > .title, .interview > .format {
    color: #9EA1D4;
}

.interview::before {
    background: #9EA1D4;
}

.cont {
    display: flex;
    justify-content: space-between;
}

.edit {
    cursor: pointer;
    color: #12222D;
    font-size: 24px;
}

.hourCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cursor {
    cursor: default;
}

.pointer {
    cursor: pointer !important;
}

.icons {
    position: absolute;
    top: 8px;
    right: 6px;
    display: flex;
}

