.contlogo {
    width: 210px;
    height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.contcateg {
    width: 190px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 0 10px 40px;
}

.contname {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8%;
}

.contpar {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.conticon {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6%;
}

.contqua {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    margin-bottom: -13px;
}

.light {
    font-family: var(--main-font-family), sans-serif;
    font-size: 13px;
    font-weight: 300;
    color: #728796;
    line-height: 1.4;
}

.logo {
    width: 42px;
    height: 53px;
}

.text {
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: #EBEBEB;
}

.icon {
    width: 37px;
    height: 37px;
    opacity: .95;
}

.icon:hover ,
.img:hover{
    opacity: .9;
}

.icon:active,
.img:active {
    opacity: 1;
}


.a {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: #FFC038;
    text-decoration: none;
}

.a:hover {
   color: #FFCB5C;
}

.a:active {
    color: #FFB81E;
}

.linkdiv {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 40%;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    text-align: left;
    font-weight: 400;
    line-height: 31px;
    text-decoration: none;
    color: #C4CDD3;
    white-space: nowrap;
    padding-top: 2px;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.cont {
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 38px;
    flex-wrap: wrap;
}

@media only screen and (max-width: 690px) {
    .contqua {
        align-items: center;
    }

    .contlogo {
        height: 250px;
    }

    .contname {
        flex-direction: column;
        gap: 10px;
    }

    .conticon {
        justify-content: center;
    }

    .contqua {
        justify-content: center;
    }
}

@media only screen and (max-width: 548px) {
    .contlogo {
        align-self: center;
    }
}





