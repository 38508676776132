.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.title_en {
    font-size: 35px;
}

.title_am {
    font-size: 28px;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.testSection {
    flex: 50%;
    height: 66vh;
    border-radius: 32px;
    padding: 20px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.testSection::before, .backgroundTestSection::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.small{
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #728796;
}

.choose {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #BCD3DB;
    margin-top: 20px;
}

.radio {
    padding-top: 20px;
    justify-content: flex-start;
    gap: 40px;
}

.backgroundContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.backgroundTestSection {
    flex: 100%;
    border-radius: 32px;
    padding: 30px 70px 30px 30px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.titleCont {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.results {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.pointsCont {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
}

.quizTitle {
    width: 50%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.points {
    width: 20%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #BCD3DB;
}

.passDate {
    width: 20%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #BCD3DB;
}

.paddingBottom {
    padding-bottom: 30px;
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}

