.dropdown {
    background: #213E53;
    position: relative;
    border-radius: 5px;
    padding: 7px 20px;
}

.triangle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10%;
    z-index: -1;
}

.cont {
    margin-top: -25px;
    padding-top: 25px;
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
}

.link {
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    text-decoration: none;
    color: #EBEBEB;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.arrowHover {
    transform: rotate(-180deg);
    margin-left: 1px;
    transition: 0.2s ease-in-out;
}

.selected {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 60px
}

.arrow {
    margin-left: 1px;
    transition: 0.2s ease-in-out;
}

.li {
    display: inline-block;
    margin-right: 9.5%;
    position: relative;
}

.linkDropdown {
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFCB5C;
}

.linkDropdown:active {
    color: #FFB81E;
}

.line {
    border-left: 2px solid #728796;
    padding-left: 17px;
    margin-left: 10px;
    height: 35px;
}

.disabled {
    pointer-events: none !important;
    opacity: 0.6;
}

.pointer {
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .dropdown {
        padding: 6px 17px;
    }

    .link {
        font-size: 16px;
        line-height: 33px;
    }

    .link, .linkDropdown{
        font-size: 16px;
    }

    .li {
        margin-right: 6%;
    }
}


