.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    font-size: 35px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.table tr td:not(:last-child), .table tr th:not(:last-child) {
    border-right: 1px solid #728796;
}

.table tr td:not(:first-child), .table tr th:not(:first-child) {
    border-left: 1px solid #728796;
}

.table tr td:not(.table tr:first-child td) {
    border-top: 1px solid #728796;
}

.table tr td:not(.table tr:last-child td) {
    border-bottom: 1px solid #728796;
}

.table tr:last-child td, .table tr th {
    border-bottom: 1px solid #728796;
    text-align: center;
}

.table tr td:not([class]), .table tr a {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
}

.table tr a:hover {
    color: #f1c40f;
}

.table tr td, .table tr th {
    text-align: center;
    padding: 25px 0;
}

.table tr th {
    cursor: pointer;
}

.table tr th > span {
    padding: 8px 25px;
    font-size: 20px;
    border-radius: 15px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    text-align: center;
    background: #EBEBEB;
    color: #12222D;
    margin: auto;
}

.totalPoints {
    padding: 6px 30px;
    font-size: 20px;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    background: #FFC038;
    color: black;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 30px;
}
