* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body.am {
    --main-font-family: 'Noto Sans Armenian';
    --english-font-teko: 'Noto Sans Armenian';
}

body.en {
    --main-font-family: 'Fira Sans';
    --english-font-teko: 'Teko';
}

body.ru {
    --main-font-family: 'Fira Sans';
    --english-font-teko: 'Teko';
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans'), url("/public/fonts/FiraSans-Regular.ttf") format('truetype');
    font-weight: normal;
}


@font-face {
    font-family: 'Teko';
    src: local('Teko'), url(/public/fonts/Teko-Regular.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Noto Sans Armenian';
    src: local('NotoSansArmenian'), url(/public/fonts/NotoSansArmenian-Regular.ttf) format('truetype');
    font-weight: normal;
}

body {
    background: #12222D;
    position: relative;
}

body::-webkit-scrollbar, .ql-editor::-webkit-scrollbar {
    width: 12px !important;
}

body::-webkit-scrollbar-track, .ql-editor::-webkit-scrollbar-track {
    background: #152836 !important;
}

body::-webkit-scrollbar-thumb, .ql-editor::-webkit-scrollbar-thumb {
    background-color: #2F495C !important;
    border-radius: 5px !important;
}

body::-webkit-scrollbar-thumb:hover, .ql-editor::-webkit-scrollbar-thumb:hover {
    background-color: #243745 !important;
}

ul {
    list-style: none;
}

input:focus, textarea:focus {
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

a {
    text-decoration: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(70%);
    transform: scale(1.2);
    cursor: pointer;
}

input[type="time"] {
    -moz-appearance: textfield;
}

#chooseLearning .slick-slider{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 18%;
}

@media only screen and (max-width: 1450px) {
    #chooseLearning .slick-slider{
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    #chooseLearning .slick-dots li button::before {
        scale: 1.5;
    }

     .slick-slide:not(.slick-active) {
        opacity: 0.8;
    }
}

.slick-dots li button:before {
    opacity: 0.25;
}
.slick-dots li button:focus:before {
    opacity: 0.25 !important;
}
.slick-dots li.slick-active button:focus:before {
    opacity: 0.75 !important;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75 !important;
}

#chooseLearning .slick-dots,
#courses .slick-dots {
    bottom: -50px;
}

#students .slick-dots {
    bottom: 30px;
}

#courses .slick-dots li.slick-active button::before,
#students .slick-dots li.slick-active button::before{
    color: #E7E7E7;
    font-size: 20px !important;
}

#students .slick-dots li button:before {
    top: 3px !important;
}

#ourStory .slick-slider {
    padding: 0 7%;
}

@media screen and (max-width: 400px) {
    #ourStory .slick-slider {
        padding: 0;
    }

    #ourStory .slick-slider .slick-slide {
        padding: 0 5px !important;
    }
}

#ourStory  .slick-slider .slick-slide {
    padding: 0 15px;
}

#chooseLearning .slick-dots li button::before,
#courses .slick-dots li button::before ,
#students .slick-dots li button::before{
    color: #354956;
    font-size: 18px !important;
}

#students .slick-dots li {
    margin: 3px;
}

#chooseLearning .slick-dots li.slick-active button::before {
    color: #FFC850;
}

.slick-list {
    width: 100%;
    border-radius: 5px;
}

#chooseLearning .slick-initialized .slick-slide,
#students .slick-initialized .slick-slide,
#ourStory .slick-initialized .slick-slide
{
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 1300px) {
    #students .slick-slide {
        opacity: 0.7;
    }

    #students .slick-center {
        transform: scale(1.25);
        opacity: 1;
    }
}

#courses .slick-slider,
#students .slick-slider,
#ourStory .slick-slider{
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
}

.react-international-phone-input {
    height: 100% !important;
    border-radius: 0 8px 8px 0 !important;
    border: none !important;
    width: 100% !important;
    padding: 15px 13px !important;
    background-color: #243745 !important;
    color: #FFFFFF !important;
    font-family: var(--main-font-family), sans-serif !important;
    font-size: 17px !important;
    font-weight: 300 !important;
    letter-spacing: 0.03em !important;
}

.react-international-phone-country-selector-button {
    height: 100% !important;
    background-color: #263D4D !important;
    margin-right: 0 !important;
    padding: 10px !important;
    border-radius: 8px 0 0 8px !important;
    border: 1px solid transparent !important;
}

.react-international-phone-country-selector-button__button-content {
    scale: 1.2;
}

.react-international-phone-country-selector-dropdown__list-item {
    padding: 4px 5px !important;
}

.react-international-phone-country-selector-dropdown__list-item:hover,
.react-international-phone-country-selector-dropdown__list-item--focused {
    background: #2F495C !important;
    color: #EBEBEB !important;
}

.react-international-phone-country-selector-dropdown {
    width: 350px !important;
    background-color: #263D4C !important;
    margin-top: 15px !important;
    color: #EBEBEB !important;
    font-size: 14px !important;
    padding-left: 6px !important;
    font-family: var(--main-font-family), sans-serif !important;
    border-radius: 8px !important;
    outline: none !important;
}

.react-international-phone-country-selector-dropdown::-webkit-scrollbar {
    width: 11px;
}

.react-international-phone-country-selector-dropdown::-webkit-scrollbar-track {
    background: #263D4C;
}

.react-international-phone-country-selector-dropdown::-webkit-scrollbar-thumb {
    background: #1A2933;
    border-radius: 5px;
}


 .MuiDateCalendar-root {
     background-color: #243745;
     color: #EBEBEB;
 }


.MuiPaper-root-MuiPickersPopper-paper {
    background-color: unset;
}

 .MuiDateCalendar-root{
     border-radius: 4px ;
}

.MuiDateInput-root {
    width: 100% !important;
}

.MuiPickersDay-root, .switchViewIcon, .MuiSvgIcon-root,
.MuiDayCalendar-weekDayLabel,.MuiInputBase-input,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
     color: #EBEBEB !important;
 }

.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected,
.MuiPickersMonth-monthButton.Mui-selected{
    background-color: #728796 !important;
}

.MuiInputAdornment-root{
    margin-left: -42px ;
}

.MuiPickersCalendarHeader-root {
    background-color: #2F495C;
    margin: 0 !important;
    padding-top: 30px ;
    padding-bottom: 25px !important;
}

.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper {
    margin: 10px 0 ;
}

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-toolbar{
   display: none;
}

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-actionBar,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    background: #243745 !important;
}
#idData div[class^="MuiFormControl"] {
    width: 146%;
}


div[class^="MuiFormControl"] {
    width: 208%;
}

@media only screen and (max-width: 1060px) {
    div[class^="MuiFormControl"] {
        width: 100%;
    }
}

.video-react-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    border-radius: 50% !important;
    width: 1.7em !important;
    height: 1.6em !important;
    border: none !important;
}

.video-react-poster {
    background-size: cover !important;
    border-radius: 12px;
}

.video-react {
    background-color: #1A3040 !important;
    border-radius: 12px !important;
    display: flex !important;
}

.video-react-slider-bar {
    color: #FFB81E !important;
}


.video-react .video-react-progress-holder .video-react-play-progress,
.video-react .video-react-volume-level{
    background-color: #FFB81E !important;
}

.video-react .video-react-control-bar {
    height: 50px !important;
    border-radius: 0 0 12px 12px;
    background-color: #1A3040 !important;
}

.video-react .video-react-progress-control {
    margin-top: -5px !important;
}

.video-react .video-react-control-bar{
    padding-top: 10px;
}

.video-react video {
    width: 95% !important;
    left: 50% !important;
    transform: translateX(-50%);
}


.ql-editor {
    font-family: Arial, sans-serif;
    background-color: transparent;
    font-size: 18px;
}

.ql-toolbar.ql-snow {
    padding: 13px 80px !important;
}

/*.ql-clipboard {*/
/*    position: fixed;*/
/*}*/

.ql-container {
    /*height: 500px !important;*/
    height: 80vh !important;
    overflow-y: auto !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    color: white;
}

.ql-snow .ql-picker.ql-size .ql-picker-item:hover::before, .ql-snow .ql-picker.ql-size .ql-picker-label:hover::before {
    color: #F4B41A;
}

.ql-toolbar.ql-snow {
    position: sticky !important;
    top: 0;
    background: #12222D !important;
    z-index: 100;
}

.ql-toolbar.ql-snow {
    border-right: none !important;
    border-left: none !important;
    border-top: 0.5px solid #9CA3A7 !important;
    border-bottom: 0.5px solid #9CA3A7 !important;
}

.ql-snow.ql-toolbar button {
    width: 35px !important;
    height: 32px !important;
}

.ql-container.ql-snow {
    border: none !important;
}

.ql-container {
    font-family: var(--main-font-family), sans-serif;
    color: #FFFFFF;
    padding: 20px 80px !important;
}

.ql-snow .ql-stroke {
    stroke: #EBEBEB !important;
}

.ql-snow .ql-fill {
    fill: #EBEBEB !important;
}

.ql-snow .ql-transparent {
    opacity: 1 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: rgba(22, 42, 56, 1);
    border-radius: 12px;
    top: 30px;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    font-size: 18px;
    padding-left: 80px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #728796;
    font-style: unset;
}

.ql-snow .ql-tooltip {
    background-color: rgba(22, 42, 56, 1) !important;
    border: #9CB1C0 1px solid !important;
    border-radius: 12px !important;
    color: #EBEBEB !important;
    box-shadow: none !important;
    padding: 10px 15px !important;
    font-family: var(--main-font-family), sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.ql-snow .ql-tooltip::before {
    color: #FFB81E !important;
}

.ql-snow .ql-tooltip > a {
    color: #EBEBEB !important;
}

.ql-snow .ql-tooltip > a:hover {
    color: #FFC850 !important;
}

.ql-snow .ql-tooltip > input {
    background-color: rgba(22, 42, 56, 1);
    color: #EBEBEB;
    font-family: var(--main-font-family), sans-serif ;
    font-weight: 400 ;
    font-size: 14px ;
    border-radius: 5px;
}

.year {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 35px 0;
}

.month {
    flex-basis: 32%;
    padding: 25px;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    text-align: center;
}

@media only screen and (max-width: 1470px) {
    .year {
        justify-content: center;
        gap: 35px 60px;
    }

    .month {
        flex: 40%;
    }
}

@media only screen and (max-width: 1110px) {
    .year {
        gap: 25px 10px;
    }

    .month {
        flex: 48%;
    }
}

@media only screen and (max-width: 800px) {
    .month {
        flex: 100%
    }
}

.responsive-month {
    flex-basis: 100%;
    padding: 30px 10px;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    text-align: center;
    margin-top: 90px;
}

@media only screen and (max-width: 400px) {
    .date:hover {
        cursor: pointer;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        color: #FFC850;
    }
}

.activeMonth {
    box-shadow: 0 0 15px 0 #FFC03880;
}

.future-month {
    pointer-events: none;
    opacity: .6;
}

.month::before, .responsive-month::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 108%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.month-name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #FFC038;
    margin-bottom: 15px !important;
}

.day {
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.01em;
    margin: 0 0.2em;
}


.week-names {
    display: flex;
}

.week-name {
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.01em;
    margin: 0 0.53em 0.1em;
}

.in-month:hover {
    color: #FFC850;
}

.date {
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    margin: 6px;
    background: transparent;
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.01em;
    position: relative;
}

.date.in-month:hover {
    cursor: pointer;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
}

.event-dots {
    position: absolute;
    bottom: -13px;
    width: 100%;
}

.event-dot {
    width: 6px;
    height: 6px;
    background-color: #A8D1B6;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
}

.today {
    color: #FFC850;
}

.prev-month {
    color: #728796;
}

.date.prev-month,
.date.next-month {
    color: grey;
}

.rbc-month-view {
    border: none !important;
    padding: 40px 30px;
    position: relative !important;
    z-index: 2;
    width: 1500px !important;
    margin-top: 160px;
}

@media only screen and (max-width: 450px) {
    .rbc-month-view {
        margin-top: 140px;
    }

    .dayView > .rbc-time-view {
        padding: 10px 20px !important;
    }
}

.rbc-month-row {
    display: inline-table !important;
    flex: 0 0 0 !important;
    min-height: 180px !important;
}


.rbc-month-view::before, .rbc-time-view::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 108%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}



  .rbc-button-link  {
    color: #AFB9C0 !important;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    padding: 7px 9px !important;
    background-color: #12222D !important;
    border-radius: 50%;
    margin-top: 2px !important;
}

.rbc-button-link:hover {
    color: #FFC850 !important;
}

  .rbc-header {
      font-family: var(--main-font-family), sans-serif;
      font-size: 24px !important;
      font-weight: 500;
      letter-spacing: 0.02em;
      text-align: left;
      color: rgba(175, 185, 192, 1);
      padding-bottom: 40px !important;
  }

.rbc-today {
    background: transparent !important;
}

.rbc-date-cell.rbc-now > .rbc-button-link {
    color: #FFB81E !important;
}

.rbc-date-cell.rbc-off-range > .rbc-button-link {
    color: rgba(235, 235, 235, 0.3) !important;
}


.rbc-show-more {
    background: transparent !important;
    font-size: 16px !important;
    color: #EBEBEB !important;
    padding: 5px 10px !important;
}

.rbc-show-more:hover {
    color: #FFC850 !important;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: none !important;
}

.rbc-event {
    background: transparent !important;
    margin-top: 10px !important;
    display: inline-block !important;
}

.dayView .rbc-event {
    margin-top: 0 !important;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    overflow: unset !important;
}

.rbc-off-range-bg {
    background: transparent !important;
}

.dayView .rbc-day-bg + .rbc-day-bg, .rbc-header + .rbc-header, .dayView .rbc-time-header-content, .dayView .rbc-time-content > * + * > * {
    border-left: none !important;
}

 .rbc-day-bg + .rbc-day-bg, .rbc-header + .rbc-header, .rbc-time-header-content, .rbc-time-content > * + * > * {
    border-left: 0.5px #72879680 solid !important;
}

.rbc-month-row + .rbc-month-row,
.rbc-day-slot .rbc-time-slot {
    border-top: 0.5px #72879680 solid !important;
}

.weekView .rbc-month-row + .rbc-month-row,
.weekView .rbc-day-slot .rbc-time-slot {
    border-top: none !important;
}

.rbc-header {
    border-bottom: 0.5px #72879680 solid !important;
}

.rbc-time-view {
    margin-top: 160px !important;
}

.year {
    margin-top: 160px;
}

.rbc-header {
    position: sticky;
}

.rbc-header.rbc-today > .rbc-button-link {
    color: #FFC850 !important;
}

.rbc-time-view {
    border: none !important;
    padding: 40px 30px;
    position: relative !important;
    z-index: 2;
}

.weekView > .rbc-time-view {
    width: 1700px !important;
}

.dayView > .rbc-time-view {
    width: 100% !important;
    padding: 50px 20px;
}

.dayView > .rbc-time-content {
    width: 100% !important;
}

.weekView > .rbc-time-view .rbc-time-gutter {
    display: none !important;
}

.rbc-timeslot-group {
    border-bottom: none !important;
    min-height: 80px !important;
}

.dayView .rbc-timeslot-group > :not(:first-child) {
    border-top: none !important;
}

.rbc-time-slot > .rbc-label{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.02em;
    text-align: left;
    color: #AFB9C0;
}

.rbc-time-content {
    border-top: 1px #72879680 solid !important;
}

.rbc-allday-cell {
    padding: 7px 0 !important;
}

.weekView .rbc-label.rbc-time-header-gutter {
    display: none !important;
}

.dayView .rbc-label.rbc-time-header-gutter {
    width: unset !important;
    max-width: unset !important;
    min-width: unset !important;
}

.weekView .rbc-label  {
    padding: 0 !important;
}

.weekView .rbc-time-view .rbc-allday-cell {
    display: none !important;
}

.rbc-event-label {
    display: none !important;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none !important;
    overflow: unset !important;
    flex-flow: unset !important;
    cursor: default !important;
}

.weekView .rbc-day-slot .rbc-event {
    width: 100% !important;
    height: unset !important;
    left: unset !important;
    top: unset !important;
    position: unset !important;
}

.rbc-day-slot .rbc-event-content {
    height: unset !important;
    z-index: 10;
}

.dayView .rbc-day-slot .rbc-event-content {
    height: 100% !important;
    z-index: 10;
}

.rbc-events-container {
    z-index: 5;
    margin: 0 !important;
}

.rbc-current-time-indicator {
    background: #FFC850 !important;
    height: 4px !important;
    z-index: 100 !important;
    position: relative;
}

.rbc-current-time-indicator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-2% ,-49.5%) rotate(90deg);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #FFC850;
}

.weekView .rbc-level {
    padding: 0 !important;
}

.weekView .rbc-current-time-indicator {
    display: none !important;
}

.rbc-time-slot {
    padding-top: 6px ;
}

.MuiAvatar-img {
    width: 90px !important;
    height: 90px !important;
    object-fit: cover !important;
}

.MuiAvatar-root {
    border: none !important;
}

.css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    display: none !important;
}

#class .css-julti5-MuiSwitch-root {
    width: 110px !important;
    height: 55px !important;
    border-radius: 15px!important;
}

#class .css-1yjjitx-MuiSwitch-track {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    z-index: -1;
    -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #000;
    opacity: 0.38;
}
#class .css-1i8lvs-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input {
    left: -100%;
    width: 110px;
    height: 60px;
}

.monthView  .rbc-row-content {
    overflow-y: auto;
    height: 180px;
}

.rbc-row-content::-webkit-scrollbar {
    width: 5px;
}

.rbc-row-content::-webkit-scrollbar-thumb {
    background-color: #FFC038;
    border-radius: 15px ;
}

.rbc-row-content::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}

.rbc-row:has(.rbc-button-link) {
    position: sticky;
    top: 0;
    z-index: 10;
}

.ql-editor p a {
    color: #FFC850;
    cursor: pointer;
}

.ql-active, .ql-align:not(.ql-align.ql-active):has(svg line[class="ql-stroke"][x1="3"][x2="15"][y1="9"][y2="9"]) {
    border: 1px solid white !important;
    border-radius: 5px;
}

.ql-active:has(svg line[class="ql-stroke"][x1="3"][x2="15"][y1="9"][y2="9"]) {
    border: none !important;
}

.dayView {
   box-shadow: 4px 4px 20px 0 #021A2B;
;
}

.dayView .rbc-time-gutter.rbc-time-column {
    transform: translateY(-18px);
}

.rbc-time-content {
    overflow-y: unset !important;
    border-top: none !important;
}




