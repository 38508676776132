.contfoot {
    width: 100%;
    padding: 3.5% 0;
    border-top: 1px solid rgba(98, 119, 133, 0.3);
    display: flex;
    margin:  auto;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
@media only screen and (max-width: 1150px) {
    .contfoot {
        gap: 45px;
    }
}

@media only screen and (max-width: 690px) {
    .contfoot {
        justify-content: space-around;
        align-items: center;
        padding: 30px 0;
    }
}

@media only screen and (max-width: 548px) {
    .contfoot {
        padding: 1% 0;
        margin-bottom: -50px;
    }
}
