.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin: 480px 0 100px 0;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 5%;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_en {
    font-size: 57px;
}

.head_ru {
    font-size: 45px;
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5% 5% 1%;
    flex-wrap :wrap;
}

.cont {
    flex-basis: 16.5%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid #627785;
    position: relative;
    gap: 30px;
}

.cont:last-child{
    border: 0;
}

.image {
    width: 45px;
    height: 45px;
}

.stage {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #C2C7CB;
    padding: 5px 7px;
    background: #213E53;
    border-radius: 7px;
}

.job {
    width: 50%;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    line-height: 1.15;
    color: #C2C7CB;
    padding: 5px 6px;
    background: #213E53;
    border-radius: 7px;
}

.text {

    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
}


@media only screen and (max-width: 1515px) {
    .section {
        margin-top: 600px;
    }
}

@media only screen and (max-width: 1400px) {

    .container {
        justify-content: center;
    }

    .cont {
        flex-basis: 30%;
    }

    .cont:nth-child(3) {
        border: none;
    }
}

@media only screen and (max-width: 800px) {

    .section {
        gap: 25px;
        margin-bottom: 50px;
        padding-top: 100px;
    }


    .job {
        width: 70%;
        font-size: 12px;
    }

    .container{
        padding: 0;
    }

    .head_am {
        font-size: 22px !important;
    }
    .head {
        font-size: 30px;
        padding-top: 10%;

    }

    .image {
        width: 25px;
        height: 25px;
    }

    .text {
        font-size: 13px;
    }
}

@media only screen and (max-width: 550px) {
    .cont {
        flex-basis: 50%;
        gap: 17px;
    }

    .cont:nth-child(3) {
        border-right: 1px solid #627785;
    }

    .cont:nth-child(2n) {
        border: none;
    }
}
