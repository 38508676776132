.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 4%;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_en {
    font-size: 57px;
}

.head_ru {
    font-size: 45px;
}

.sliderBlock{
    padding: 0 0 30px;
    margin:  0 auto;
    position: relative;
}


@media only screen and (max-width: 600px) {
    .head_am {
        font-size: 22px;
        white-space: pre-wrap !important;
    }
    .head_am {
        font-size: 22px !important;
    }
    .head {
        font-size: 30px;
    }
}
