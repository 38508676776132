.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: 300;
    line-height: 31px;
    text-decoration: none;
    color: #C4CDD3;
    white-space: nowrap;
    padding-top: 2px;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #EBEBEB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

.contcateg {
    width: 100%;
    display: flex;
    padding: 25px 3% 80px;
    gap:12px;
    flex-direction: column;
    justify-content: start;
}

.icon , .iconopen{
    width: 11px;
    height: 11px;
    margin-right: 4%;
    color:#EBEBEB;
    scale: 2;
    cursor: pointer;
    transition: 0.2s;
}

.iconopen {
    rotate: -180deg;
}

.appdiv {
    display: flex;
    justify-content: center;
    padding-top: 10%;
    gap: 3%;
}

.card {
    width: 45px;
    height: 31px;
}
@media only screen and (max-width: 548px) {

    .text {
        font-family: var(--main-font-family), sans-serif;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        color: #EBEBEB;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        padding-bottom: 10px;
    }
}