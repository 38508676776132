.title{
    color: #FFC038;
    font-family: var(--main-font-family), serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;

}
.circl{
    display: flex;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.container{
    display: flex;
    flex-wrap: wrap;

    gap: 20px;
}
.img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.cont{
    padding-top: 40px;
    padding-bottom: 40px;
}