.parent {
    background: #121623;
    border-radius: 12px;
    z-index: 20;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(67, 73, 101, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}

.titles{
    margin-top: 20px;
    color:  #FFC038;
    font-family: var(--main-font-family);
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.container {
    width: 100%;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 17px;
    flex-wrap: wrap;
}

.containerSettings {
    display: flex;
    gap: 30px 5%;
    padding-right: 10px;
}

.inputSettings {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.contry {
    position: relative;
    width: 70%;
}

.inpcont {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.caption {
    width: 30%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: #AFB9C0;
    white-space: nowrap;

}

.bigcont {
    padding-bottom: 200px;
}

.input {
    width: 70%;
    padding: 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input:focus {
    border: 1.2px solid #FFC038;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.01em;
}

.error {
    position: absolute;
    right: 30px;
    bottom: -28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 13px;
    padding-top: 5px;
    font-weight: 400;
    line-height: 22px;
    color: #CF6679;
}

.errorinp, .phoneerr {
    border: 1.2px solid #CF6679 !important;
}

.noterrinp,.phonenot {
    border: 1.2px solid #66CF8A !important;
}

.phone {
    width: 70%;
    border-radius: 8px;
}

.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}

.radio{
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: 3%;
}

.button {
    width: 100%;
    display: flex;
    justify-content: end;
}

.inputcont {
    width: 48%;
    height: 120px;
}

.eye {
    width: 21px;
    margin-left: -33px;
    margin-bottom: -5px;
    cursor: pointer;
}

.check {
    display: flex;
    justify-content: center;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #DCDDDD;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038;
    text-decoration: underline;
}

.password {
    padding-left: 70px;
    padding-right: 40px;
}

.lock {
    width: 60px;
    height: 50px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 7px 0 0 7px;
    background: #263D4D;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lockimg {
    width: 25px;
    height: 25px;
}

.drop {
    position: absolute;
    left: 30px;
    top: 63px;
    width: 250px;
    height: 200px;
    background: #2F495C;
    border-radius: 0 12px 12px 12px;
}

.triangle {
    position: absolute;
    top: -20px;
    width: 25px;
}

.list {
    position: absolute;
    top: 8%;
    left: 13%;
}

.list li::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url("/public/images/listSquare.png");
    background-size: cover;
    margin-right: 10px;
}


.item {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #EBEBEB;
    padding: 6px 0;
    transition: 0.5s;
}

.contsec {
    justify-content: space-between;
    display: flex;
}

.contthird {
    justify-content: space-between;
    display: flex;
}

.user {
    width: 190px;
    border-radius: 50%;
    padding: 10px;
    border: 2px solid #FFC038;
    z-index: 2;
    margin: 0 40px 15px;
    cursor: pointer;
}

.changed {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #728796;
    padding: 10px 0 0 25px;
}

.personal {
    color: #FFC038;
    font-family: var(--main-font-family), serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 40px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 20px;
    overflow: auto;
    max-height: calc(100vh - 50px);
}

.formContainer::-webkit-scrollbar {
    width: 8px;
}

.formContainer::-webkit-scrollbar-thumb {
    height: 30px;
    border-radius: 5px;
    background: #334e60;
}

.title {
    color:  #FFC038;
    font-family: var(--main-font-family);
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px auto 0;
}

.readOnlyText {
    color: #EBEBEB;
    font-family: var(--main-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}