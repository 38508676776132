.image {
    position: absolute;
    /*right: -50px;*/
    right: 0;
    top: -110px;
    width: 550px;
    /*animation: hand ease-in-out infinite 2.5s;*/
    transform-origin: bottom right;
}

/*@keyframes hand {*/
/*    0% {*/
/*        transform: rotate(5deg);*/
/*    }*/
/*    50% {*/
/*        transform: rotate(-5deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(5deg);*/
/*    }*/
/*}*/

.img {
    width: 92%;
    z-index: 5;
    padding-top: 200px;
}

.terms {
    width: 80%;
    z-index: 5;
    padding-top: 200px;
    padding-bottom: 100px;
}

.shield {
    position: absolute;
    right: 100px;
    top: 170px;
    width: 200px;
}

.cont {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-bottom: 100px;
    padding-top: 130px;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #EBEBEB;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #EBEBEB;
}

div.text > ul {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.bold {
    font-weight: 600;
}

.textcont {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #FFC038;
    cursor: pointer;
    text-decoration: underline;
}

.cookie {
    position: fixed;
    background: #243745;
    left: 20px;
    bottom: 20px;
    z-index: 20;
    border-radius: 12px;
    border: 1px solid #728796;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 15px 10px 40px 20px;
}

.width_am, .width_ru {
    width: 500px;
}

.width_en {
    width: 420px;
}

.manage {
    position: fixed;
    width: 420px;
    background: #243745;
    left: 20px;
    bottom: 20px;
    z-index: 20;
    border-radius: 12px;
    border: 1px solid #728796;
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 15px 25px 20px;
}

.bottom {
    padding-bottom: 35px;
    border-bottom: 1px solid #627785;
}

.info {
    font-family: var(--main-font-family),sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    color: #EBEBEB;
}

.item {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #FFFFFF;
}

.itemcont {
    display: flex;
    gap: 10px;
    align-items: center;
}

.close {
    width: 25px;
    align-self: flex-end;
    margin-right: -5px;
    cursor: pointer;
}

.button {
    width: 100%;
    display: flex;
    gap: 25px;
    padding-top: 5%;
}

.yellow {
    color: #FFB81E;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    margin-top: -10px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.con {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infocont {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.arrow {
    margin-left: 1px;
    font-size: 25px;
    color: #EBEBEB;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.arrowactive {
    transform: rotate(-180deg);
    font-size: 25px;
    color: #EBEBEB;
    margin-left: 1px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}
@media only screen and  (max-width: 1050px) {
    .image {
        position: absolute;
        /*right: -50px;*/
        right: 0;
        top: -110px;
        width: 450px;
        /*animation: hand ease-in-out infinite 2.5s;*/
        transform-origin: bottom right;
    }

}
@media only screen and  (max-width: 850px) {
    .terms{
        width: 50%;
        margin-left:130px
    }
    .shield{
        width: 10%;

    }
}
@media only screen and  (max-width: 700px) {
    .terms{
        width: 50%;
        margin-left:130px;
        padding-top:100px
    }
    .shield{
        width: 10%;
        top:90px;
    }
}
@media only screen and  (max-width: 700px) {
    .terms{
        width: 50%;
        margin-left:80px;
        padding-top:100px
    }
    .cont{padding-top:165px}

}
@media only screen and  (max-width: 600px) {
    .terms{
        width: 80%;
        margin-left:1px;
        padding-top:100px
    }
    .image {
        position: absolute;
        /*right: -50px;*/
        /*transform:translateX(50px);*/
        right: 0;
        top: -10px;
        width: 200px;
        /*animation: hand ease-in-out infinite 2.5s;*/
        transform-origin: bottom right;
    }
    .img{
        padding-top: 130px;
            width:170px;
        /*padding-right: 50px;*/
    }

}
@media only screen and  (max-width: 500px) {
    .terms{
        width: 70%;
        margin-left:-40px;
        padding-top:100px
    }
    .text{
        font-size:18px
    }
    .title{
        font-size:22px

    }
}
@media only screen and  (max-width: 450px) {
    .terms{
        width: 200px;
        margin-left:-80px;

    }
    /*.cont{padding-top:1px}*/
    .cookie {
        width: 94%;
        left: 50%;
        transform: translateX(-50%);
    }

    .manage {
        width: 94%;
        left: 50%;
        transform: translateX(-50%);
    }
    .shield{
        width: 20%;
right: 20px    }
}