.container {
    width: 100%;
}
.contless{
    display: grid;
    grid-template-columns:repeat(2,1fr) ;
}

.courscont{
    width: 98%;
    position: relative;
    align-items: center;
    padding: 10px 0 40px 40px;
    cursor: pointer;
}

.courscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.courscontdis{
    width: 98%;
    position: relative;
    pointer-events: none;
    align-items: center;
    padding: 20px 0 40px 40px;
    margin-top: 20px;
    cursor: not-allowed;
    border: #728796 1px solid;
    border-radius: 32px;
    opacity: 0.6;
}

.course {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.img{
    width: 85%;
}

.top{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topdiv{
    width: 70%;
}

.num {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 50px !important;
    font-weight: 500;
    text-align: left;
    color: #FFC038;
    margin-bottom: 15px;
}

.title{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 30px !important;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    margin-top: -20px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px !important;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.imgop {
    width: 55px;
    height: 55px;
    position: absolute;
    right: -60px;
    top: 0;
}
.imgcl{
    position: absolute;
    top: 0;
    left: 10px;
    width: 55px;
    height: 55px;
}

.switch {
    pointer-events: auto;
}
.lin{
    display: flex;
    justify-content: flex-end;
}
.link{
    width: 210px;
    height: 49px;
    padding-left: 10px;
    display: flex;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.link:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.link:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}
@media only screen and (max-width: 1700px) {
    .courscont, .courscontdis {
        padding : 15px 0 30px 30px;
    }

    .contless {
        gap: 20px;
    }

    .course {
        font-size: 35px;
    }

    .img {
        width: 75%;
    }

    .num {
        font-size: 80px;
    }

    .title {
        font-size: 45px;
    }

    .text {
        font-size: 18px;
        padding-top: 15px;
    }

}