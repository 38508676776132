.cont{
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    padding-right: 5px;
    background: #12222D;
    margin-top: 40px;
    z-index: 100;
    border-radius: 5px;
}


.container{
    max-height: 125px;
    overflow-y: scroll;
}

.container::-webkit-scrollbar{
    width: 8px;
}

.container::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #a4a8ab;
}

.leb{
    color:#FFFFFF;
    font-size:20px;
    font-family: var(--main-font-family), sans-serif;

}

.countryName{
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    color: #EBEBEB;
    margin-top: 7px;
    cursor: pointer;
    padding: 15px 11px 15px 20px;
}
.countryName:hover{
    color: #FFC038;
}
.checkboxContainer{
    display:flex;
    padding:10px 30px;
}
@media only screen and (max-width: 470px) {
    .countryName {
        font-size: 16px;
    }
}