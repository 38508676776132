.container {
    flex-basis: 33%;
    padding: 35px 0 45px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 2;
    min-height: 850px;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038;
    padding-left: 45px;
}

.paddingTop {
    padding-top: 20px;
}

.cont {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    overflow-y: auto;
}

.contMaxSmall {
    max-height: 170px;
}

.messages {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #AFB9C0;
    padding-right: 35px;
}

.contMaxBig {
    max-height: 800px;
}

.cont::-webkit-scrollbar {
    width: 5px;
}

.cont::-webkit-scrollbar-thumb {
    background-color: #FFC038;
    border-radius: 15px 0 0 15px;
}

.cont::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}

.memberCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}


.smallCont {
    padding: 8px 0;
    border-bottom: 1px solid rgba(114, 135, 150, 0.7);
    border-top: 1px solid rgba(114, 135, 150, 0.7);
    cursor: pointer;
}

.member {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    gap: 25px;
    border-radius: 10px;
}

.member:hover {
    background: rgba(20, 62, 89, 0.5);
}

.selected {
    background: rgba(20, 62, 89, 0.5);
}

.image {
    border-radius: 50%;
    width: auto;
    height: 57px;
}

.nameCont {
    display: flex;
    flex-direction: column;
}

.name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: #BCD3DB;
}

.lastMessage {
    width: 200px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #FFFFFF;
}

.envelope {
    width: 25px;
    height: auto;
    margin-left: auto;
}

.ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.user {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #66CF8A;
    position: absolute;
    bottom: 3px;
    right: 2px;
}

.notReadMessages {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #FFC038;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-left: auto;
}