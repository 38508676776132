.container {
    width: 100%;
    padding: 4% 5%;
    background: #243745;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 80px;
    gap: 25px;
}
.image {
    width: 45%;
    transform: translate(-90px, -15px);
    margin-bottom: -50px;
    height: auto;
    align-self: center;
}

.img {
    width: 100px;
}

.mail {
    font-weight: 600;
    letter-spacing: 0.01em;
    font-family: "Fira Sans", sans-serif !important;
}

.heading {
    font-family: var(--main-font-family), sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    max-width: 600px;
    color: #EBEBEB;
}

.link {
    font-family: var(--main-font-family),sans-serif;
    font-size: 17px;
    font-weight: 200;
    color: #FFC038;
}

.spam {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #AFB9C0 ;
    padding-top: 25px;
    margin-bottom: -15px;
}

.timer {
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #FFC038;
    margin-top: 15px;
}

.cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
}
.conts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
}

@media only screen and (max-width: 800px) {
    .image {
        width: 150px;
        margin-bottom: -30px;
    }
}

@media only screen and (max-width: 470px) {
    .container{
        gap: 20px;
        padding-bottom: 0;
        margin-bottom: 10px;
        align-items: center;
    }

    .cont {
        align-items: unset;
    }

    .heading {
        font-size: 21px;
    }

    .text, .link {
        font-size: 16px;
    }
}