.container {
    margin-top: 20px;
    padding: 20px 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    border-radius: 32px;
    border: 1px solid rgba(255, 192, 56, 0.10);
    background:  #162A38;
    margin-bottom: 32px;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 120%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.left{
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.img{
    width: 80px;
    height: 80px;
    border-radius:50%;
}
.title{
    color: #BCD3DB;
    font-family: var(--main-font-family), serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.right{
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    row-gap: 20px;
    padding-right: 50px;
}
.cont{
    border-radius: 32px;
    background:  #143E59;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
}
.group{
    color: #BCD3DB;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.qa{
    color:  #EBEBEB;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
@media only screen and (max-width: 1400px){
    .title{
        font-size:28px;
    }
    .img{
        width: 60px;
        height: 60px;
    }
}