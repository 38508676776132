.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 4%;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_en {
    font-size: 57px;
}

.head_ru {
    font-size: 45px;
}

.sliderBlock{
    padding: 50px 0;
    margin: 0 auto;
}

.hero {
    width: 28%;
    height: auto;
    position: absolute;
    top: 1800px;
    left:0;
    z-index: -1;
}

@media only screen and (max-width: 1300px) {
    .hero {
        width: 23%;
        top: 1850px;
    }
}

@media only screen and (max-width: 1200px) {
    .hero {
        width: 0;
    }
}


@media only screen and (max-width: 600px) {
    .sliderBlock{
        padding: 25px 0 60px;
    }
    .head_am {
        font-size: 22px !important;
    }
    .head {
        font-size: 30px;
    }
}

