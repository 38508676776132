.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 34, 45, 0.5);
    z-index: 100;
}

.modal {
    max-width: 800px;
    width: 100%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    position: relative;
    background: #243745;
    border-radius: 32px;
    border: 1px solid #728796;
    z-index: 3;
    padding: 30px 0;
    overflow-y: auto;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFC038;
}

.text, .contentCont > ul li {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
}

/*.text::first-letter {*/
/*    padding-left: 50px;*/
/*}*/

.contentCont {
    padding: 0 85px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contentCont > ul {
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    gap: 8px
}

/*.contentCont .text:first-child {*/
/*    padding-left: 20px*/
/*}*/

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #FFC038;
    text-decoration: underline;
}

.agree {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: white;
}

.yellow {
    color: #FFC038;
}

.agreeCont {
    display: flex;
    gap: 5px;
    align-items: center;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 60px;
}

.close {
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    top: 20px;
    right: 30px;
    color: #AFB9C0;
}

.close:hover {
    color: #FFC850;
}

@media screen and (max-width: 800px) {
    .modal {
        width: 95%;
    }

    .title {
        font-size: 18px;
        margin-top: 15px;
    }

    .contentCont {
        padding: 0 20px
    }

    .text, .contentCont > ul li, .link {
        font-size: 15px;
    }

    .agree {
        font-size: 14px
    }
}

@media screen and (max-width: 400px) {
    .modal {
        height: 95%;
        padding-bottom: 50px;
        border-radius: 15px;
    }

    .close {
        font-size: 24px;
        top: 15px;
        right: 20px;
    }

    .buttons {
        gap: 20px
    }
}
