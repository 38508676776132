.container {
    display: flex;
    flex-direction: column;
}

.first {
    flex-basis: 60%;
    gap: 40px
    
}

.second {
    flex-basis: 40%;
    gap: 40px;
}

.section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.statistics {
    width: 100%;
    padding: 50px 20px; 
    background-color: #0f212a;
    border-radius: 32px;
    border: 2px solid #F4B41A;
    display: flex;
    justify-content: space-between;
}

.countContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
}

.countContainer > p {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #AFB9C0;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-bottom: 30px; 
}

.title_en {
    font-size: 35px;
}

.title_am {
    font-size: 28px;
}