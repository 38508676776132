.layout {
    width: 100%;
    padding: 0 6%;
}

@media only screen and (max-width: 470px) {
    .layout {
        padding: 0 3%;
    }
}
