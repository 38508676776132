.title{
    margin-top: 60px;
    margin-bottom: 45px;
    color:#FFC038;
    font-family: var(--main-font-family);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
}
.btn{
    color: #FFC038;
    font-family: var(--main-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border-radius: 25px;
    border: 2px solid  #FFC038;
    padding: 10px 35px;
    background: none;
    cursor: pointer;
}
.personal{
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 24px;
}
.circldiv{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 24px;
}
.circl{
    display: flex;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.bottom{
    margin-top: 69px;
    padding-bottom: 60px;
}
.img{
    width: 26px;
    height: 26px;
    cursor: pointer;
}