.container {
    flex-basis: 60%;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 0.2fr 1fr 0.5fr;
    gap: 15px;
}

.pointsContainer {
    width: 50%;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 25px;
}

.pointsContainer::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 100%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.sectionTitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #EBEBEB;
    margin-bottom: 10px;
}

.points {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #EBEBEB;
}

.overview {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.overview_en {
    font-size: 35px;
}

.overview_am {
    font-size: 26px;
}

.titlecont{
    grid-column: 9 span;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.pointscont {
    grid-column: 9 span;
    background-color: #162A38;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 30px;
    display: flex;
    justify-content: space-between;
}

.pointscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.textcont {
    display: flex;
    flex-direction: column;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.text{
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #BCD3DB;
}

.image {
    width: 230px;
    border-bottom: 0.5px #728796 solid;
    align-self: flex-end;
}

.barcont {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    padding-top: 25px;
}

.progress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top:10px;
}

.score {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #AFB9C0;
}

.info {
    width: 24px;
    height: 24px;
    background: url("../../../../public/images/info.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    margin: 18px 52px 0 0;
}

.info:hover {
    background: url("../../../../public/images/infoHover.png") no-repeat;
    background-size: contain;
}

.percent {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #728796;
}


.modal {
    width: 400px;
    background: #143E59;
    border-radius: 15px;
    padding: 15px 20px;
    position: absolute;
    top: -68px;
    right: -60px;
    box-shadow: 0 0 40px 0 #FFC03833;
    z-index: 3;
}

.modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-15deg, #F4B41A 0%, rgba(255, 255, 255, 0) 50%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: 5;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #143E59;
    transform: rotate(180deg);
    margin: 2rem;
    position: absolute;
    bottom: -45px;
    right: 60px;
    z-index: 10;
}

.yellow{
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rgba(244, 180, 26, 0.8);
    transform: rotate(180deg);
    margin: 2rem;
    position: absolute;
    bottom: -46.5px;
    right: 75px;
    z-index: -5;
}

.progressCont {
    grid-column: 9 span;
    display: flex;
    gap: 10px;

}
.progressCont1 {
    display: flex;
    width: 800px;
    gap: 10px;

}
.container1 {
    display: flex;
    order: -3;
    flex-direction:column;
    overflow: auto;

}
.container1::-webkit-scrollbar{
    width: 3px;
}

.container1::-webkit-scrollbar-thumb{
    height: 10px;
    border-radius: 5px;
    background: #1a3749;
}
@media only screen and (max-width:800px ) {
    .progressCont{
        gap:10px;
        overflow-x: auto;
        flex-wrap: nowrap;

    }
    .container{
        display: flex;
        flex-direction:column;
    }
}

@media only screen and (max-width:650px ) {
   .pointscont{
       flex-direction:column;
   }
    .image{
        align-self:center;
    }
    .progressCont1{
        width:600px;
    }
}

