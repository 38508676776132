.forWhomIsCourse{
    margin-bottom: 125px;
}

.title {
    font-weight: 500;
    font-family: var(--english-font-teko), sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 70px;
    color: #FFC038;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.inner{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.block{
    background: rgba(22, 42, 56, 1);
    border-radius: 22px;
    padding: 30px;
    font-family: var(--main-font-family), sans-serif;
    z-index: 2;
    position: relative;
}

.block:nth-child(1) {
    background-color: #132531;
}

.block::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 22px;
    padding: 1.5px 1.5px;
    background: linear-gradient(-30deg, #F4B41A 0%, rgba(255, 255, 255, 0) 53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.block p {
    color: rgba(255, 192, 56, 1);
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
}

.block span{
    color: white;
    font-size: 18px;
    font-weight: 400;
}
@media only screen and (max-width: 848px) {

    .title{
        font-size:30px

    }
    .title_am{
        font-size:22px

    }
    .forWhomIsCourse{
        margin-bottom: 40px;

    }

}

@media only screen and (max-width: 450px) {
    .block p {
        font-size: 18px
    }

    .block span {
        font-size: 16px
    }
}
