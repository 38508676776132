
.tit {
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}

.btndiv{
    margin-top:20px;
    margin-bottom:20px;
    width:100%;
    display: flex;
    justify-content: center;
    gap:50px;

}
.tagOption {
    width: 170px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    cursor: pointer;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    background: none;
}

.selected {
    border: 2px solid #FFC038;
    color: #FFC038;
}

.cont{
    border-radius: 48px;
    border: 1px solid #F4B41A;
    background: #162A38;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 32px;
}
.inpDiv{
    position: relative;
    width: 100%;
    padding-inline:150px ;
    margin-top: 32px;
}
.name{
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}
.imgclosk{
    width: 50px;
    height: 50px;
}
.controler{
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
}
.controler1{
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.controler2{
    position: relative;
    border-radius: 12px;
    border-end-end-radius: 0;
    border-bottom-left-radius: 0;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
}
.label{
    position: relative;
}
.controler > div{
    display: flex;
    column-gap: 20px;
}
.only{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.img{
    width: 26px;
    height: 26px;
}
.input{
    width: 100%;
    color: #ffffff;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}

.textarea {
    width: 100%;
    height: 50px;
    caret-color: #FFFFFF;
    resize: none;
    outline: none;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #EBEBEB;
    background: transparent;
    border: none;
}

.textarea::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.textarea::-webkit-scrollbar {
    width: 5px;
}

.textarea::-webkit-scrollbar-thumb {
    background-color: #728796;
    border-radius: 15px;
}

.btns{
    margin-top: 30px;
}
.questdiv{
    margin-top: 40px;
    align-self: flex-start;
    padding-left: 190px;
}
.correct{
    width: 40px;
    height: 30px;
    margin: auto 0;

}
.incorrect{
    opacity: 0.4;
    width: 40px;
    height: 30px;
    margin: auto 0;
}

.delete{
    position: relative;
    width: 100%;
    /*padding-inline:150px ;*/
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.deleteButton{
    /*margin-top: 20px;*/
    cursor: pointer;
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 15px;
    border: 1px solid #AFB9C0;
    background: rgba(255, 192, 56, 0.05);
    display: inline-flex;
    padding: 12px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.que{
    color:  #EBEBEB;
    font-family: var(--main-font-family), serif;
    font-size: 28px;
    padding-left: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
}
.top{
    margin-top: 30px;
    width: 100%;
    display: flex;
    padding-inline: 120px;
    justify-content: space-between;
}
.top > p:first-child{
    color:  #FFC038;
    font-family: var(--main-font-family), serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.top > p:last-child{
    color: #BCD3DB;
    text-align: right;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.quest{
    cursor: pointer;
    color:  #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 15px;
    border: 1px solid #FFC038;
    background: rgba(255, 192, 56, 0.05);
    display: inline-flex;
    padding: 15px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.bot{
    width:100%
}
.in{
    position: relative;
    width: 100%;
    padding-inline:150px ;
    margin-top: 32px;
}
.bts{
    margin-top:60px;
    display:flex;
    justify-content: center;
}
.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}
.conts{
    width:100%;
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
}
.conts > div{
    width:95%;
    display: flex;
    column-gap: 20px;
}