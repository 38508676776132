.courseProgram {
    margin-bottom: 125px;
    margin-top: 120px;
}

.title {
    font-weight: 500;
    font-family: var(--english-font-teko),sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 40px;
    color: #FFC038;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.inner{
    padding: 80px 40px 40px 40px;
    background: #162A38 ;
    border-radius: 32px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.inner table {
    width: 100%;
    border-collapse:separate; 
    border-spacing: 0 0.8em;  
    font-family: var(--main-font-family), sans-serif;
}

.inner table tr{
    background: rgba(19, 41, 57, 1);
    border-radius: 15px;
    color: white;
    font-size: 18px;
}

.inner table tr td{
    padding-top: 15px;
    padding-bottom: 15px;
}

.info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
}

.single {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.single::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 0.4px 1.5px 1.5px;
    background: linear-gradient(-8deg, #F4B41A 0%, rgba(255, 255, 255, 0) 80%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.text{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    max-width: 90%;
}

.text span {
    font-size: 20px
}

.text p{
    font-size: 16px;
}


.inner table tr td:first-child{
    color: rgba(255, 192, 56, 1);
    padding: 0 20px;
    font-weight: 500;
    width: 100px;
}

.inner table td:first-child {
  border-radius: 10px 0 0 10px;
}

.inner table td:last-child {
  border-radius: 0 10px 10px 0;
}

.day{
    width: 150px !important;
    font-size: 20px;
}

.seeAll{
    color: rgba(188, 211, 219, 1);
    font-size: 14px;
    font-family: var(--main-font-family), sans-serif;
    margin-right: 25px;
    cursor: pointer;
}

.icon{
    width: 35px;
    align-self: flex-start;
    cursor: pointer;
}

@media only screen and (max-width: 848px) {

    .title{
        font-size:30px

    }
    .title_am{
        font-size:22px

    }
    .courseProgram {
        margin-bottom: 85px;
        margin-top: 10px;
    }
    .inner{
        padding: 40px 5px 40px 5px;
        background: #162A38 ;
        border-radius: 32px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
    .text span {
        font-size: 16px
    }

    .text p{
        font-size: 14px;
    }
}