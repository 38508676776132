.container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox {
    -webkit-appearance: none;
    appearance: none;
    width: 23px;
    height: 23px;
    border-radius: 4px;
    margin-right: 13px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    outline: none;
    cursor: pointer;
}

.checkbox:not(.checkbox:disabled):hover {
    opacity: 0.95;
    box-shadow: 0 0 0 13px rgba(255, 255, 255, 0.1);
    clip-path: circle(90%);
}

.checkbox:checked {
    background: #FFC038;
    border: 2px solid #FFC038;
    position: relative;
}

.checkbox:checked:not(.checkbox:disabled):hover {
    opacity: 0.95;
    box-shadow: 0 0 0 13px rgba(255, 192, 56, 0.1);
    clip-path: circle(90%);
}

.checkbox:disabled {
    opacity: 0.5;
}

.checkbox:checked::before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 5px;
    left: 6px;
    transform: rotate(45deg);
    height: 12px;
    width: 5px;
    border-right: 3px solid #12222D;
    border-bottom: 3px solid #12222D;
}

.checkbox:disabled {
    cursor: default;
}


@media only screen and (max-width: 400px) {
    .checkbox {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .checkbox:checked::before {
        bottom: 4px;
        left: 5px;
        height: 10px;
        width: 3px;
    }
}




