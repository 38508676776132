.content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
}

.notHover:hover {
    color: #C4CDD3 !important;
}

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.check {
    display: flex;
    justify-content: center;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #EBEBEB;
}

.lin {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    color: #FFC038;
}

.caption {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: #AFB9C0;
    white-space: nowrap;

}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 31px;
    text-decoration: none;
    color: #C4CDD3;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.img {
    width: 23px;
}
.imgs {
    width: 26px;
    height: 22px;
    color: #FFB81E;

}
.conticon {
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.icon {
    width: 37px;
    height: 37px;
    opacity: .95;
}

.quest {
    font-family: var(--main-font-family), sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFC038;
    /*padding-bottom: 10px;*/
}

.image {
    width: 170px;
    transform: translate(70px, 20px);
}

.input {
    padding: 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input:focus {
    border: 1.2px solid #FFC038;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.01em;
}

.error {
    font-family: var(--main-font-family), sans-serif;
    font-size: 13px;
    padding-top: 5px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #CF6679;
}

.errorinp, .phoneerr {
    border: 1.2px solid #CF6679 !important;
}

.noterrinp,.phonenot {
    border: 1.2px solid #66CF8A !important;
}

.phone {
    border-radius: 8px;
}

.inputcont {
    width: 48%;
    height: 120px;
}

.message {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin: -15px 0;
    display: flex;
    align-items: center;
    gap: 5px
}

.success {
    color: #66CF8A
}

.error {
    color: #CF6679;
}

.messageImg {
    width: 28px;
    height: auto;
}
@media only screen and (max-width: 1000px) {

    .image{
        width:100px
    }

}
@media only screen and (max-width: 500px) {

    .image{
        width:120px
    }
    .container{
        flex-direction: column;
        width:100%;
    }
    .inputcont{
        width:100%;
    }
    .title_am{
        font-size:42px;
    }
}
