.dropdown {
    background: #162A38;
    position: relative;
    border-radius: 5px;
    padding: 10px 25px ;
}

.droplang {
    background: #213E53;
    position: relative;
    border-radius: 5px;
    padding: 7px 20px;
}

.triangle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10%;
    z-index: -1;
}


.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    text-decoration: none;
    color: #EBEBEB;
    cursor: pointer;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

@media only screen and (max-width: 1024px) {
    .dropdown {
        padding: 7px 20px;
    }

    .droplang {
        padding: 6px 17px;
    }

    .link {
        font-size: 16px;
        line-height: 33px;
    }
}


