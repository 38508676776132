.container {
    width: 100%;
    padding:18% 0 20%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.cont {
    width: 37%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
    z-index: 2;
}

.button {
    width: 100%;
    display: flex;
    gap: 25px;
    padding-top: 5%;
}


.image {
    width: 37%;
    height: auto;
    position: absolute;
    top: 200px;
    right: 7%;
    z-index: 2;
}

.vector {
    width: 58%;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
}

@keyframes slideAndRotate {
    to {
        -webkit-transform: translateX(100%) rotate(360deg);
        transform: translateX(100%) rotate(360deg);
    }
}

.rocketCont {
    position: absolute;
    top: 100px;
    left: 15%;
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
    transition: transform 2s ease-in-out;
    z-index: 5;
}

.animate {
    animation: slideAndRotate 2s forwards;
    -webkit-animation: slideAndRotate 2s forwards;
}

.rocket {
    width: 45%;
    height: auto;
    -webkit-transform-origin: center center;
    transform-origin: center center;
}

@media only screen and (max-width: 1700px) {
    .rocketCont {
        left: 8%;
        transform: translateX(-60%);
        -webkit-transform: translateX(-60%);
    }

    .rocket {
        width: 40%;
    }
}

.rock {
    position: absolute;
    top: 90px;
    right: 40%;
    width: 18%;
}

.head {
    width: 100%;
}

.big {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-align: left;
    color: #FFC038;
}

.big_en {
    font-size: 80px;
    white-space: nowrap;
    margin-top: -20px;
}

.big_am {
    font-size: 50px;
}

.big_ru {
    font-size: 45px;
}

.small {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #EBEBEB;
}

.small_en {
    font-size: 57px;
}

.small_am {
    font-size: 40px;
}

.small_ru {
    font-size: 38px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4;
    text-align: left;
    color: #EBEBEB;
}

@media only screen and  (max-width: 1300px) {
    .image {
        top: 160px;
        right: 10%;
    }

    .vector {
        width: 63%;
    }

    .rocket {
        width: 13%;
        right: 37%;
        top: 2%;
    }
}

@media only screen and  (max-width: 848px) {
    .cont {
        padding-top: 15%;
    }
    .big_am {
        font-size: 22px !important;
    }
    .small_am {
        font-size: 22px !important;
        line-height: 2.2;

    }
    .image {
        width: 42%;
        right: 0;
    }

    .vector {
        width: 78%;
    }

    .rocket {
        right: 33%;
        top: 2%;
        width: 19%;
    }

    .button {
        gap: 35px;
    }

    .text {
        font-size: 16px;
        width: 270px;
    }

    .big {
        font-size: 50px;
    }

    .small {
        font-size: 40px;
    }
}

@media only screen and  (max-width: 460px) {
    .container {
        padding-top: 60%;
        padding-bottom:1px;
    }

    .vector {
        height: 290px;
        width: 98%;
    }

    .image {
        top: 50px;
        width: 195px;
        height: 215px;
    }

    .big {
        font-size: 30px;
        margin-top: -10px;
    }

    .small {
        font-size: 29px;
        white-space: nowrap;
    }

    .cont {
        gap: 8px;
    }

}







