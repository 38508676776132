.progressBar {
    width: 170px;
    margin: auto;
    font-family: var(--main-font-family), sans-serif;
}

@media only screen and (max-width: 1700px) {
    .progressBar {
        width: 160px;
    }
}