.container {
    padding: 12% 0 6%;
    display: flex;
    justify-content: space-between;
}

.frame {
    position: absolute;
    top: -180px;
    left: -120px;
    width: 480px;
    height: auto;
    z-index: -1;
}

.withoutFrame {
    top: -300px;
}

.imgcont {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    gap: 55px;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    width: 300px;
    color: #EBEBEB;
}

.head_am {
    font-size: 35px;
    line-height: 1.2;
}

.head_ru {
    font-size: 45px;
    line-height: 1.1;
}

.head_en {
    font-size: 57px;
    line-height: 1;
}

.img {
    width: 60%;
    height: auto;
}

.line {
    max-width: 336px;
    border-color: rgba(98, 119, 133, 0.3);
    margin-top: -50px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #EBEBEB;
}

.cont {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: -40px;
}

.image {
    width: 150px;
}

.formcont {
    flex-basis: 75%;
}

.contain {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

@media only screen and (max-width: 1200px) {
    .frame {
        width: 415px;
    }

    .image{
        width: 125px;
    }

    .line {
        width: 280px;
    }

    .head {
        font-size: 45px;
    }
}
@media only screen and (max-width: 1000px) {

    .frame{
        width:350px
    }

}

@media only screen and (max-width: 850px) {
    .head {
        margin-top: 50px;
    }

    .formcont {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        flex-wrap: wrap;
    }

    .imgcont {
        gap: 45px;
        margin-bottom: 80px;
    }

    .img {
        width: 200px;
    }

    .formcont {
       flex-basis: 100%;
        align-self: center;
    }

    .head {
        font-size: 40px;
    }
}
@media only screen and (max-width: 450px) {

    .frame{
        width:430px
    }

}