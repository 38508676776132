.linerdiv {
    width: 40%;
}
.title{
    margin-top: 60px;
    margin-bottom: 45px;
    color:#FFC038;
    /* Big text 1.2 */
    font-family: var(--main-font-family);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
}
.linercontainer{
    display: flex;
    flex-wrap: wrap;
 column-gap: 30px;
}
.lestit{
    margin-left: 80px;
    color: #FFF;
    font-family:var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 24px;
}

.linerbotdiv{
    position: absolute;
    display: flex;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-top: 10px;
}
.quest {
    width: 145px;
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
}
.lin{
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.dvprogres{
    width: 260px;
    position: relative;
}


@media only screen and (max-width: 1300px){
    .dvprogres{
        width: 250px;
    }
    .linercontainer{
        column-gap: 60px;
    }
}