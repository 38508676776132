.parent {
    background: #121623;
    border-radius: 5px;
    z-index: 20;
    max-width: 720px;
    /*height: 80vh;*/
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    display: none;
    z-index: 100;
    background: rgba(67, 73, 101, 0.7);
    border-radius: 32px;
}
/*.modal {*/
/*    max-width: 700px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 30px;*/
/*    align-items: center;*/
/*    position: relative;*/
/*    background: rgba(18, 34, 45, 1);*/
/*    border-radius: 32px;*/
/*    z-index: 3;*/
/*    padding: 30px 40px;*/
/*}*/
.modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;

}
.titles{
    margin-top: 20px;
    color:  #FFC038;
    font-family: var(--main-font-family);
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.btns{
    padding: 10px 60px;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.btns:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.btns:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}


.btndiv{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid  rgb(113, 134, 148, 0.5);
    margin-top: 32px;
    padding: 22px 0;

}
.cont{
    display: flex;
    flex-direction: column;
    /*row-gap: 10px;*/
    width: 70%;
    margin-top: 20px;
    height: 400px;
    overflow: auto;
}
.cont::-webkit-scrollbar{
    width: 8px;
}

.cont::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #334e60;
}

.devinp{
    width: 100%;
    display: flex;
    align-items: center;

}
.text{
    width: 30%;
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.inp{
    width: 70%;
    padding: 15px;
    border: none;
    border-radius: 12px;
    background: #243745;
    color:  #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.label {
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}
.active{
    display: inline-flex;
    padding: 5px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 28px;
    background:  #FFD1D1;
    border: none;
    color:  #12222D;
    font-family: var(--main-font-family), serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.unactive{
    display: inline-flex;
    padding: 5px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: none;
    border-radius: 28px;
    border: 2px solid  #9EA1D4;
    color:  #FFFFFF;
    font-family: var(--main-font-family), serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.role{
    margin-top: 20px;
    width: 70%;
    display: flex;
    align-items: center;
}
.role > p {
    color: #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 30%;
}
.role > div {
    display: flex;
    column-gap: 30px;
}
.role > div > button {
    cursor: pointer;
}
.circl{
    display: flex;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
}
.selected{
    display: flex;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #f8bf06;
    color: #AFB9C0;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
}
.container{
    display: flex;
    flex-wrap: wrap;

    gap: 20px;
}
.img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.devinp{
    width: 100%;
    display: flex;
    align-items: center;

}
.pos{
    position:relative;
    width: 70%;
}
.inps{
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 12px;
    background: #243745;
    color:  #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
@media only screen and (max-width: 640px){
    .parent{
        width: 90%;
    }
    .p{
        text-align: center;
    }
}