.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 80px 15px;
}


.header {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-bottom: 10px;
    align-self: center;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 1.4;
    text-align: left;
    color: white;
}

.name {
    font-weight: 700;
}

.label {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.caption {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: #AFB9C0;
    white-space: nowrap;

}

.input {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 400;
    color: #EBEBEB;
}

.input:focus {
    border: 1.2px solid #FFC038 !important;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.01em;
}

.changed {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #728796;
    padding: 10px 0 0 25px;
}

.inputCont {
    padding-top: 15px;
}

.link {
    text-decoration: underline;
    font-weight: 700;
    color: inherit;
}

.message {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 5px;
}

.success {
    color: #66CF8A
}

.errorMessage {
    color: #CF6679;
}

.messageImg {
    width: 28px;
    height: auto;
}