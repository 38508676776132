.paymentMethods{
    padding-top: 100px;
    margin-bottom: 125px;
    position: relative;
    z-index: 2;
}

.title {
    font-weight: 500;
    font-family: var(--english-font-teko), sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
    color: #FFC038;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
}

.innerOneMethod {
    display: flex;
    justify-content: center;
}

.method {
    height: 570px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    padding: 50px 20px 80px;
    text-align: center;
    border-radius: 32px;
    background: rgba(22, 42, 56, 1);
    position: relative;
    z-index: 2;
}

.methodFlex {
    flex-basis: 36%
}

.method:nth-child(2) {
    background-color: #132531;
}

.method:nth-child(2) span.price, .method:nth-child(2) .currency, .method:nth-child(2) .priceCont > p {
    color: white !important;
}

.method::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px 1.5px;
    background: linear-gradient(-50deg, #F4B41A 0%, rgba(255, 255, 255, 0) 53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.method p{
    color: rgba(188, 211, 219, 1);
    margin-bottom: 32px;
    font-size: 22px;
    text-transform: uppercase;
}

.method span{
    margin-bottom: 72px;
    color: white;
    font-weight: 400;
    font-size: 20px;
}

span.price{
    font-weight: 500;
    font-family: 'Teko', sans-serif;
    font-size: 72px;
    position: absolute;
    top: 50%;
}

span.price {
    color: #BCD3DB;
}

.method button {
    position: absolute;
    bottom: 50px
}

.currency {
    font-family: 'Fira Sans', sans-serif;
    font-size: 38px !important;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    margin-left: 5px;
}

.saleCurrency {
    font-family: 'Fira Sans', sans-serif;
    font-size: 22px !important;
    font-weight: 500;
    margin: 22px 0 0 -22px;
}

.currency {
    color: #BCD3DB !important;
}

.discount {
    font-family: 'Teko', sans-serif !important;
    font-size: 45px !important;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: center;
    color: #728796;
}

.priceCont {
    position: absolute;
    bottom: 16%;
    display: flex;
    gap: 25px;
}


.initialPrice {
    font-family: 'Teko', sans-serif !important;
    font-size: 45px !important;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: center;
    position: relative;
    color: #728796;
}

.initialPrice::after {
    content: '';
    position: absolute;
    top: 28px;
    left: -7px;
    right: 0;
    height: 2px;
    width: 120%;
    background: #E7E7E7;
    transform: rotate(-15deg);
}
@media only screen and (max-width: 848px) {

   .title{
       font-size:30px

   }
    .title_am{
        font-size:22px

    }
    .inner{
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));

    }
    .paymentMethods{
        padding-top:40px;
        margin-bottom: 25px;
    }
}