.linerdiv {
    width: 40%;
}
.title{
    margin-top: 60px;
    margin-bottom: 45px;
    color:#FFC038;
    /* Big text 1.2 */
    font-family: var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
}
.linercontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 40px;
    /*column-gap: 60px;*/
}
.lestit{
    color: #FFF;
    font-family:var(--main-font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 24px;
}

.linerbotdiv{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.linerbotdiv > p {
    color:#BCD3DB;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}