
.cont{
    display: flex;
    justify-content: space-between;
}

.title{
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}
.link{
    display: flex;
    padding-inline:10px ;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.link:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.link:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}
.certification {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}


.container {
    margin-top: 20px;
    padding: 20px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 120%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.performances {
    font-family: Fira Sans, sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFC038;
}

.numberCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table tr td {
    width: 100%;
    padding: 13px 0;
    text-align: start;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
}
.table tr td:nth-child(1){
    width: 60%;
}
.table tr td:nth-child(2){
    width: 20%;
}

.divon{
    display: flex;
    justify-content: space-evenly;
}
.table tr:not(.table thead tr) {
    display:flex;
    justify-content: space-between;
    border-top: 1px solid #728796;
    /*border-bottom: 1px solid #728796;*/
}

.table tr:not(.table thead tr):first-child {
    border-top: none;
}

.table thead tr td{
    width: 100%;
    padding: 10px 0 20px;
    text-align: start;
    vertical-align: middle;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #728796;
}
.table thead tr td:nth-child(1){
    width: 60%;
}
.table thead tr td:nth-child(2){
    width: 28%;
}



.line {
    height: 3px;
    width: 15%;
    background-color: #728796;
    border: none;
    margin: auto;
}

.cell {
    display: flex;
    align-items: center;
    gap: 10px;
}

.student {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.date {
    color: #BCD3DB;
    font-family: var(--main-font-family), serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.name {
    font-family: var(--main-font-family),sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    color: #BCD3DB;
    padding-left: 20px;
}
.img{
    width: 30px;
    height: 30px;
    cursor: pointer;

}

.type {
    color:  #BCD3DB;
    font-family: var(--main-font-family), serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.participation {
    color: #BCD3DB;
}

.excellence {
    color: #FFC038;
}

.number {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #BCD3DB;
    padding-right: 40px;
}

.button {
    padding: 13px 30px;
    border: 1px solid #FFC038;
    border-radius: 15px;
    background: #FFC0380D;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: white;
    align-self: center;
    margin-top: 25px;
    cursor: pointer;
}
