.container{
    width: 470px;
    position: absolute;
    top: 130px;
    right: 2%;
    display: flex;
    flex-direction: column;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 5px 4px 80px 0 #0D1A23;
}

.top {
    top: 100px;
}

.topCont {
    border-radius: 10px 10px 0 0;
    background: #162A38;
    padding: 18px 15px;
}

.notifications {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.notificationCont {
    max-height: 75vh;
    overflow-y: auto;
    padding: 5px 15px ;
    display: flex;
    flex-direction: column;
    gap: 11px;
    background: #143E59;
    border-radius: 0 0 10px 10px;
}

.notificationHeight {
    height: 200px;
}

.notificationMinHeight {
    min-height: 40vh;
}

.noNote {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #AFB9C0;
    margin: auto auto;

}

.notificationCont::-webkit-scrollbar {
    width: 5px;
}

.notificationCont::-webkit-scrollbar-thumb {
    background-color: #728796;
    border-radius: 2px;
}

.notification {
    background: #12222D;
    border-radius: 5px;
    padding: 15px 20px 12px 10px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.titlecont {
    display: flex;
    justify-content: space-between;
}


.notCont {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.typeCont {
    display: flex;
    gap: 8px;
    align-items: center;
}

.type {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #EBEBEB;
}

.line {
    height: 0.5px;
    background-color: #EBEBEB;
    border: none;
    flex: 1;
}

.image {
    width: 23px;
}

.loader {
    margin: 50px auto;
}

.triangle {
    position: absolute;
    top: -15px;
    right: 178px;
    width: 40px;
}

.right {
    right: 217px;
}

.smallcont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.title{
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #EBEBEB;
}

.message {
    font-family: var(--main-font-family),sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    padding: 0 20px 0 35px;
}
.clear {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    align-self: flex-end;
    color: #AFB9C0;
    padding-right: 5px;
    margin-bottom: -5px;
    cursor: pointer;
}

.clear:hover, .see:hover {
    color: #FFC850;
}


.see {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #BCD3DB;
    align-self: center;
    cursor: pointer;
}

.seecont {
    padding: 15px;
    border-radius: 0 0 10px 10px;
    background: #143E59;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #728796;
}

.paddingBottom {
    padding-bottom: 30px;
}

.success {
    color: #66CF8A;
}

.critical {
    color: #CF6679;
}

.warning {
    color: #FFAE6D;
}

.info {
    color: #9EA1D4;
}

@media only screen and (max-width: 1000px) {
    .triangle, .container {
        left: 50%;
        transform: translateX(-50%);
    }

    .container {
        top: 100px;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        width: 100%;
    }
}