.chartContainer {
    height: 300px;
    border-radius: 32px;
    position: relative;
    z-index: 2;
}

.chartContainer_en {
    width: 47vw;
}

.chartContainer_am {
    width: 45vw;
}

.chartContainer::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-20deg, #F4B41A 0%, rgba(255, 255, 255, 0) 100%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
@media only screen and (max-width:1350px ) {
    .chartContainer{
        width: 100%;
    }
}