.layout {
    padding: 15px 2% 0;
    display: flex;
    gap: 20px;
}

.sidebar_en {
    width: 230px;
}

.sidebar_am {
    width: 250px;
}

.namecont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    z-index: 300;
}

.circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #CF6679;
    position: absolute;
    top: 12px;
    right: 17px;
    cursor: pointer;
}

.circleMessage {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background:#CF6679;
    position: absolute;
    top: 10px;
    right: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
}

.name {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.name_en {
    font-size: 57px;
}

.name_am {
    font-size: 40px;
}

.main {
    margin-bottom: 40px;
    padding-top: 25px;
}

.main_en {
    width: calc(100% - 230px);
}

.main_am {
    width: calc(100% - 250px);
}

.icon {
    width: 57px;
    height: 57px;
    cursor: pointer;
}

.iconcont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    z-index: 2000;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.cont {
    position: relative;
}

.logo {
    width: 45px;
    height: auto;
    margin-left: 10px;
}
@media only screen and (max-width: 1000px){
    .sidebar_en, .sidebar_am {
        width: 100%;
        z-index: 1;
    }

    .layout{
        flex-direction: column;
    }

    .icons{
        display: flex;
        align-items:center;
        justify-content:space-between;

    }
    .main_en, .main_am {
        width: 100%;
        z-index:0
    }
}

@media only screen and (max-width: 1000px) {
    .icons {
        padding: 10px 2%;
        position: absolute;
        background: #0f212a;
        top: 0;
        right: 0;
        left: 0;
    }

    .layout {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 650px){
    .namecont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 20px;
        z-index: 300 !important;
    }
}

@media only screen and  (max-width: 475px){
    .icons {
        background-color: #12222D;
    }
}
