.meeting {
    background-color: #A8D1B6;
}

.homework {
    background-color: #FFD1D1;
}

.interview {
    background-color: #9EA1D4;
}

.lesson {
    background-color: #F1F7B5;
}

.finalInterview {
    background-color: #FF90C2;
}

