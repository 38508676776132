.sliderBlock {
    padding: 0 0 30px;
    margin: 0 auto;
    position: relative;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 5%;
    padding-bottom: 40px;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_ru {
    font-size: 45px;
}

.head_en {
    font-size: 57px;
}

.container {
    position: relative;
    padding: 40px 35px;
    background: #182E3D;
    border-radius: 32px;
    display: flex !important;
    align-items: center;
    gap: 30px;
}

.image {
    width: 250px;
    height: auto;
    border-radius: 12px;
}

.cont {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.arrowcont {
    display: flex;
    align-self: flex-end;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    right: 50px
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #AFB9C0;
}

.arrow {
    width: 45px;
    cursor: pointer;
    opacity: 0.7;
}

.arrow:hover {
    opacity: 0.9;
}

.arrow:active {
    opacity: 1;
}

.yearContainer {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 0 40px;
}

.year {
    font-family: "Teko", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #EBEBEB;
    cursor: pointer;
    position: relative;
}

.selectedYear {
    color: #FFC038;
}

.yellow {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
}

@media only screen and (max-width: 848px) {
    .head {
        font-size: 30px;
        padding-top: 60px;
    }

    .container {
        padding: 40px 35px;
        background: #182E3D;
        border-radius: 32px;
        display: block !important;
        align-items: center;
        gap: 30px;
    }

    .yearContainer {
        width: 100%;
        overflow-y: auto;
        overflow-x: auto;
    }

    .year {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    .container {
        height: 950px;
        padding: 20px;
        width: 100%;
        border-radius: 12px;
    }

    .text {
        padding-top: 15px;
        font-size: 16px;
    }

    .image {
        width: 100%;
    }

    .head {
        padding-bottom: 20px;
    }
}
