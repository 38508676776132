.li {
    display: inline-block;
    margin-right: 9.5%;
    position: relative;
}

.logo {
    width: 43px;
}

.itemsdiv {
    white-space: nowrap;
    width: 55%;
    display: flex;
    align-items: center;
}

.listdiv {
    width: 80%;
    margin-left: 8%;
}

.link, .linkdrop {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-decoration: none;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.line {
    border-left: 2px solid #728796;
    padding-left: 17px;
    margin-left: 10px;
    height: 35px;
}

.link {
    color: #EBEBEB;
}

.active {
    color: #FFB81E;
}

.linkdrop {
    color: #FFCB5C;
}

.linkdrop:active {
    color: #FFB81E;
}

.link:hover {
    color: #FFCB5C;
}

.link:hover .icon {
    background: url("../../../../public/images/loginIconHov.png");
}

.arrowhov {
    transform: rotate(-180deg);
    margin-left: 1px;
    transition: 0.2s ease-in-out;
}

.link:active .icon {
    background: url("../../../../public/images/loginIconActive.png");
}

.arrow {
    margin-left: 1px;
    transition: 0.2s ease-in-out;
}

.link:active {
    color: #FFB81E;
}

.logdiv {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.logeddiv {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.button {
    margin-bottom: 20px;
}

.logIcon {
    max-width: 24px;
    min-height: 24px;
}

.courses {
    display: flex;
    align-items: center;
}

.icon {
    background: url("../../../../public/images/loginIcon.png");
    width: 48px;
    height: 48px;
    scale: 0.4;
    margin-right: 10px;
}

.login {
    white-space: nowrap;
    margin-right: -10px;
    font-weight: 600;
}

.cont {
    margin-top: -29px;
    padding-top: 29px;
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
}

.clickable {
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .link , .linkdrop{
        font-size: 16px;
    }

    .li {
        margin-right: 6%;
    }

    .itemsdiv {
        padding-left: 0;
    }
}
