.image {
    width: 77%;
    z-index: 5;
    padding-top: 160px;
}

.info {
    padding-top: 80px;
    display: flex;
    align-items: center;
    column-gap: 13%;
}

.info li {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    color: rgba(188, 211, 219, 1);
}

.info li span {
    text-transform: uppercase;
    color: white;
    font-size:13px;
}

.iconHolder {
    background-color: #182E3D;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding: 20px;
}

.iconHolder::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 1.25px 1.5px;
    background: linear-gradient(-50deg, #F4B41A 0%, rgba(255, 255, 255, 0) 55%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.description {
    border-radius: 32px;
    background: #162A38;
    position: relative;
    padding: 40px 100px 65px;
    z-index: 2;
    margin-top: 80px;
}

.description::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 30%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.title {
    font-weight: 500;
    font-family: var(--english-font-teko), sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 15px;
    color: #FFC038;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.title_en {
    font-size: 57px;
}

.courseDescription {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    color: white
}

@media only screen and (max-width: 848px) {
    .image {
        position: absolute;
        right: 70px;
        width: 200px;
        transform: translateY(-100px);
    }
    .info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 0;
        width: 370px;
        padding-top:25px
    }
    .imgs{
        width:30px !important;
    }
    .description {
        border-radius: 32px;
        background: #162A38;
        position: relative;
        padding: 40px 10px 65px;
        z-index: 2;
        margin-top: 180px;
    }
    .title{
        font-size:30px
    }
    .title_am{
        font-size:22px

    }
    .courseDescription{
        font-size:16px

    }


}

@media only screen and (max-width: 450px) {
    .image {
        right: 50px;
        width: 190px;
    }
}
@media only screen and (max-width: 400px) {
    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 0;
        width: 350px;
    }

    .description {
        padding: 30px;
        margin-top: 220px;
    }
}