.container {
    flex: 1 66%;
    border-radius: 32px;
    position: relative;
    padding: 20px 0 50px;
    z-index: 2;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.groupDataContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 15px 25px;
    border-bottom: 1px solid rgba(114, 135, 150, 1);
}

.nameCont {
    display: flex;
    align-items: center;
    gap: 30px
}

.membersNumber {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #AFB9C0;
    padding-right: 35px;
}

.image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
}

.name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: rgba(188, 211, 219, 1);
}

.messageCont {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    padding: 20px 0 55px;
    overflow-y: auto;
    max-height: 710px;
}

.messageCont::-webkit-scrollbar {
    width: 5px;
}

.messageCont::-webkit-scrollbar-thumb {
    background-color: #FFC038;
    border-radius: 15px 0 0 15px;
}

.messageCont::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}

.noMessages {
    width: 250px;
}

.noMessageContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:  center;
    align-items: center;
    gap: 20px;
}

.noMessageContainer > p {
    font-family: var(--main-font-family), sans-serif;
    font-size: 26px;
    font-weight: 500;
    color: white;
}

.box {
    width: 50px;
}

.smallCont {
    display: flex;
}

.start{
    justify-content: flex-start;
}

.end {
    justify-content: flex-end;
}

.startPadding {
    padding-right: 25px;
}

.endPadding {
    padding-left: 25px;
}

.message {
    width: 100%;
    display: flex;
    gap: 20px;
}

.messageSelected {
    gap: 10px;
}

.text {
    border-radius: 15px;
    max-width: 350px;
}

.text p {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    text-align: left;
    color: white;
    word-wrap: break-word;
}

.row-reverse {
    flex-direction: row-reverse;
}

.me {
    padding: 15px 15px 10px;
    background: rgba(131, 106, 54, 1);
}

.other {
    padding: 10px 15px;
    background: rgba(20, 62, 89, 1);
}

.notGroupChatOtherText {
    padding: 15px 15px 10px;
}

.areaCont {
    margin: 0 25px;
    position: absolute;
    padding-right: 15px;
    background: #2F414E;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    bottom: 25px;
    left: 0;
    right: 0;
    z-index: 150;
}

.textCont {
    flex-basis: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.textarea {
    background: transparent;
    padding: 15px 25px;
    border: none;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #FFFFFF;
    border-radius: 12px;
    resize: none;
    max-height: 100px;
    overflow-y: auto;
    height: 57px ;
}

.textarea::-webkit-scrollbar {
    width: 5px;
}

.textarea::-webkit-scrollbar-thumb {
    background-color: #728796;
    border-radius: 2px;
}

.textarea::placeholder {
    color: rgba(188, 211, 219, 1);
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}

.icon {
    font-size: 33px;
    cursor: pointer;
    margin-top: 12px;
    color: #9CB1C0;
}

.icon:hover {
    color: #FFC850;
}

.selectIcon {
    width: 30px;
    cursor: pointer;
    margin-top: 12px;
}

.emoji {
    position: absolute;
    bottom: 95px;
    right: 25px;
    z-index: 500;
}

.isRightClicked {
    background: #0C171F;
    z-index: -100;
    border-radius: 5px;
    position: relative;
    transition: .2s ease-in-out;
}

.isSelected {
    background: rgba(255, 192, 56, 0.2);
    border-radius: 5px;
    transition: .2s ease-in-out;
}

.replyCont {
    background: #2F414E;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 5px 10px 25px;
}

.replyName {
    font-family: var(--main-font-family),sans-serif;
    font-size: 15px !important;
    font-weight: 500;
    text-align: left;
    color: #BCD3DB;
}

.replyMessage {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.gray {
    color: #AFB9C0;
}

.white {
    color: #E7E7E7;
}

.cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
}

.check {
    font-size: 30px;
    color: #AFB9C0;
    margin-top: 12px;
    cursor: pointer;
}

.check:hover {
    color: #FFC850;
}

.replyContMessage {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    padding: 10px 13px 10px 35px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.meReplyBackground {
    background: #b69c69;
}

.otherReplyBackground {
    background: #3f65a2;
}

.replyContMessage::before {
    content: '';
    position: absolute;
    width: 0.1em;
    top: 7px;
    bottom: 7px;
    left: 20px;
    background: white;
}

.close {
    font-size: 18px;
    color: #AFB9C0;
    cursor: pointer;
}

.closeCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuIsOpen {
    overflow: hidden;
    padding-right: 5px;
}


.checkbox {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #AFB9C0;
    align-self: flex-end;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.checkbox:not(.checkboxSelected):hover {
    background: rgba(175, 185, 192, 0.3);
}

.checkboxSelected {
    border: 2px solid #FFC038;
    background: #FFC038;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out;
}

.checkmark {
    font-size: 16px;
    color: white;
}

.selectedCont {
    flex: 1;
    display: flex;
    transition: .2s ease-in-out;
}

.editedText {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    /*float: right;*/
    color: #EBEBEB;
    font-style: italic;
}

.link {
    color: #FFC038;
}

.link:hover {
    text-decoration: underline;
}

.userName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px !important;
    color: #EBEBEB;
    margin: 5px 0;
}

.time {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    float: right;
    color: #EBEBEB;
    padding-top: 3px;
}

.timeContainer {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    justify-content: flex-end;
}

.dateSeparator {
    font-family: var(--main-font-family), sans-serif;
    text-align: center;
    color: #EBEBEB;
    font-size: 16px;
    margin: 10px 0;
}

.typing {
    font-family: var(--main-font-family), sans-serif;
    text-align: left;
    color: white;
    font-size: 16px;
    position: absolute;
    bottom: 75px;
    left: 0;
    background: #2F414E;
    border-radius: 6px;
    padding: 6px 20px;
    font-style: italic;
}

.typing::before {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #2F414E transparent;
    transform: rotate(90deg);
}

.sentImage {
    position: relative;
    box-shadow: 0 0 5px 1px #0D1C26B8;
    cursor: pointer;
    border-radius: 15px;
}

.sentImage > img {
    width: 350px;
    height: auto;
    border-radius: 15px;
}

.sentImageOpened {
    max-width: 50%;
    height: auto;
    max-height: 90vh;
    box-shadow: 0 0 15px 8px #0D1C26B8;
}

.imageContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 34, 45, 0.9);
    z-index: 1000;
}

.closeIcon {
    cursor: pointer;
    font-size: 28px;
    position: absolute;
    top: 25px;
    right: 20px;
    color: #AFB9C0;
}

.closeIcon:hover, .close:hover {
    color: #F4B41A;
}

.imageTime {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.file {
    border-radius: 15px;
    max-width: 350px;
    cursor: pointer;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.file p {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.downloadButton {
    color: white;
    font-size: 33px;
}

 .file:hover .downloadButton {
    color: #FFC850;
}

 .checks {
     color: #EBEBEB;
 }

 .replyingImage {
     width: 80px;
     height: auto;
 }

 @media screen and (max-width: 900px) {
     .sentImage > img {
         width: 200px;
     }

     .text {
         max-width: 200px;
     }

     .text p {
         font-size: 16px;
     }
 }

@media screen and (max-width: 840px) {
    .icon {
        font-size: 26px;
    }
}

