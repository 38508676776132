.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.title_en {
    font-size: 35px;
}

.title_am {
    font-size: 28px;
}

.groups {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.group {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    position: relative;
    border-radius: 32px;
    padding: 30px 20px;
    z-index: 2;
    cursor: pointer;
}

.isDisabledGroup {
    width: 31%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    position: relative;
    border-radius: 32px;
    padding: 30px 20px;
    z-index: 2;
}

.group:hover {
    transform: scale(1.02);
}

.group:before, .isDisabledGroup:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 110%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.image {
    width: 40%;
    height: auto;
    align-self: center;
}

.number {
    background: #AFB9C033;
    position: relative;
    border-radius: 10px;
    z-index: 2;
    padding: 5px 12px;
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
}


.number:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 60%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.imageCont {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;
}

.students {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #BCD3DB;
}

.avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 1000px) {
    .avatar {
        flex-direction: column;
    }
}