.container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.header {
    display: flex;
    gap: 12%;
    padding: 15px 40px;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    margin: 0 0 30px;
}

.header::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.messages {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.messages_en {
    font-size: 35px;
}

.messages_am {
    font-size: 28px;
}