.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 34, 45, 0.5);
    z-index: 100;
}

.modal {
    max-width: 550px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    position: relative;
    background: #143E59;
    border-radius: 32px;
    z-index: 3;
    padding: 40px 50px;
}

.modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    color: #FFC038;
}

.type {
    padding: 8px 30px;
    border-radius: 28px;
    text-transform: capitalize;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #143E59;
}

.meeting {
    background-color: #A8D1B6;
}

.homework {
    background-color: #FFD1D1;
}

.lesson {
    background-color: #F1F7B5;
}

.interview {
    background-color: #9EA1D4;
}

.finalInterview {
    background-color: #FF90C2;
}

.data {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.label {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    color: #AFB9C0;
    width: 160px;
}

.eventData {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #AFB9C0;
    text-align: left;
    width: 60%;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.closeIcon {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 25px;
    right: 20px;
    color: #AFB9C0;
}

.closeIcon:hover, .data > a:hover {
    color: #FFC850;
}

@media only screen and (max-width: 500px) {
    .modal {
        width: 95%;
        padding: 30px 25px;
        gap: 20px;
    }

    .title {
        font-size: 24px;
        text-align: center;
    }

    .label {
        font-size: 18px;
    }

    .eventData {
        font-size: 16px;
        text-align: center;
    }

    .type {
        padding: 5px 20px;
        font-size: 18px;
    }
}
