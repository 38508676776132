.container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #162A38;
    padding:  40px 0;
    border-radius: 16px;
    position: relative;
    z-index: 2;
}

.bigcont{
    padding-top: 150px;
}

.container:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.5px 0.75px 0;
    background:linear-gradient(135.87deg, #F4B41A 0%, rgba(255, 255, 255, 0)
                53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.cont {
    height: max-content;
    width: 200px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.icon {
    width: 40px;
    height: 40px;
    opacity: .95;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 21px;
    font-weight: 500;
    text-align: left;
    color: #FFC038;
    margin-top: -8px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    width: 240px;
    font-size: 17px;
    line-height: 28px;
    text-align: left;
    border-top: 1px solid #627785;
    padding-top: 15px;
    color: #AFB9C0;
}

.textContainer {
    height: 30px;
    display: flex;
    align-items: center;
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4%;
}

@media only screen and (max-width: 1200px) {
    .container {
        padding: 40px 70px;
        justify-content: space-around;
        gap: 60px ;
    }
    .cont{
        flex-basis: 33%;
    }
}

@media only screen and (max-width: 750px){
    .container{
        background: transparent;
        border-radius: 0;
        gap: 20px 28px;
        justify-content: center;
        margin-top: 50px;
        padding: 0;
    }

    .container::before {
        display: none;
    }

    .cont {
        position: relative;
        background: #162A38;
        border-radius: 16px;
        padding: 23px 10px;
        gap: 20px;
        height:300px
    }
    .cont::before{
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 16px;
        padding: 1.5px 0.75px 0;
        background:linear-gradient(135.87deg, #F4B41A 0%, rgba(255, 255, 255, 0) 53.33%, rgba(244, 180, 26, 0) 100%) ;
        mask:
                linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%) content-box,
                linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
        mask-composite: exclude;
    }

    .icon {
        width: 35px;
        height: 35px;
    }

    .link {
        font-size: 16px;
    }

    .text {
        font-size: 14px;
        width: 150px;
        line-height: 1.2;
    }
}

@media only screen and (max-width: 450px){
    .bigcont{
        padding-top: 1px;
    }
    .icon {
        width: 25px;
        height: 25px;
    }

    .cont {
        flex-basis: 43%;
        height:210px;
    }

    .link {
        font-size: 13px;
    }

    .text {
        padding-top: 11px;
        font-size: 10px;
        width: 120px;
    }

    .button {
        padding: 8%;
    }

}
