.cont {
    background-color: #162A38;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 0 30px 35px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.course {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}
.num{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 80px;
    font-weight: 500;
    /*letter-spacing: 0.02em;*/
    text-align: left;
    color: #FFC038;
}
.title{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 60px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    margin-top: -20px;
}

.text{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
}
.left{
    width: 63%;
}
.right{
    width: 37%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img{
    max-width: 340px;
}
.linerProg{
    margin-top: 40px;
    gap: 20px;
    display: flex;
    align-items: center;
}
.pointcol{
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #BCD3DB;
}
.textSide{
    display: flex;
    width: 100%;
}
.userSide{
    width: 75%;
}

.prev{
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;

}
.prev::after{
    content: url("/public/images/VectorNext.png");
    position: absolute;
    width: 10px;
    height: 10px;
    left: -25px;
    bottom: 3px;
    transform: rotate(180deg);
    cursor: pointer;

}

.next{
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;

}

.next::after{
    content: url("/public/images/VectorNext.png");
    position: absolute;
    width: 10px;
    height: 10px;
    right: -25px;
    bottom: 20px;
    cursor: pointer;
}
.container{
    max-width: 440px;
    position: relative;
    /*display: flex;*/
    align-items: center;
    padding: 20px 25px 20px 25px;
    margin-top: 20px;
    cursor: pointer;
}


.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.containervid{
    /*display: flex;*/
    align-items: center;
    width: 98%;
    position: relative;
    /*display: flex;*/
    align-items: center;
    padding: 20px 25px 20px 25px;
    margin-top: 20px;
    cursor: pointer;
}
.tops{
    display: flex;
    align-items: center;
}
.containervid::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.vid{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-left: 20px;
    margin-top: 25px;
    padding: 5px 10px;
}

.up{
    display: flex;
    align-items: center;
    gap: 25px;
}
.title{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 500;
    line-height: 28.8px;
}
.text{
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    color: #AFB9C0;
    font-weight: 500;
    line-height: 33px;
}
.links{
    text-decoration: none;
    cursor: pointer;
}
.text1{
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    margin-top: 10px;
}
.videocomponent{
    width: 98%;
    margin-top: 20px;
}
.btndivs{
 display: flex;
    justify-content: center;
    margin-top: 25px;
}
.btndivs > a{
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.22px;
    display: inline-flex;
    padding: 18px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid  #FFC038;
    background: #FFC038;
    cursor: pointer;
}
.btndivs > a:hover{
    border-radius: 12px;
    border: 1px solid #FFC850;
    background:  #FFCB5C;
}
.btndivs > a:active{
    border-radius: 12px;
    border: 1px solid #FFB30E;
    background: #FFB81E;
}