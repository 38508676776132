.contextMenu {
    width: 170px;
    background: #12222D;
    box-shadow: 2px 2px 15px 0 #0D1C26;
    border-radius: 15px;
    position: absolute;
    z-index: 100;
}


.menu {
    display: flex;
    flex-direction: column;
    padding: 15px 0 15px 20px;
    gap: 23px;
}

.option {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
}

.option:hover {
    color: #FFC038;
}

.red {
    color: #CF6679;
}

.readUsers {
    width: max-content !important;
    top: 65px;
    min-height: 120px;
    max-height: 180px;
    overflow-y: auto;
    padding: 10px;
}

.readUsers::-webkit-scrollbar {
    width: 8px;
}

.readUsers::-webkit-scrollbar-track {
    background: #152836 ;
}

.readUsers::-webkit-scrollbar-thumb {
    background-color: #2F495C;
    border-radius: 5px;
}

.readUsers::-webkit-scrollbar-thumb:hover {
    background-color: #243745 ;
}

.user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
}

.user > p {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
    margin-top: 35px;
}

.image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
}

