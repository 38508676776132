.heading {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-bottom: 30px;
}

.heading_en {
    font-size: 35px;
}

.heading_am {
    font-size: 30px;
}

.sections {
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    padding-bottom: 40px;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding: 0 3px 5px;
}

.link:hover {
    color: #FFC038;
}

.active {
    border-bottom: 2px solid #FFC038;
}

.container {
    padding: 40px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 120%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}