.smalldiv {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding-top: 12px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: #EBEBEB;
    white-space: nowrap;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    text-align: left;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    color: #C4CDD3;
    padding-top: 3px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.link:hover {
    color: #FFCB5C;
}

.notHover {
    color: #C4CDD3 !important;
}

.link:active {
    color: #FFB81E;
}

.light {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #728796;
    line-height: 1.4;
}

.tel {
    display: flex;
    align-items: center;
    font-family: "Fira Sans", sans-serif !important;
}

.firaSans {
    font-family: "Fira Sans", sans-serif !important;
}

.img {
    width: 145px;
    height: 42px;
    margin-top: 10%;
}

.appdiv {
    display: flex;
    justify-content: end;
    gap: 3%;
}

.image {
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 1150px) {
    .smalldiv {
        flex-basis: unset;
    }

    .contact {
        order: 1;
    }

    .appdiv {
        order: 2;
    }
}

@media only screen and (max-width: 997px) and (min-width: 810px) {
    .appdiv {
        width: 70%;
    }
}

@media only screen and (max-width: 810px) and (min-width: 690px) {
    .contact {
        padding-bottom: 20px;
    }
    .appdiv {
        padding-top: 40px;
    }
}

@media only screen and (max-width: 690px) {
    .smalldiv {
        width: 30%;
    }
}