.courscont{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 40px 0 40px 40px;
    margin-top: 20px;
    cursor: pointer;
}
.courscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.course {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}
.left{
    width: 78%;
    display: flex;
    flex-direction: column;
}
.timediv{
    margin-top:15px;
    display: flex;
    align-items: center;
    margin-left: 40px;
    justify-content: space-between;
}

.right{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}
.img{
    width: 70%;
}
.lintime{
    display: flex;
    gap: 80px;
}
.title{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 64px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
}
.text{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1.3;
    text-align: left;
    color: #728796;
}
.time{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    position: relative;
}
.time::after{
    content: url("/public/images/timecourse.svg");
    position: absolute;
    left: -40px;
    top: -4px;

}

.data{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    position: relative;
}
.data::after{
    content: url("/public/images/datacourse.svg");
    position: absolute;
    left: -40px;
    top: -4px;
}
.succes{
    font-family: var(--main-font-family), sans-serif;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #BCD3DB;
}

@media only screen and (max-width: 1700px) {
    .courscont {
        padding: 30px 0 30px 30px;
    }

    .course {
        font-size: 35px;
    }

    .title {
        font-size: 45px;
    }

    .text  {
        font-size: 18px;
    }

    .succes {
        font-size: 26px;
    }

    .img {
        width: 75%;
    }

    .left {
        gap: 15px;
    }

    .time, .data {
        font-size: 20px;
    }
}
@media only screen and (max-width: 750px) {
    .text {
        font-family: var(--main-font-family), sans-serif;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 1.3;
        text-align: left;
        color: #728796;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 20px);

    }
    .succes{
        font-size:18px;
    }
    .data{
        font-size:18px;
    }
    .title{
        font-size:32px;
    }
    .img{
        width:100%;
        margin-left:0px ;
    }
    .left{
        width:70%;

    }
}