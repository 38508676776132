.container {
    width: 100%;
    padding: 6% 6% 8%;
    background: #162A38;
    position: relative;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 150px;
    gap: 80px;
    z-index: 2;
}

.container:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.25px  1.5px 0;
    background: linear-gradient(173deg, #F4B41A 0%, rgba(255, 255, 255, 0) 53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.head {
    font-family: var(--main-font-family), sans-serif;
    font-size: 45px;
    font-weight: 500;
    max-width: 750px;
    text-align: left;
    color: #FFC038;
}

.image {
    position: absolute;
    width: 21%;
    height: auto;
    right: 50px;
    bottom: -40px;
}

.img {
    position: absolute;
    right: 50px;
    bottom: -60px;
    width: 25%;
    height: auto;
}

.yellow {
    color: #FFC038;
}

.text {
    font-family: var(--main-font-family),sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #EBEBEB;
    width: 60%;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    color: #FFC038;
}

@media only screen and (max-width: 700px) {

    .head_am {
        font-size: 22px !important;
    }
    .head {
        font-size: 30px;
        max-width: 300px;
    }
    .text, .link {
        font-size: 15px;
    }

    .image {
        width: 35%;
        bottom: 120px;
    }

    .img {
        width: 32%;
        bottom: unset;
    }

    .container {
        align-items: unset;
        gap: 270px;
    }
    .container {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .img {
        position: unset;
        align-self: flex-end;
        margin-right: 30px;
    }

    .text {
        width: 100%;
    }
}


@media only screen and (max-width: 430px) {
    .image {
        width: 45%;
    }

    .text {
        padding-top: 10px;
    }

    .img {
        width: 48%;
    }
}

