.parent {
    background: #121623;
    border-radius: 5px;
    /*z-index: 120 !important;*/
    max-width: 720px;
    /*height: 80vh;*/
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pos{
    position:relative;
    width: 70%;
}

.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 100;
    background: rgba(67, 73, 101, 0.7);

}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    gap:40px;
}
.p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;

}
.titles{
    margin-top: 40px;
    color:  #FFC038;
    font-family: var(--main-font-family);
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.btns{
    padding: 10px 60px;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.btns:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.btns:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}


.btndiv{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid  rgb(113, 134, 148, 0.5);
    margin-top: 32px;
    padding: 32px 0;

}
.cont{
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    width: 70%;
    margin-top: 40px;
}
.devinp{
    width: 100%;
    display: flex;
    align-items: center;

}
.text{
    width: 30%;
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.inp{
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 12px;
    background: #243745;
    color:  #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.label {
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}

@media only screen and (max-width: 640px){
    .parent{
        width: 90%;
    }
    .p{
        text-align: center;
    }
}