.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 25px;
    font-family: var(--main-font-family), sans-serif;
}

.table tr td{
    width: 16%;
    padding: 20px 0;
    font-size: 20px;
    font-weight: 500;
    color: white;
    background: rgba(22, 42, 56, 1);
}

.selectable {
    cursor: pointer;
}

.selectable:hover  td{
    background: rgba(22, 42, 56, 0.6);
}

.table tr td:first-child{
    width: 45%;
    padding-left: 50px;
}

.table tr td:last-child{
    width: 15%;
    color: rgba(114, 135, 150, 1);
    text-align: right;
    padding-right: 50px;
}

.table td:first-child {
    border-top-left-radius: 30px; 
    border-bottom-left-radius: 30px;
}
.table td:last-child {
    border-bottom-right-radius: 30px; 
    border-top-right-radius: 30px; 
}

.notHomework {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-top: 50px;
}

.switch {
    display: flex;
    align-items: center;
    justify-content: center;
    scale: 1.3;
}

.imgcl,
.imgop {
    height: 25px;
    width: 25px;
}

.lock {
    padding: 0 !important;
}

.group {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.tabsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tabs {
    display: flex;
    gap: 10px
}

.tab {
    width: 200px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    cursor: pointer;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
}

.tab.selected {
    border: 2px solid #FFC038;
    color: #FFC038;
}

.courseGroups {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    gap: 25px 20px;
    padding-top: 40px;
    width: 100%
}

.course {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    gap: 75px;
    border-radius: 32px;
    background-color: #162A38;
    position: relative;
    z-index: 2;
}

.course:hover {
    background: #1B3445;
    cursor: pointer;
}

.course::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-15deg, #F4B41A 0%, rgba(255, 255, 255, 0) 70%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.groupName {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
}

.dataCont {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    align-items: center;
}

.cont {
    display: flex;
    align-items: center;
    gap: 5px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
}

.image {
    width: 25px;
}

.notGroup {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    color: white;
    padding-top: 80px;
}

.icons {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 20px;
}