.courseCertificates{
    margin-bottom: 80px;
}

.title {
    font-weight: 500;
    font-family: var(--english-font-teko), sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 25px;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.inner{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.certificate{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--main-font-family), sans-serif;
}

.img {
    width: 370px;
    height: auto;
    margin-bottom: 30px;
}

.certificate p{
    margin-bottom: 15px;
    color: white;
    font-weight: 500;
    font-size: 22px;
}

.certificate span{
    color: rgba(188, 211, 219, 1);
    font-size: 16px;
}
@media only screen and (max-width: 848px) {

    .title{
        font-size:30px

    }
    .title_am{
        font-size:22px

    }
    .img{
        width: 340px;

    }
    .certificate p{
        margin-bottom: 15px;
        color: white;
        font-weight: 500;
        font-size: 22px;
        text-align:center;
    }

    .certificate span{
        color: rgba(188, 211, 219, 1);
        font-size: 16px;
        text-align:center;

    }
}