.label {
    display: flex;
    align-items: center;
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    padding: 0 7px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    color: #EBEBEB;
    margin-left: 10px;
}

.textnot {
    color: #728796;
}

.radio {
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 2px solid #9a9a9a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio:hover {
    opacity: 0.95;
    box-shadow: 0 0 0 13px rgba(255, 255, 255, 0.1);
    clip-path: circle(90%);
}

.radio:checked:hover {
    opacity: 0.95;
    box-shadow: 0 0 0 13px rgba(255, 192, 56, 0.1);
    clip-path: circle(90%);
}

.radio:disabled {
    opacity: 0.5;
}

.radio::after {
    content: '';
    width: 9px;
    height: 9px;
    background: #f4b400;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s;
}

.radioinput {
    margin: 0;
    visibility: hidden;
}

.radioinput:checked + span {
    border: 2px solid #f4b400;
}

.radioinput:checked + span:after {
    opacity: 1;
}

@media only screen and (max-width: 470px) {
    .text {
        font-size: 16px;
    }
}