.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 34, 45, 0.5);
    z-index: 100;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 32px;
    padding: 30px 0;
    position: relative;
    background: #12222D;
    box-shadow: 0 0 30px 0 #FFC0381A;
    z-index: 3;
}

.form::-webkit-scrollbar {
    width: 5px;
}

.form::-webkit-scrollbar-thumb {
    background-color: #FFC038;
    border-radius: 15px ;
}

.form::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-bottom: 1px solid #728796;
    padding: 0 80px 40px;
    margin-bottom: 20px;
    overflow-y: auto;
    max-height: 80vh;
    /* to do */
    /*background: linear-gradient(rgba(18, 34, 45, 1), rgba(18, 34, 45, 0));*/
}

.modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    color: #FFC038;
    align-self: center;
    padding-bottom: 20px;
}

.cont {
    display: flex;
    align-items: center;
    gap: 50px;
}

.allDay {
    display: flex;
    gap: 5px;
    align-items: center;
    margin: -10px 0 0 130px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: white;
    border-top: 1px solid #728796;
    padding: 15px 0 5px;
}

.label {
    width: 80px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #AFB9C0;
}

.input {
    width: 400px;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.04em;
    font-size: 18px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input:focus {
    border: 1.2px solid #FFC038;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.04em;
}

.selectable {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tagOption {
    width: 170px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    cursor: pointer;
    color: white;
}

.tagOption:hover {
    background: rgba(255, 255, 255, 0.05);
}

.meeting {
    border: 2px solid #A8D1B6;
}

.homework {
    border: 2px solid #FFD1D1;
}

.lesson {
    border: 2px solid #F1F7B5;
}

.interview {
    border: 2px solid #9EA1D4;
}

.finalInterview {
    border: 2px solid #FF90C2;
}


.format {
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
}

.format.selected {
    border: 2px solid #FFC038;
    color: #FFC038;
}

.meeting.selected {
    background: #A8D1B6;
    color: black;
}

.lesson.selected {
    background: #F1F7B5;
    color: black;
}

.interview.selected {
    background: #9EA1D4;
    color: black;
}

.finalInterview.selected {
    background: #FF90C2;
    color: black
}

.homework.selected {
    background: #FFD1D1;
    color: black;
}

.start {
    align-items: flex-start;
}

.start > .label {
    padding-top: 5px;
}

.time {
    outline: none;
    width: 195px;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.04em;
    font-size: 18px;
    font-weight: 300;
    color: #EBEBEB;
}


.date {
    width: 192px;
}

.errorimg {
    width: 32px;
    height: auto;
}

.err {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #CF6679;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.selectCont {
    position: relative;
}

.option {
    display: flex;
    align-items: center;
}

.interviewSelectOption {
    cursor: pointer;
}

.interviewSelectOption:hover > p {
    color: #FFC850;
}

.option > p, .interviewSelectOption > p {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    color: #EBEBEB
}

.select {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 13px 17px 20px;
    background-color: #263D4C;
    border-radius: 12px;
    position: absolute;
    top: 47px;
    right: 0;
    box-shadow: 0 0 14px 0 #0D1C2666;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 50;
}

.select::-webkit-scrollbar{
    width: 7px;
}

.select::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #355972;
}
.selectInput {
    cursor: pointer;
    padding-right: 35px;
}

.arrow {
    color: #728796;
    font-size: 23px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowHov {
    rotate: -180deg;
}

.students {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #BCD3DB;
    padding-top: 5px;
}

.studentsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 50px;
    overflow-y: auto;
}

.studentsContainer::-webkit-scrollbar{
    width: 7px;
}

.studentsContainer::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #355972;
}

.borderBottom {
    padding-bottom: 15px;
    border-bottom: 1px solid #9CB1C0;
}

.group {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}
