.title {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    padding-bottom: 20px;
}

.courseNamesContainer {
    display: flex;
    gap: 15px;
}

.courseName {
    padding: 8px 30px;
    font-size: 22px;
    border-radius: 30px;
    background: linear-gradient(95.18deg, #FFC038 5.37%, #FFDE97 100%);
    color: #12222D;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.paymentDetails {
    margin: 40px 0;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 2;
}

.paymentDetails::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 30px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 110%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.boldTitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    font-weight: 500;
}

.monthlyPayment {
    background: #162A38;
    border-radius: 15px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar {
    width: 45px;
    height: auto;
}

.calendarContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.calendarContainer > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.next {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    font-weight: 500;
}

.grey {
    color: #EBEBEB;
}

.red {
    color: #CF6679;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.row > p, .row > div {
    flex-basis: 20%;
}

.row > p:last-child {
    text-align: center;
}

.columnTitle {
    font-family: var(--main-font-family), sans-serif;
    font-size: 26px;
    letter-spacing: 0.01em;
    font-weight: 500;
    color: white;
}

.rowsContainer {
    display: flex;
    flex-direction: column;
}

.status {
    padding: 8px 20px;
    font-size: 20px;
    border-radius: 30px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    text-align: center;
}

.paid {
    color: #174E2A;
    background: #66CF8A;
}

.failed {
    color: #CF6679;
    background: #FFD1D1;
}

.noPayment {
    font-family: var(--main-font-family), sans-serif;
    font-size: 32px;
    letter-spacing: 0.01em;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-top: 50px;
}

.method {
    width: 190px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 12px;
    background-color: #243745;
    padding: 20px;
    border: 1px solid transparent;
    cursor: pointer;
}

.method:hover {
    border: 1px solid #FFC038;
}

.methodImg {
    width: 25px;
    height: 25px;
}

.methodName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    color: #EBEBEB;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin: 40px 0;
}

.containerButton {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 34, 45, 0.5);
    z-index: 100000;
}

.modal {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    position: relative;
    background: rgba(18, 34, 45, 1);
    border-radius: 32px;
    z-index: 3;
    padding: 30px 40px;
}

.boxShadow {
    box-shadow: 0 0 30px 0 #FFC0384D;
}

.modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.cardBlocks {
    display: flex;
    gap: 30px;
}

.selected {
    border: 1px solid #FFC038;
}