.smalldiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding-top: 12px;
    position: relative;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #EBEBEB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    padding-bottom: 10px;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 31px;
    text-decoration: none;
    color: #C4CDD3;
    white-space: nowrap;
    padding-top: 2px;
}
.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.light {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #728796;
    line-height: 1.4;
}

.tel {
    display: flex;
    align-items: center;
}

.img {
    width: 130px;
    height: 39px;
    margin-top: 10%;
}

.appdiv {
    display: flex;
    justify-content: end;
    gap: 3%;
    padding-bottom: 20px;
}

.image {
    width: 20px;
    height: 20px;
}

.icon , .iconopen{
    width: 11px;
    height: 11px;
    margin-right: 4%;
    color:#EBEBEB;
    scale: 2;
    cursor: pointer;
    transition: 0.2s;
}

.iconopen {
    rotate: -180deg;
}

@media only screen and (max-width: 997px) and (min-width: 810px) {
    .appdiv {
        width: 70%;
    }
}

@media only screen and (max-width: 810px) and (min-width: 690px) {
    .contact {
        padding-bottom: 20px;
    }
    .appdiv {
        padding-top: 40px;
    }
}

@media only screen and (max-width: 690px) and (min-width: 548px) {
    .smalldiv {
        width: 30%;
    }
}

@media only screen and (max-width: 548px) {
    .appdiv {
        width: 100%;
        justify-content: center;
        padding-top: 5px;
        gap: 5%;
    }
    .text {
        font-family: var(--main-font-family), sans-serif;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        color: #EBEBEB;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        padding-bottom: 10px;
    }
}