.textspan{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    padding: 0 0 20px;
}
.titleDiv{
    width: 95%;
    color:#FFFFFF;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    background: #162A38;
    padding: 25px 30px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 60px;
    column-gap: 14px;
}
.indexbut{
    font-family: var(--english-font-teko), serif;
    font-size: 32px;
    font-weight: 500;
}
.currentIndex{
    color: #FFC038;
    font-family: var(--english-font-teko), serif;
    font-size: 64px;
    margin-top: 10px;
}

.only{
    width: 100%;
    display: flex;
}
.leftdiv{
    margin-left: 20px;
    width: 33%;
    margin-top: 70px;
}
.titlesDiv{
    padding-top: 18px;
    padding-bottom: 40px;
    margin-top:55px ;
    border: 1px solid #213E53;
    border-radius: 32px 0 0 32px;
    overflow-y: scroll;
    height: 500px;
}

.titlesDiv::-webkit-scrollbar {
    width: 5px;
}

.titlesDiv::-webkit-scrollbar-thumb {
    background-color: #FFC038;
    border-radius: 15px 0 0 15px;
}

.titlesDiv::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}
.titles1Div{
    margin-top: 22px;
}
.courscont{
    width: 65%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.courscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-15deg, #F4B41A 0%, rgba(255, 255, 255, 0) 95%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.ansver{
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.btn{
    text-align: left;
    background: #162A38;
    padding: 15px 31px;
    border: none;
    border-radius: 42px ;
    color: #EBEBEB;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.btn:hover {
    background: #1A3242;
}

.btn:active {
    background: #152836;
}

.textin{
    font-family: var(--main-font-family), sans-serif;
    margin-top: -2px;
    font-size: 22px;
}
.divquest{
    margin-top: 90px;
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
}
.que{
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    color: #12222D;
    background: #FFC038;
    padding: 10px 35px;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #FFC038

}
.que:hover{
    border: 1px solid #FFC850;
    background:  #FFCB5C
}
.que:active{
    border: 1px solid #FFB30E;
    background: #FFB81E
}
.points{
    position: absolute;
    bottom: 47px;
    left: 83px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #728796;
    font-weight: 500;
    line-height: 28px;
}

.score{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 183px 50px;
    gap: 40px;
}
.score::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.scoretext{
    font-family: var(--english-font-teko), serif;
    font-size:  64px;
    color: #BCD3DB;
    text-align: center;
}
.great{
    font-size: 24px;
    color: #EBEBEB;
    font-family: var(--main-font-family), sans-serif;
}
.timeredit{
    font-size: 28px;
    color: #BCD3DB;
    font-family: var(--main-font-family), sans-serif;
}
.numindex{
    font-size: 35px;
    font-family: var(--english-font-teko), serif;
    font-weight: 500;
}

.questionTitle {
    display: flex;
    align-items: center;
    padding: 0 35px;
    cursor: pointer;
}

.secret {
    margin-left: auto;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 21px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    text-align: left;
    padding: 0 20px;
}

.grey {
    color: #728796;
}

.yellow {
    color: #FFCB5C;
}

.green {
    color: #81B214;
}

.white {
    color: #BCD3DB;
}

.selected {
    position: relative;
    z-index: 2;
}

.selected::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 42px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-8deg, #F4B41A 0%, rgba(255, 255, 255, 0) 60%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.buttonContainer {
    display: flex;
    gap: 80px
}

@media only screen and (max-width: 1700px) {
    .textspan {
        font-size: 20px;
    }

    .textin, .points {
        font-size: 20px;
    }

    .currentIndex, .scoretext {
        font-size: 45px;
    }

    .titleDiv {
        padding: 0 30px;
        margin: 20px 0 30px;
    }

    .score {
        padding: 140px 50px;
    }

    .divquest {
        margin-top: 30px;
        padding-bottom: 10px;
    }

    .btn {
        padding: 8px 30px;
    }

    .leftdiv,.titlesDiv {
        margin-top: 30px;
    }

    .titlesDiv {
        height: 380px;
    }
}