.img {
    width: 60%;
}

.imgg {
    width: 40%;
    height: auto;
    transform: translateX(50px);
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    color: #EBEBEB;
    text-align: center;
}

.container {
    width: 100%;
    padding: 70px 60px 90px;
    margin-bottom: 100px;
    background: #243745;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;

}

.cont {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.image {
    width: 130px;
    align-self: center;
}

.heading {
    font-family: var(--main-font-family), sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.label {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.caption {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: #AFB9C0;

}

.input {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: #12222D;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.04em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input:focus {
    border: 1.2px solid #FFC038;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.04em;
}

.error {
    font-family: var(--main-font-family), sans-serif;
    font-size: 13px;
    padding-top: 5px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #CF6679;
}

.errorinp {
    border: 1.2px solid #CF6679 !important;
}

.noterrinp  {
    border: 1.2px solid #66CF8A !important;
}

.inputcont {
    width: 100%;
    height: 120px;
}

.back {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #AFB9C0 ;
    padding-top: 25px;
    margin: -30px 0 -60px;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #FFC038;
}

.spam {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #AFB9C0;
}

.timer {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #FFC038;
    margin-top: 15px;
}

.con {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    margin-bottom: -40px;
}


@media only screen and (max-width: 800px) {
    .cont, .inputcont {
        width: 70% !important;
    }

    .container {
        margin-top: 70px;
        margin-bottom: 50px;
        padding:50px 20px 80px;
    }
}

@media only screen and (max-width: 470px) {
    .cont, .inputcont {
        width: 100% !important;
    }

    .back, .link {
        font-size: 16px;
    }

    .imgg {
        width: 150px;
    }
}