.container {
    flex-basis: 40%;
    border-radius: 32px;
    padding: 110px 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-top: 90px;
    gap: 10px;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 101%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.user {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    position: absolute;
    /*padding: 10px;*/
    /*border: 2px solid #FFC038;*/
    z-index: 2;
    top: -60px;
    background: #0f212a;
    background-position: center !important;
    background-size: cover !important;
}

.user::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.name {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
}

.name_en {
    font-size: 35px;
}

.name_am {
    font-size: 26px;
}

.course{
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #AFB9C0;
    margin-top: -5px;
}

.icon {
    width: 25px;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
}

.title_en {
    font-size: 18px;
}

.title_am {
    font-size: 16px;
}

.amount {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
}

.amount_en {
    font-size: 23px;
}

.amount_am {
    font-size: 18px;
}

.amountcont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px
}

.anIcon {
    width: 21px;
}


.datacont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    gap: 65px
}

.grey {
    background: #162A38;
}
@media only screen and (max-width: 650px){
    .datacont{
        gap:50px;
    }
}