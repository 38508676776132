
.cont{
    display: flex;
    justify-content: space-between;
}

.title{
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}
    .link{
display: flex;
        padding-inline:10px ;
        border: none;
        align-items: center;
        border-radius: 15px;
        background:  #FFC038;
        color: #12222D;
        font-family: var(--main-font-family);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
    }

    .link:hover{
        /*border: 1px solid #FFC850;*/
        background:  #FFCB5C
    }
    .link:active{
        /*border: 1px solid #FFB30E;*/
        background: #FFB81E
    }
