.container{
    max-width: 440px;
    position: relative;
    align-items: center;
    padding: 20px 35px 20px 25px;
    margin-top: 20px;
    cursor: pointer;
}


.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.up{
    display: flex;
    align-items: center;
    gap: 25px;
}
.link{
    opacity: 0.7;
}
.title{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 500;
    line-height: 28.8px;
}
.imgs{
    width:60px;
}
.linerProg{
    margin-top: 40px;
    gap: 20px;
    display: flex;
    align-items: center;
}
.pointcol{
    width: 135px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #BCD3DB;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #AFB9C0;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 0 0 5px;
}

.pointer > p,
.pointer > span,
.pointer > ol,
.pointer > ul,
.pointer > img,
.pointer > code,
.pointer > a,
.pointer > pre {
    cursor: pointer;
}

.links{
    text-decoration: none;
    cursor: pointer;

}
.text1{
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    margin-top: 10px;
}


@media only screen and  (max-width: 1700px) {
    .title {
        font-size: 20px;
    }

    .text {
        font-size: 18px;
    }

    .text1 {
        font-size: 22px;
    }
}

