.parent {
    background: #121623;
    z-index: 20;
    max-width: 820px;
    margin: 0 auto;
    margin-top: 200px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:1px solid rgba(252, 195, 72, 0.54);

}

.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 100;
    background: rgba(67, 73, 101, 0.7);

}

.btndiv{
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    width: 70%;
}
.titles{
    margin-top: 30px;
    color:  #FFC038;
    font-family: var(--main-font-family);
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.bt{
    display: inline-flex;
    padding: 10px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    border: 1px solid #AFB9C0;
    color:  #EBEBEB;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-weight: 500;
    background: none;
    cursor: pointer;
}

.btns{
    padding: 10px 60px;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.btns:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.btns:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}


