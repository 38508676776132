.stepper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.step-indicator {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.step {
    background:#354956;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #C2C7CB;
    transition: 0.5s;
}

.step.active {
    background-color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #12222D;
    transition: 0.5s;
}

.checkmark {
    width: 16px;
    height: 13px;
}

.lines {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

.line {
    flex: 1;
    height: 6px;
    background-color: #354956;
    transition: background-color 0.5s;
}

.line:first-child {
    border-radius: 5px 0 0 5px;
}

.line:last-child {
    border-radius: 0 5px 5px 0;
}

.line.active {
    background-color: #FFC038;
}


.cont {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: #C2C7CB;
    transition: 0.5s;
}

.title.act {
    color: #FFC038;
    transition: 0.5s;
}

.comp-cont {
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .step {
        width: 30px;
        height: 30px;
    }

    .title {
        font-size: 12px;
    }
}

