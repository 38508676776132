.container {
    width: 100%;
}
.contless{
    display: grid;
    grid-template-columns:repeat(2,1fr) ;
}
.inter{
    display:flex;
}
tops{
    display:flex;
    flex-direction: column;
}
.cours{
    display:flex;

}
.courscont{
    width: 98%;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px
}

.imgs1{
    width: 70px;
    margin-top:10px;
}
.courscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.courscontdis{
    width: 98%;
    position: relative;
    pointer-events: none;
    align-items: center;
    padding: 20px 0 40px 40px;
    margin-top: 20px;
    cursor: not-allowed;
    border: #728796 1px solid;
    border-radius: 32px;
    opacity: 0.6;
}

.course {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.img{
    width: 75%;
}

.top{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top2{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top1{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:10px;
    padding-bottom:10px;
}

.topdiv{
    width: 70%;
}

.topdiv1{
    width: 85%;
}

.num{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 50px !important;
    font-weight: 500;
    text-align: left;
    color: #FFC038;
}

.imgs3 {
    width: 120px;
    margin-top: 10px;
}

.title{
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    padding: 5px 0 10px;
}

.title_en {
    font-size: 64px;
}

.title_am {
    font-size: 57px;
}

.bottom{
    width: 95%;

}

.bottom1{
    width: 95%;
}

.link{
    padding: 15px 30px;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.link:hover{
    background:  #FFCB5C
}
.link:active{
    background: #FFB81E
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -25px
}

.text1 {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    padding-right: 10px;
    color: #728796;
}
.imgop{
    width: 55px;
    height: 55px;
    position: absolute;
    right: -60px;
    top: 0;
}
.imgcl{
    position: absolute;
    top: 0;
    left: 10px;
    width: 55px;
    height: 55px;
}

.switch {
    pointer-events: auto;
}

.finalInterviewMessage {
    white-space: pre-wrap;
    color: white;
    font-family: var(--main-font-family);
    font-size: 20px;
}

.yellow {
    color: #FFC850;
    font-size: 25px;
}

@media only screen and (max-width: 1700px) {
    .courscont, .courscontdis {
       padding : 15px 0 30px 30px;
    }

    .contless {
        gap: 20px;
    }

    .course {
        font-size: 35px;
    }

    .img {
        width: 65%;
    }

    .num {
        font-size: 60px;
    }

    .title_en {
        font-size: 35px;
    }

    .title_am {
        font-size: 28px;
    }

    .text {
        font-size: 18px;
        padding-top: 15px;
    }

}

@media only screen and (max-width: 750px) {
    .top1{
        flex-direction: column;
        align-items:flex-start;
    }
    .top2{
        margin-top:10px;
        flex-direction: column;
        align-items:flex-start;

    }
    .finalInterviewMessage {
        width:80%;
        white-space: pre-wrap;
        color: white;
        font-family: var(--main-font-family);
        font-size: 20px;
    }
    .top{
        width:99%;

    }
    .imgLes{
        width:90px;
    }
    .courscont{
        position: relative;
    }
    .imgs3{
        position: absolute;
        bottom: 10px;
        width:80px;
        right:10px;
    }
    .imgs1{
        position: absolute;
        bottom: 10px;
        width:45px;
        right:20px;
    }
    .bottom{
        margin-top:20px;
    }

    .title_en {
        font-size: 35px;
    }

    .title_am {
        font-size: 26px;
    }

    .text1{
        width:90%;
        font-size: 18px;

    }
    .contless{
        grid-template-columns:repeat(1,1fr) ;

    }
}