.image {
    width: 50%;
    height: auto;
    transform: translate(35px, -30px);
    margin-bottom: -50px;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 40px;
    color: #FFC038;
}

.form {
    flex-basis: 66%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.formHeight {
    height: 350px;
    margin-bottom: 190px;
}

.container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.containerSpace {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.method {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 12px;
    background-color: #243745;
    padding: 20px;
    cursor: pointer;
}

.methodImg {
    width: 25px;
    height: 25px;
}

.methodName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 19px;
    font-weight: 500;
    text-align: left;
    color: #EBEBEB;
}

.selected {
    border: 1px solid #FFC038;
}

.label {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #AFB9C0;
    margin-bottom: 10px;
}

.cardNumber {
    background-color: #243745;
    border-radius: 12px;
    flex-basis: 100%;
    padding: 14px 15px 13px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    border: 1px solid transparent;
}

.cardNumber input {
    border: none;
    outline: none;
    background: transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
    text-align: left;
    -moz-appearance: textfield;
    appearance: textfield;
}

.cardNumber input::-webkit-inner-spin-button,
.cardNumber input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cardNumber:focus-within {
    border: 1px solid #FFC038 !important;
}

.cont {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cont > img {
    width: 40px;
    height: auto;
}

.input {
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    background-color: #243745;
    border: 1px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover, .cardNumber:hover {
    border: 1px solid #728796;
}

.input:focus {
    border: 1px solid #FFC038;
}

.input::placeholder, .cardNumber input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.01em;
}

.half {
    flex-basis: 50%;
}

.cvcImg {
    width: 40px;
    height: auto;
    filter: hue-rotate(180deg);
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: white;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFC038;
    text-decoration: underline;
}

.summary {
    flex-basis: 30%;
    padding: 30px 25px;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.duration {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #EBEBEB;
}

.type {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #9CA3A7;
}

.line {
    border: 1px solid #627785;
    margin: 8px 0 5px;
    width: 100%;
    opacity: 0.3;
}

.price {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #AFB9C0;
}

.yellow {
    color: #FFC038;
}

.finalPrice {
    margin-top: 10px;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

@media (max-width: 800px) {
    .title {
        font-size: 22px;
        text-align: center;
        margin-top: 30px
    }

    .container {
        flex-direction: column;
    }

    .formHeight {
        height: unset;
    }

    .form {
        flex-basis: 100%;
    }

    .summary {
        flex-basis: 100%;
        order: 1;
    }

    .form {
        order: 2;
    }
}
