.meeting {
    background-color: #A8D1B6;
}

.homework {
    background-color: #FFD1D1;
}

.interview {
    background-color: #9EA1D4;
}

.lesson {
    background-color: #F1F7B5;
}

.finalInterview {
    background-color: #FF90C2;
}

.arrow {
    font-size: 22px;
    color: #FFC038;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.arrow:hover {
    color: #FFC850;
    transform: scale(1.3);
    transition: 0.2s ease-in-out;
}

.monthName {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom: 10px;
}

.name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #FFC038;
}

.events {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 20px;
}

.noEvents {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #728796;
}

