.container {
    background: #162A38;
    position: relative;
    padding: 30px 55px 40px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    z-index: 2;
}

.container_am {
    height: 580px;
}

.contdis {
    padding-top: 70px;
    position: relative;
    pointer-events: none;
    opacity: 0.3;
}

.container:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.75px  1.5px;
    background: linear-gradient(50deg, #F4B41A 0%, rgba(255, 255, 255, 0) 53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.image {
    width: 120px;
    height: 120px;
    transform: translate(-10px, -20px);
}

.img {
    width: 80px;
    height: 80px;
    transform: translate(-10px, -45px);
}

.soon {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 25px;
    letter-spacing: 0.03em;
    color: #143E59;
    rotate: 45deg;
    position: absolute;
    right: 5px;
    top: 45px;
}


.wrapper {
    position: relative;
    z-index: 3;
}

.wrapper_en {
    transform: translateY(-570px);
}

.wrapper_am {
    transform: translateY(-580px);
}

.disabled {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 4px;
    right: -5px;
}

.head {
    font-family: var(--main-font-family),sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: -50px;
    color: #FFFFFF;
}

.text {
    font-family: var(--main-font-family),sans-serif;
    font-size: 16px;
    width: 300px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color:#728796;
}

.checkcont {
    height: 60%;
    width: 100%;
    border-top: 1px solid #627785;
    padding-top: 50px;
    display: flex;
    flex-direction: column;

}

.line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-bottom: 15px;
}

.label {
    font-family:var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #AFB9C0;
}

@media only screen and (max-width: 1000px) {
   .container {
       padding: 10px 30px 20px;
   }

    .contdis {
        padding-top: 50px;
    }



    .disabled {
        width: 120px;
        height: 120px;
    }

    .soon {
        font-size: 19px;
        top: 35px;
    }
}

@media only screen and (max-width: 430px) {
    .container {
        width: 305px;
        gap: 20px;
    }

    .head {
        font-size: 17px;
    }

    .label {
        font-size: 12px;
    }

    .image {
        width: 100px;
        height: 100px;
    }

    .img {
        width: 60px;
        height: 60px;
    }



    .checkcont {
        padding-top: 30px;
    }

    .text {
        width: 100%;
        font-size: 14px;
    }
}