.girl {
    width: 25%;
    z-index: 5;
    position: absolute;
    right: 3%;
    top: 230px;
}

.platform {
    width: 20%;
    position: absolute;
    right: 26%;
    top: 150px;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 25px 0;
    color: #FFC038;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 150px;
    margin-top: 130px;

}

.sectionCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}


.container > .small:nth-child(even) .sectionCont{
    flex-direction: row-reverse;
}

.image {
    width: 50%;
}

.titleDescription {
    font-family: var(--main-font-family),sans-serif;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038;
}

.description {
    font-family: var(--main-font-family),sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    color: #EBEBEB;
}

.small {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.likeBlock {
    width: 100%;
    padding: 80px 70px;
    background: #162A38;
    position: relative;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 150px;
    gap: 30px;
    z-index: 2;
}

.likeBlock:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 48px;
    padding: 1.25px  1.5px 0;
    background: linear-gradient(150deg, #F4B41A 0%, rgba(255, 255, 255, 0) 45%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.head {
    font-family: var(--main-font-family), sans-serif;
    font-size: 45px;
    font-weight: 500;
    max-width: 750px;
    text-align: left;
    color: #FFC038;
}

.img {
    position: absolute;
    right: 140px;
    bottom: -30px;
    width: 27%;
    height: auto;
}

.text {
    font-family: var(--main-font-family),sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #EBEBEB;
    width: 60%;
}

@media only screen and (max-width: 850px) {
    .sectionCont {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
    }
    .image{
        width: 90%;
    }
    .container > .small:nth-child(even) .sectionCont{
        flex-direction: column-reverse;
    }
    .likeBlock{
        padding: 20px 20px 180px 20px;
    }
    .text{
        width: 90%;

    }
    .title{
        font-size:30px
    }
    .title_am{
        margin-top:60px;
        font-size:22px;
    }
    .description{
        font-size:18px

    }
    .head {
        font-size:28px

    }
    .img{
        width: 35%;
    }

    .girl{
        width: 30%;
        z-index: 5;
        position: absolute;
        right: 20%;
        top: 81px;
    }
    .platform{
        width: 14%;
        position: absolute;
        right: 45%;
        top: 30px;
    }
}

@media only screen and (max-width: 430px) {
    .img{
        width: 50%;
        right: 60px;
        bottom: 1px;
    }
    .girl{
        width: 39%;
        z-index: 5;
        position: absolute;
        right: 20%;
        top: 81px;
    }
    .platform{
        width: 20%;
        position: absolute;
         right: 45%;
        top: 30px;
    }
}