.title{
    font-family: var(--english-font-teko), serif;
    font-size: 42px;
    color: #FFFFFF;
}
.courscont{
    width: 100%;
    height: 580px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.courscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.textmultpy{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #BCD3DB;
    text-align: center;

}
.container{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin: 0 auto;
}
.text {
   text-align: center;
    font-family: var(--english-font-teko), serif;
    line-height: 55px;
    font-weight: 500;
    color: #BCD3DB;
}

.text_en {
    font-size: 64px;
}

.text_am {
    font-size: 45px;
}

.btn{
    padding:15px 50px ;
    font-family: var(--main-font-family), sans-serif;
    background: #FFC038;
    color: #12222D;
    font-size: 28px;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #FFC038

}
.btn:hover{
    border: 1px solid #FFC850;
    background:  #FFCB5C
}
.btn:active{
    border: 1px solid #FFB30E;
    background: #FFB81E
}
.points{
    position: absolute;
    bottom: 47px;
    left: 83px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #728796;
    font-weight: 500;
    line-height: 28px;
}

@media only screen and (max-width: 1700px) {
    .title {
        font-size: 35px;
    }

    .container {
        gap: 50px;
    }

    .text_en {
        font-size: 50px;
    }

    .text_am {
        font-size: 40px;
    }

    .btn {
        padding: 15px 40px;
        font-size: 20px;
    }

    .points {
        font-size: 20px;
    }
}
