.title{
    margin-top: 60px;
    margin-bottom: 45px;
    color:#FFC038;
    font-family: var(--main-font-family);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
}
.text{
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.inp{
    display: none;
}
.leb{
    margin-top: 45px;
    display: flex;
    width: 220px;
    column-gap: 10px;
    align-items: center;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 15px;
    background: #FFC038;
    cursor: pointer;
    padding: 6px 22px;
}