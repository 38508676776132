.container {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5% 6%;
    z-index: 100;
    background: transparent;
    transition: 0.2s ease-in-out;
}

.containerhov {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5% 6%;
    z-index: 100;
    background: #12222D;
    transition: 0.2s ease-in-out;
}

.black{
    background: #FFC850;
}

.cont {
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
}

.black{
    background: #FFC850;
}


@media only screen and (max-width: 850px) {
    .container {
        justify-content: space-between;
        padding: 4% 6%;
    }
}