.title {
    color: white;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}

.languages {
    margin-top: 20px;
    margin-bottom:20px;
    width:100%;
    display: flex;
    justify-content: center;
    gap:50px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.language {
    width: 170px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    cursor: pointer;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    background: none;
}

.selected {
    border: 2px solid #FFC038;
    color: #FFC038;
}

.cont {
    border-radius: 32px;
    border: 1px solid rgba(255, 192, 56, 0.10);
    background:  #12222D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 32px;
}

.label {
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
}

.input {
    width: 100%;
    position: relative;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px ;
    padding: 10px 13px;
}

.areaContainer {
    flex-basis: 50%;
    position: relative;
    border-radius: 12px;
    background: #243745;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    padding: 4px 8px;
}

.calendarContainer {
    display: flex;
    gap: 50px;
}

.inputContainer {
    position: relative;
    border-radius: 12px;
    background: #243745;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    padding: 8px 10px;
}

.input {
    width: 100%;
    color: #ffffff;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}

.input::-webkit-scrollbar {
    width: 8px;
}

.input::-webkit-scrollbar-thumb {
    height: 30px;
    border-radius: 5px;
    background: #0f202c;
}

.area {
    width: 50%;
    height: 150px;
    resize: none;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.icons {
    font-size: 24px;
    color: #869EB0;
    cursor: pointer;
}

.hiddenInput {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    opacity: 0;
    border: none;
    outline: none;
    cursor: text
}

.score {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



