
.tit {
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}
.btndiv{
    margin-top:20px;
    margin-bottom:20px;
    width:100%;
    display: flex;
    justify-content: center;
    gap:50px;

}
.tagOption {
    width: 170px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    cursor: pointer;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    background: none;
}

.selected {
    border: 2px solid #FFC038;
    color: #FFC038;
}
.cont{
    width:100%;
    border-radius: 32px;
    border: 1px solid rgba(255, 192, 56, 0.10);
    background:  #12222D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 32px;
}
.inpDiv{
    position: relative;
    width: 100%;
    padding-inline:150px ;
    margin-top: 32px;
}
.name{
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}
.big{
    /*margin-top: 20px;*/
    cursor: pointer;
    color:  #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 15px;
    border: 1px solid #FFC038;
    background: rgba(255, 192, 56, 0.05);
    display: inline-flex;
    padding: 12px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.detal{
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    color: #FFFFFF;
}
.conts{
    width:100%;
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
}
.controler{
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
}
.label{
    position: relative;
}
.conts > div{
    width:95%;
    display: flex;
    column-gap: 20px;
}
.controler > div{
    width: 100%;
    display: flex;
    column-gap: 20px;
}

.img{
    width: 26px;
    height: 26px;
}

.delete{
    position: relative;
    width: 100%;
    padding-inline:150px ;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.deleteButton{
    /*margin-top: 20px;*/
    cursor: pointer;
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 15px;
    border: 1px solid #AFB9C0;
    background: rgba(255, 192, 56, 0.05);
    display: inline-flex;
    padding: 12px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.input{
    width: 100%;
    color: #ffffff;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}
.area{
    width: 100%;
    color: #ffffff;
    height: 150px;
    resize: none;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}
.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}
.btns{
    margin-top: 30px;
}
.ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/

}
.li{
    width: 100%;
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.images{
    width: 35px;
    height: 25px;
    padding-left: 10px;
    cursor: pointer;
}

.file{
    display: none;
}

.lil{
    width: 100%;
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

}
.container{
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding-inline:150px;
    align-items: center;
    column-gap: 40px;
}
.divData{
    width: 50%;

}