.container {
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
    border-radius: 32px;
    z-index: 2;
    padding: 25px 10px 25px 30px;
    background: #162A38;
    height: 100%;
}

.container:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 110%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.heading {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #BCD3DB;
}

.cont {
    width: 56%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.image {
    width: 20%;
    height: auto;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.overviewCont {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
