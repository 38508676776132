.container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.cont {
    width: 48%;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    position: relative;
    padding: 20px 25px;
    z-index: 1;
    row-gap: 40px;
}
.inner{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-20deg, #F4B41A 0%, rgba(255, 255, 255, 0) 100%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.title{
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
}
.botm{
    display: flex;
    justify-content: space-between;
    /*flex-direction: column;*/
    column-gap: 16px;

}
.av{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.students {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #BCD3DB;
}
.divon{
    width: 30%;
    align-self:flex-end ;
    display: flex;
    justify-content: space-between;
    /*padding-right: 20px;*/
}

.avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.dataTime{
    display: flex;
    padding: 10px 20px;
    column-gap: 10px;
    border-radius: 32px;
    background: #143E59;
    align-items: center;
}

.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.first{
    color:#BCD3DB;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.sec{
    color: #EBEBEB;
    font-family: var(--main-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.id{
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;

}
.inpdiv{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.im{
    position: absolute;
    right: 20px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.tit{
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    
   
}
.inp{
    padding: 10px;
    width: 400px;
    color: #728796;
    font-family: var(--main-font-family), serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18px;
    border-radius: 32px;
    border: 1px solid #F4B41A;
    background:  #12222D;
}
.btn{
    padding:15px 30px ;
    font-family: var(--main-font-family), sans-serif;
    background: #FFC038;
    color: #12222D;
    font-size: 24px;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #FFC038

}
.btn:hover{
    border: 1px solid #FFC850;
    background:  #FFCB5C
}
.btn:active{
    border: 1px solid #FFB30E;
    background: #FFB81E
}

.status {
    background-color: #FDF0F54D;
    padding: 3px 18px;
    font-family: var(--main-font-family), serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    border-radius: 24px;
}

.pink {
    color: #F67DAE;
}

.green {
    color: #81B214;
}

@media only screen and (max-width: 1500px) {
    .title{
        font-size: 24px;
    }
    .sec{font-size: 12px;margin-top: 2px}
    .first{
        font-size: 14px;
    }
    .students{
        font-size: 14px;
    }
}
@media only screen and (max-width: 1350px) {
    .btn{
        font-size: 20px;
        padding: 5px 20px;
    }
    .inp{
        width: 350px;
        height: 50px;
    }
    .im{
        bottom: 20px;
    }
    .img{
        width: 30px;
        height: 30px;
    }
    .title{
        font-size: 20px;
    }
    .id{
        font-size: 20px;

    }
    .sec{font-size: 10px;margin-top: 2px}
    .first{
        font-size: 12px;
    }
    .students{
        font-size: 12px;
    }
    .avatar{
        gap: 10px;
    }
}