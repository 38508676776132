.cont{
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    padding-right: 5px;
    background: #162A38;
    margin-top: 40px;
    z-index: 100;
    border-radius: 5px;
}


.container{
    max-height: 195px;
    overflow-y: scroll;
}

.container::-webkit-scrollbar{
    width: 8px;
}

.container::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #1A2933;
}

.countryName{
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    color: #EBEBEB;
    margin-top: 7px;
    cursor: pointer;
    padding: 15px 11px 15px 20px;
}
.countryName:hover{
    color: #FFC038;
}

@media only screen and (max-width: 470px) {
    .countryName {
        font-size: 16px;
    }
}