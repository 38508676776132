.dropdown {
    border-radius: 5px;
    z-index: 1;
    margin-top:5px;
}

/*.triangle {*/
/*    left: 50%;*/
/*    top: -10%;*/
/*    z-index: -1;*/
/*}*/

.cont {
    padding-top: 25px;
}

.link {
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    text-decoration: none;
    color: #C4CDD3;
    cursor: pointer;
    display: block;
    width: 100%
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}


.icon , .iconopen{
    width: 11px;
    height: 11px;
    margin-right: 4%;
    color:#EBEBEB;
    scale: 2;
    cursor: pointer;
    transition: 0.2s;
}

.iconopen {
    rotate: -180deg;
}

.selected {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 60px;
}

.arrow {
    transition: 0.2s ease-in-out;
}

.itemopen{
    width:100%;
    padding-left:4%;
    padding-top:10px;
    display:flex;
    flex-direction: column;
}
.linkDropdown {
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    align-items: center;
    color: #FFCB5C;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.linkDropdown:active {
    color: #FFB81E;
}

.line {
    margin-left: 13px;
    height: 35px;
}

.disabled {
    pointer-events: none !important;
    opacity: 0.6;
}

.pointer {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
}

@media only screen and (max-width: 1024px) {

    .link {
        font-size: 16px;
        line-height: 33px;
    }

    .link, .linkDropdown{
        font-size: 16px;
    }

    .li {
        margin-bottom: 8px;
    }
}