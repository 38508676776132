.section {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 4%;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_ru {
    font-size: 45px;
}

.head_en {
    font-size: 57px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;
    margin: 0 auto;
    gap: 20px 15px;
}

.video:hover {
   cursor: pointer;
}

.cont {
    position: relative;
}

.muted {
    width: 25px;
    cursor: pointer;
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1;
}

.name {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #EBEBEB;
    padding: 5px 12px;
    border-radius: 12px;
    background: rgba(18, 34, 45, 0.72);
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.image {
    width: 300px;
    height: auto;
    border-radius: 32px;
}

@media only screen and (max-width: 848px) {


    .head{
        font-size:30px

    }
    .video{
        width: 150px!important;

    }
    .container{
        width: 100%;
        justify-content: center;
    }
}