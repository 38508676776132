.title {
    font-size: 22px;
    font-family: var(--main-font-family), serif;;
    display: flex;
    padding: 0 35px;
    align-items: center;
    justify-content: space-between;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quizList {
    height: 45vh;
    overflow-y: auto;
}

.quizList::-webkit-scrollbar {
    width: 6px;
}

.quizList::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #F4B41A;
}

.white {
    color: #BCD3DB;
}

.yellow {
    color: #FFCB5C;
}

.quizTitle {
   margin-top: 20px;
}

.titleOfQuiz {
    color: #BCD3DB;
    text-align: center;
    font-family: var(--english-font-teko), serif;
    font-size: 28px;
    font-weight: 500;
    margin: 10px 0;
}