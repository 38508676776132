
.left{
    width: 50%;
    /*padding-inline:10px ;*/

}
.right{
    width: 50%;
    padding-inline:10px ;
}
.container{
    display: flex;
}
.cont {
    /*grid-column: 9 span;*/
    /*background-color: #162A38;*/
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 20px 20px 35px 30px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

}
.scroll{
    width: 100%;
    height: 600px;
    overflow: auto;
    padding-right: 10px;
}
.scroll::-webkit-scrollbar{
    width: 8px;
}

.scroll::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #355972;
}
.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.total{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: center;
}
.totalon{
    display: flex;
    align-items: center;
    column-gap:40px ;

}
.on{
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}
.to{
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
}
.course {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}
.num{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 100px;
    font-weight: 500;
    /*letter-spacing: 0.02em;*/
    text-align: left;
    color: #FFC038;
}
.title{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 64px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    margin-top: -20px;
}

.text{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
}

.img{
    width: 200px;
}

.textSide{
    width: 100%;
}
.textsi{
    width: 100%;
    padding-inline:10px ;

    border-right:1px solid #728796 ;

}
.titList{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    margin-top: 40px;
}
.titList > p {
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44.8px;
    letter-spacing: 0.64px;
}
.last{
    margin-right: 30px;
}
.titListmap{
    text-decoration: none;
    margin-bottom: 16px;
    border-radius: 16px;
    background: rgba(20, 62, 89, 0.50);
    width: 100%;
}
.link{
    display: flex;
    align-items: center;
    column-gap: 40px;
    padding: 15px 20px;
}
.ones{
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ones > div {
    display: flex;
    align-items: center;
    column-gap: 40px;
    padding: 15px 20px;
    cursor: pointer;
}
.studentname{
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.studentname1{
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.imgsstudents{
    width: 55px;
    height: 55px;
    border-radius:50%;
}
.status{
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 24px;

}
.btn{
    padding:15px 20px ;
    font-family: var(--main-font-family), sans-serif;
    background: #FFC038;
    color: #12222D;
    font-size: 18px;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #FFC038

}
.btn:hover{
    border: 1px solid #FFC850;
    background:  #FFCB5C
}
.btn:active{
    border: 1px solid #FFB30E;
    background: #FFB81E
}

.searchBar {
    width: 100%;
    border: 1px solid #f1c40f;
    padding: 15px 30px;
    border-radius: 32px;
    background: transparent;
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
    font-size: 16px;
}

.searchBar::placeholder {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #728796;
    font-size: 16px;
}

.searchContainer {
    width: 350px;
    position: relative;
}

.searchIcon {
    position: absolute;
    top: 13px;
    right: 25px;
    font-size: 28px;
    color: #728796;
}

@media only screen and (max-width: 1550px ){
    .imgsstudents{
        width: 36px;
        height: 36px;
    }
    .studentname{
        font-size: 20px;
    }
    .points{
        width: 30px;
        height: 30px;
    }
    .on{
        font-size: 28px;
    }
    .to{
        font-size: 18px;
    }
    .btn{
        font-size: 14px;
    }
}
