.trainers{
    margin-bottom: 125px;
}

.title {
    font-weight: 500;
    font-family: var(--english-font-teko),sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 120px;
    color: #FFC038;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.inner{
    display: flex;
    justify-content: space-around;
    column-gap: 20px;
}

.trainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    border-radius: 40px;
    padding: 36px 12px;
    width: 100%;
    max-width: 350px;
    background: linear-gradient(rgba(20, 62, 89, 0), rgba(20, 62, 89, 1));
    z-index: 2;
}

.trainer::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 36px;
    padding: 0.4px 1.5px 1.25px;
    background: linear-gradient(8deg, #F4B41A 0%, rgba(255, 255, 255, 0) 70%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.trainer img {
    position: absolute;
    bottom: 25px;
    left: 12px;
    width: 140px;
    height: auto;
}

.info{
    width: 150px;
    color: white;
}

.info span{
    font-size: 20px;
    font-family: var(--english-font-teko),sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
}

.info p{
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    margin-top: 10px;
}

@media only screen and (max-width: 848px) {
    .info span{
        font-size: 20px;
        font-family: var(--english-font-teko),sans-serif;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 1;
    }
    .title{
        font-size:30px

    }
    .title_am{
        font-size:22px

    }
    .trainers{
        margin-bottom: 40px;

    }
    .inner{
        flex-direction:column;
        gap:80px;
        justify-content: center;
        align-items:center;


    }

}