.container {
    width: 100%;
    padding: 5% 7% 5% 10%;
    background: #162A38;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 30px;
    z-index: 2;
    left: 0;
    right: 0;
}

.heart {
    width: 11%;
    position: absolute;
    top: -410px;
    right: 20px;
    z-index: 10;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_en {
    font-size: 57px;
}

.head_ru {
    font-size: 45px;
}

.text {
    font-family: var(--main-font-family),sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #EBEBEB;
    max-width: 550px;
}

.cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:30px;
    padding-right: 90px;
}
.image {
    width: 180px;
    height: auto;
    border-radius: 20px;
}

.about {
    width: 180px;
    height: 180px;
    border-radius: 20px;
    background: #213E53;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.link {
    text-decoration: none;
}


.about:hover {
    background: #355164;
}

.about:active {
    background: #193B53;
}

.about::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 0 1.5px 1px;
    background:linear-gradient(-40deg, #F4B41A 0%, rgba(255, 255, 255, 0)
    53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.innertext {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFFFFF;
}

@media only screen and (max-width: 1515px) {
    .container {
        justify-content: center;
        padding: 5%;
    }

    .cont {
        align-items: center;
        padding-right: 0;
        gap: 15px;
    }

    .image, .about {
        width: 160px;
        height: 160px;
    }
}

@media only screen and (max-width: 850px) {
    .heart {
        width: 0;
    }
}

@media only screen and (max-width: 600px) {
    .head_am {
        font-size: 22px !important;
    }
    .head_am {
        font-size: 22px !important;
    }
    .head {
        font-size: 30px;
    }

    .text {
        font-size: 15px;
    }
}

@media only screen and (max-width: 500px) {
    .image, .about {
        width: 120px;
        height: 120px;
    }

    .innertext {
        font-size: 23px;
    }
}