.container {
    width: 300px;
    height: 500px;
    background-color: #182E3D;
    border-radius: 21px;
    background-size: contain;
    position: relative;
    padding-bottom: 15px;
    z-index: 2;
    cursor: pointer;
}

.container:hover {
    background: #1B3445;
}

.container::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.5px  1.25px;
    background: linear-gradient(60deg, #F4B41A 1.19%, rgba(255, 255, 255, 0) 19.74%,
                rgba(244, 180, 26, 0) 39.67%);

    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.comingSoonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px
}

.comingSoonContainer .title {
    padding-top: 90px;
    font-family: var(--english-font-teko);
    font-size: 24px;
    color: white;
    text-align: center;
}

.disabled {
    opacity: 0.4
}

.pointerEvents {
    pointer-events: none;
}

.comingSoon {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -1px;
    right: -3px
}

.soon {
    font-family: "Teko", sans-serif;
    font-size: 28px;
    letter-spacing: 0.03em;
    color: #143E59;
    rotate: 45deg;
    position: absolute;
    right: -5px;
    top: 33px;
}

.format {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #FFC038;
    width: 150px;
    text-align: center;
    padding: 4px 10px;
    background: rgba(255, 192, 56, 0.05);
    border: 1px solid #FFC038;
    border-radius: 7px;
    margin-top: 20px;
}

.image {
    height: 40%;
    width: 100%;
    border-radius: 12px 12px 0 0;
    cursor: pointer;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.heart {
    width: 30px;
    cursor: pointer;
}

.icon {
    width: 15px;
    height: 13px;
}

.like {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 17px;
}

.num {
    background: #213E53;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.number {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #C2C7CB;
    display: inline-block;
    padding-left: 5px;
}

.innertext {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #728796;
    display: inline-block;
    padding-left: 5px;
}

.cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 7px;
}

.divcont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 10px 17px;
}

.header {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #FFC038;
    padding: 15px 0 0 17px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.online {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #FFC038;
    width: 70px;
    text-align: center;
    padding: 4px 10px;
    background: rgba(255, 192, 56, 0.05);
    border: 1px solid #FFC038;
    border-radius: 7px;
    margin:0 17px 10px 0;
}

.hcont {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 10px;
}

.text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Fira Sans ", sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    text-align: left;
    color: #E7E7E7;
    padding:0 17px;
}

.date {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #3FA2F0;
    padding-left: 17px;
}

.costcont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    position: absolute;
    bottom: 17px;
}

.cost {
    font-family: var(--main-font-family), sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0.01em;
    text-align: right;
    color: #FFC038;
    padding-right: 24px;
}

.month {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    text-align: left;
    color: #728796;
    padding-top: 10px;
}


.sale {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: right;
    color: #9A9A9A;
    text-decoration: line-through;
    margin-right: -5px;
}

@media only screen and (max-width: 400px) {
    .container {
        width: 98%;
        margin: 0 5px;
    }
}

