.certification {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.titleCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleCont > div {
    display: flex;
    align-items: center;
    gap: 50px;
    padding-bottom: 30px;
}

.container {
    background-color: #162A38;
    border-radius: 48px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 48px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-55deg, #F4B41A 0%, rgba(255, 255, 255, 0) 30%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.performances {
    font-family: Fira Sans, sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFC038;
}

.numberCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table tr td {
    width: 25%;
    padding: 13px 0;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
}

.table tr:not(.table thead tr) {
    border-top: 1px solid #728796;
    border-bottom: 1px solid #728796;
}

.table tr:not(.table thead tr):first-child {
    border-top: none;
}

.table thead tr td{
    width: 25%;
    padding: 10px 0 20px;
    text-align: center;
    vertical-align: middle;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #728796;
}

.table thead tr td:first-child {
    padding-right: 120px;
}

.line {
    height: 3px;
    width: 15%;
    background-color: #728796;
    border: none;
    margin: auto;
}

.cell {
    display: flex;
    align-items: center;
    gap: 10px;
}

.student {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.date {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #BCD3DB;
}


.name {
    font-family: var(--main-font-family),sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #BCD3DB;
    padding-left: 20px;
}

.type {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.participation, .basicSkills {
    color: #BCD3DB;
}

.excellence {
    color: #FFC038;
}

.number {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #BCD3DB;
    padding-right: 40px;
}

.button {
    padding: 13px 30px;
    border: 1px solid #FFC038;
    border-radius: 15px;
    background: #FFC0380D;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: white;
    align-self: center;
    margin-top: 25px;
    cursor: pointer;
}

.typeDrop {
    width: 190px;
    background-color: #243745;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 16px 0 #0D1C26B8;
    z-index: 10;
}

.dropType {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #EBEBEB;
    cursor: pointer;
}

.dropType:hover {
    color: #FFC850;
}

.arrow {
    font-size: 24px;
    transition: 0.2s ease-in-out;
}

.arrowOpen {
    font-size: 24px;
    transform: rotate(180deg);
    transition: 0.2s ease-in-out;
}

.clickable {
    cursor: pointer;
}

.typeName {
    display: flex;
    align-items: center;
}
