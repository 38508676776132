.date {
    width: 400px;
    background-color: #243745;
    border-radius: 8px;
}






