.tableContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.table tr td, table tr th {
    text-align: center;
    padding: 15px 0;
    max-width: 100%;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: white;
}

.green {
    color: #66CF8A
}

.studentName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    color: #EBEBEB;
    margin-left: 20px
}

.firstColumn {
    width: 30% !important
}

.table tr, .table tr td, .table tr th{
    border: 1px solid #728796;
}

.table thead tr th p {
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    display: block;
}

.table thead tr th {
    position: relative;
    height: 200px;
    padding: 10px;
}

.table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table thead tr th:first-child{
    border-top-left-radius: 20px;
}

.table thead tr th:last-child {
    border-top-right-radius: 20px;
}

.table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 20px;
}

.table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 20px;
}

.input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    -moz-appearance: textfield;
    appearance: textfield;
    padding: 0 15px;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input::placeholder {
    color: #728796;
}

.price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
}

.finalInterviewMark {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.message {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 1.4;
    text-align: left;
    color: #EBEBEB;
}
