.container {
    position: relative;
}

.select {
    width: 180px;
    padding: 7px 15px 7px 20px;
    background-color: #143E59;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    font-size: 21px;
    color: #FFFFFF;
    transition: 0.2s ease-in-out;
}

.arrowopen {
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

.option {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
}

.selected {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option:hover {
    color: #FFC850;
}

.drop {
    width: 180px;
    padding: 13px 40px 13px 20px;
    background-color: #143E59;
    border-radius: 20px;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 10;
}