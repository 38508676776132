.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.container {
    min-height: 190px;
    padding: 26px 20px 27px 30px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    gap: 40px
}

.skills {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px 1.5px;
    background: linear-gradient(-15deg, #F4B41A 0%, rgba(255, 255, 255, 0) 97%, rgba(244, 180, 26, 0) 100%);
    mask: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%) content-box, linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.skill {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    width: 150px;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.title_en {
    font-size: 35px;
}

.title_am {
    font-size: 26px;
}

.skillType {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #FFC038;
    margin-bottom: 5px
}

.percent {
    font-family: var(--main-font-family), sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-left: -3px;
}

.more {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #AFB9C0;
    align-self: flex-end;
    margin-top: -3px;
    cursor: pointer;
}

.more:hover {
    color: #FFC850;
}

.skillsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 10px;
}

.skillsContainer > span {
    flex-basis: 50%;
    border-radius: 25px;
    border: 2px solid #AFB9C0;
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    color: #AFB9C0;
    padding: 10px 0;
    text-align: center;
}

.image {
    width: 185px;
    position: absolute;
    bottom: -5px
}

.motivation {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.white {
    color: white;
}

.yellow {
    color: #FFC038;
    margin-bottom: 5px
}

.motivationContainer {
    width: 60%;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}
@media only screen and (max-width: 650px){
   .container{
       flex-direction:column-reverse;
   }
    .image{
        position: relative;
    }
    .motivationContainer {
        width: 100%;
        padding-inline: 10px;
        position: relative;
        right: 30px;
        top: 0;
        transform: translateY(-1px);

    }
}