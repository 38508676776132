.parent {
    background: #121623;
    border-radius: 5px;
    z-index: 20;
    max-width: 920px;
    /*height: 80vh;*/
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 100;
    background: rgba(67, 73, 101, 0.7);

}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    gap:40px;
}
.p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;

}
.titles{
    margin-top: 40px;
    color:  #FFC038;
    font-family: var(--main-font-family);
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.texts{
    color:  #AFB9C0;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #355CCA;
}

.btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #FFFFFF;
    width: 197px;
    height: 50px;
    cursor: pointer;
    background: #355CCA;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 25px;
}
.btn:active{
    background: #0E41D0;
}
.containerSkilss{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 120px;
    column-gap: 16px;
    row-gap: 12px;

}
.containerSkilss::-webkit-scrollbar{
    width: 8px;
}

.containerSkilss::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #1A2933;
}
.skills{
    display: flex;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #FFC038;
    color: #FFC038;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.skillshave{
    display: flex;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.inp{
    width: 520px;
    height: 66px;
    border-radius: 12px;
    background: #243745;
    border: none;
    padding: 10px;
    color: #728796;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inp::placeholder{
    color: #728796;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btns{
    padding: 10px 60px;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 15px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.btns:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.btns:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}

.new{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 12px;
}
.new::-webkit-scrollbar{
    width: 8px;
}

.new::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
    background: #1A2933;
}

.inpdiv{
 width: 70%;
}
.alreadyhave {
    width: 80%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}
.first{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

}
.sec{
    display: flex;
    justify-content: space-between;
    align-items:center;

}
.third{
    display: flex;
    justify-content: space-between;
    align-items:center;

    margin-top: 32px;
}
.btndiv{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid  rgb(113, 134, 148, 0.5);
    margin-top: 32px;
    padding: 32px 0;

}
@media only screen and (max-width: 640px){
    .parent{
        width: 90%;
    }
    .p{
        text-align: center;
    }
}