.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-bottom: 50px;
}

.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 6%;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_ru {
    font-size: 45px;
}

.head_en {
    font-size: 57px;
}

.container {
    width: 84%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 0.7fr 1fr;
    gap: 15px;
}

.cont {
    background: #182E3D;
    border-radius: 32px;
    padding: 35px 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFC038;
}

.mission {
    grid-column: span 10;
}

.valuetitle {
    grid-column: span 1;
    display: unset;
    padding: 0;
    position: relative;
}

.value {
    grid-column: span 3;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #AFB9C0;
}

.titleval {
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    color: #EBEBEB;
}

.ourValues {
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
}

@media only screen and (max-width: 848px) {
    .title{
        font-size:22px;
    }
    .head{
        font-size:30px

    }
    .head_am{
        font-size:22px;
        white-space:pre-wrap;

    }
    .container {
        width: 84%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .ourValues {
        text-align: center;
        /*white-space: nowrap;*/
        position: relative;
        left: 0;
        top: 0;
        transform: none;
    }

}