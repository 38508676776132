.image {
    width: 40%;
    height: auto;
    transform: translateX(50px);
}

.img {
    width: 150px;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25%;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.cont {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
}

.account {
    align-self: center;
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: -25px;
    color: #C2C7CB;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #FFC038;
}

.label {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.caption {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: #AFB9C0;

}

.input {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.04em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input:focus {
    border: 1.2px solid #FFC038;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.04em;
}

.error {
    font-family: var(--main-font-family), sans-serif;
    font-size: 13px;
    padding-top: 5px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #CF6679;
}

.err {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #CF6679;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
}


.errorimg {
    width: 32px;
    height: auto;
}

.errorinp {
    border: 1.2px solid #CF6679 !important;
}

.noterrinp  {
    border: 1.2px solid #66CF8A !important;
}

.inputcont {
    width: 100%;
    height: 120px;
}

.rem {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #728796;
}

.checkcont {
    display: flex;
    align-items: center;
}

.pass {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    max-width: 600px;
    color: #EBEBEB;
}

.link {
    font-family: var(--main-font-family),sans-serif;
    font-size: 17px;
    font-weight: 200;
    color: #FFC038;
}

.contain {
    width: 100%;
    padding: 4% 5%;
    background: #243745;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 80px;
    gap: 25px;
}

.spam {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #AFB9C0 ;
    padding-top: 25px;
    margin-bottom: -15px;
}

.btncont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.passwordUpdate {
    gap: 30px;
    align-self: center;
    padding: 20px 0 ;
}

.role {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.role:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
}

.chooseYourRole {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.student {
    width: 110%;
}

.teacher {
    width: 78%;
}

.admin {
    width: 100%;
}

.roleName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.rolesCont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}


.roleCont {
    max-width: 280px;
    height: 280px;
    background-color: #162A38;
    border-radius: 31px;
    padding: 20px 30px 0;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.eye {
    width: 21px;
    margin-left: -33px;
    margin-bottom: -5px;
    cursor: pointer;
}

.roleCont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 31px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-40deg, #F4B41A 0%, rgba(255, 255, 255, 0) 50%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
    gap: 15px;
}

.role:hover .roleCont {
    background-color: #1B3445;
    transition: 0.2s ease-in-out;
}

.message {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin: -15px 0;
    display: flex;
    align-items: center;
    gap: 5px
}

.success {
    color: #66CF8A
}

.errorMessage {
    color: #CF6679;
}

.messageImg {
    width: 28px;
    height: auto;
}

.password {
    position: relative;
}

@media only screen and (max-width: 1200px) {
    .container {
        padding: 35px 0;
    }
}

@media only screen and (max-width: 1060px) {
    .inputcont {
        width: 100%;
    }


    .container {
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 800px) {
    .image {
        width: 150px;
    }

    .inputcont {
        width: 100%;
    }

    .error {
        padding-top: 0;
    }


    .container {
        width: 100%;
        padding-bottom: 25px;
    }


}

@media only screen and (max-width: 470px) {

    .input,.caption,.input::placeholder, .rem{
        font-size: 16px;
    }

    .link, .text {
        font-size: 14px;
    }

    .cont{
        width: 100%;
    }
}
