.contSt{
    width: 68%;
    background-color: #162A38;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 0 30px 35px 30px;
    display: flex;
    justify-content: space-between;
}

.contSt::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.cont {
    /*grid-column: 9 span;*/
    background-color: #162A38;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 0 30px 35px 30px;
    display: flex;
    justify-content: space-between;
}

.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.course {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 25px
}

.course_en {
    font-size: 42px;
}

.course_am {
    font-size: 30px;
}

.num {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 100px;
    font-weight: 500;
    text-align: left;
    color: #FFC038;
}

.title {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    padding-bottom: 15px
}

.title_en {
    font-size: 64px;
}

.title_am {
    font-size: 57px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.big{
    display:flex;
    justify-content: space-between;

}
.left{
    padding: 20px 0;
    width: 63%;
}

.right{
    width: 37%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img{
    max-width: 340px;
}
.linerProg{
    margin-top: 40px;
    gap: 20px;
    display: flex;
    align-items: center;
}
.pointcol{
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #BCD3DB;
}

.textSide{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.userSide{
    width: 70%;
    margin-top: 20px;
}
.quiz{
    width:30%;
}
.element{
    padding-inline: 10px;
    color: white;
}
.btn{
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.prev{
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;

}
.prev::after{
    content: url("/public/images/VectorNext.png");
    position: absolute;
    width: 10px;
    height: 10px;
    left: -25px;
    bottom: 3px;
    transform: rotate(180deg);
    cursor: pointer;

}

.next{
    color: #FFC038;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;

}

.next::after{
    content: url("/public/images/VectorNext.png");
    position: absolute;
    width: 10px;
    height: 10px;
    right: -25px;
    bottom: 20px;
    cursor: pointer;
}
.imgs{
    width: 160px;
}
.times{
    width: 30%;
    position: relative;
    align-items: center;
    padding: 20px 25px 20px 25px;
    cursor: pointer;
}
.timerText{
    color:#BCD3DB;
    font-size:24px;
    text-align:center;
    font-family: var(--main-font-family), sans-serif;
}
.timeDiv{
    width: 100%;
    background:#243745 ;
    border-radius:12px;
    display: flex;
    align-items: center;
    padding-inline:10px ;
    margin-top:10px
}
.imgclosk{
    margin-top: 5px;
    width:52px;
    height:52px;
}
.inp{
    background:none;
    border:none;
    font-family: var(--main-font-family), sans-serif;
    font-size:20px;
    color:white;
    margin-left: 5px;
}
.btns{
    width: 100%;
    margin-top:20px;
    padding:8px 0;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 12px;
    background:  #FFC038;
    color: #12222D;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.btns:hover{
    /*border: 1px solid #FFC850;*/
    background:  #FFCB5C
}
.btns:active{
    /*border: 1px solid #FFB30E;*/
    background: #FFB81E
}
.times::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

@media only screen and (max-width: 2000px) {
    .course {
        font-size: 35px;
    }

    .cont {
        padding-top: 10px;
    }
    .contSt {
        padding-top: 10px;
    }
    .num {
        font-size: 80px;
    }

    .title_en {
        font-size: 45px;
    }

    .title_am {
        font-size: 30px;
    }

    .text {
        font-size: 18px;
    }

    .img {
        width: 180px;
    }

    .linerProg {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 1500px) {

    .num {
        font-size: 50px;
    }

    .title_en {
        font-size: 35px;
    }

    .title_am {
        font-size: 26px;
    }

    .text {
        font-size: 18px;
    }

    .img {
        width: 150px;
    }

}

