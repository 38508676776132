


.cont {
    background-color: #162A38;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    padding: 0 30px 35px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.course {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}
.num{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 68px;
    font-weight: 500;
    /*letter-spacing: 0.02em;*/
    text-align: left;
    color: #FFC038;
}
.title{
    font-family: var(--english-font-teko), sans-serif;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #BCD3DB;
    margin-top: -20px;
}

.text{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #728796;
}
.left{
    width: 75%;
}
.right{
    width: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img{
    width: 150px;
}

.textSide{
    /*display: flex;*/
    width: 100%;
}
.titList{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    margin-top: 40px;
}
.titList > p {
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44.8px;
    letter-spacing: 0.64px;
}
.last{
    margin-right: 30px;
}
.titListmap{
    margin-bottom: 22px;
    border-radius: 16px;
    background: rgba(20, 62, 89, 0.50);
    width: 100%;
}
.ones{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ones > div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}
.studentname{
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.imgsstudents{
    width: 70px;
    height: 70px;
}
.status{
    display: flex;
    width: 175px;
    height: 53px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: rgba(18, 34, 45, 0.50);
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 24px;

}
.points{
    margin-right: 30px;
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pointss{
    margin-right: 118px;

}
.bottom{
    width: 90%;
    border-top:1px solid #BCD3DB;
    display: flex;
    gap: 42px;
    margin: 0 auto;
    padding: 32px 0;
}
.bottom > div {
    column-gap: 22px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 32px;
    background: #143E59;
}
.bottom > div > p:first-child {
    color: #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
}
.bottom > div > p:last-child {
    color:#EBEBEB;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.arrow {
    cursor: pointer;
    font-size: 35px;
    color: #869EB0;
    transition: 0.2s ease-in-out;
}
.arrowopen {
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 1450px ){
    .bottom > div > p:first-child {
        font-size: 20px;
    }
    .bottom > div > p:last-child {
       font-size: 18px;
    }
    .bottom{
        gap: 32px;
    }
}
@media only screen and (max-width: 1285px ){
    .bottom > div > p:first-child {
        font-size: 18px;
    }
    .bottom > div > p:last-child {
        font-size: 16px;
    }
    .bottom{
        gap: 22px;
    }
    .studentname{
        font-size: 22px;
    }
    .status{
        font-size: 22px;
    }
    .points{
        font-size: 22px;
    }
}
@media only screen and (max-width: 1190px ){
    .bottom > div > p:first-child {
        font-size: 18px;
    }
    .bottom > div > p:last-child {
        font-size: 16px;
    }
    .bottom{
        gap: 16px;
    }
    .bottom > div {
        gap: 10px;
    }

}