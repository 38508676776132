.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    padding-top: 4%;
    color: #FFC038;
}

.head_en {
    font-size: 57px;
}

.head_ru {
    font-size: 45px;
}

.head_am {
    font-size: 40px;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap:35px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    width: 80%;
    color: #EBEBEB;
}

@media only screen and (max-width: 600px) {
    .head {
        font-size: 28px;
    }
    .head_am {
        font-size: 22px !important;
        white-space: pre-wrap !important;
    }
}

@media only screen and (max-width: 780px) {
    .text {
        font-size: 15px;
        width: 100%;
    }

    .container {
        gap: 20px;
        padding-bottom: 50px;
    }
}
