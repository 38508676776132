.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.overviewUser {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.skillsRecom {
    width: 100%;
    display: flex;
    gap: 4%;
    justify-content: flex-start;
}

.progressCont {
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.calendarCont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.cont {
    padding: 70px 70px 75px 20px;
    border-radius: 32px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px 1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 110%, rgba(244, 180, 26, 0) 100%);
    mask: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%) content-box, linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.image {
    max-width: 290px;
    height: auto;
    border-bottom: 0.5px solid #728796;
    padding-right: 50px;
}

.textcont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 65%;
}

.name {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.name_en {
    font-size: 40px;
}

.name_am {
    font-size: 36px;
}

.text {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.text_en {
    font-size: 45px;
}

.text_am {
    font-size: 34px;
}

.soonImg {
    max-width: 228px;
    height: auto;
    border-bottom: 0.5px solid #728796;
    padding-right: 40px;
}

.comingSoon {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 64px;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.comingContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
}

.timeCont {
    display: flex;
    gap: 30px;
    align-items: flex-start;
}

.timePart {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.time {
    font-family: var(--main-font-family), sans-serif;
    font-size: 57px;
    font-weight: 500;
    color: white;
}

.yellow {
    color: #FFC038;
}

.part {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #AFB9C0;
}


@media only screen and (max-width:1350px ) {
    .calendarCont{
        flex-direction: column;
    }
}
@media only screen and (max-width:1200px ) {
    .overviewUser{
        flex-direction: column-reverse;
    }
}
@media only screen and (max-width:740px ) {
    .cont{
        flex-direction: column;
        padding: 30px 20px 35px 30px;
        align-items:flex-start;
    }
    .image{
        max-width: 140px
    }
    .text{
        font-family: var(--english-font-teko), sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #FFFFFF;
        padding-bottom: 20px;
    }
    .name{
        font-size: 30px;

    }
    .textcont{
        width:93%
    }
    .soonImg {
        max-width: 148px;
        height: auto;
        border-bottom: 0.5px solid #728796;
        padding-right: 40px;
    }
    .time{
        font-size:37px;
    }
    .part{
        font-size:22px;
    }
    .comingSoon{
        font-size:46px;

    }
    .timePart{
        width:100%;
    }
    .comingContainer{
        width:100%;

    }
}
@media only screen and (max-width:560px ) {
    .timeCont{
        gap:12px;
    }
    .soonImg {
        max-width: 120px;
        height: auto;
        border-bottom: 0.5px solid #728796;
        padding-right: 40px;
    }
    .time{
        font-size:20px;
    }
    .part{
        font-size:16px;
    }
    .comingSoon{
        font-size:26px;

    }
    .timePart{
        width:100%;
    }
    .comingContainer{
        width:100%;

    }
}