.contright {
    width: 100%;
    height: 60px;
    background: #162A38;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    padding: 0 6%;
    right: 0;
    left: 0;
}

.card {
    width: 54px;
    height: 37px;
}

.light {
    font-family: var(--main-font-family), sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    color: #728796;
    line-height: 1.4;
    text-align: center;
}

.appdiv2 {
    display: flex;
    justify-content: end;
    gap: 3%;
    align-items: center;
}

@media only screen and (max-width: 690px) {
    .card {
        width: 41px;
        height: 29px;
    }
}

@media only screen and (max-width: 548px) {
    .contright {
        height: 50px;
    }
    .contright {
        justify-content: center;
    }
}


