.container {
    padding: 15px 0;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    background:#162A38;
    border-radius: 32px;

}

.container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 120%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.titdiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    column-gap: 20px;
    /*padding: 15px 0;*/
}
.imgsstudents{
    width: 60px;
    height: 60px;
    border-radius:50%;
}
.status{
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;

}
.studentname{
    color:  #BCD3DB;
    font-family: var(--main-font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contbottom{
    background:#162A38;
    margin-top: 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
}
.contbottom > div{
    width: 85%;
}