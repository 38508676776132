.whyThisCourse {
    width: 100%;
    background: #162a38;
    padding: 50px 50px 70px;
    border-radius: 32px;
    position: relative;
    z-index: 2;
    margin-bottom: 125px;
}

.whyThisCourse .optionsContainer {
    display: flex;
    justify-content: space-around;
    gap: 40px
}

.whyThisCourse .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.title {
    margin-bottom: 50px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.02em;
    font-family: var(--english-font-teko), sans-serif;
    color: #FFC038;
}

.title_en {
    font-size: 57px;
}

.title_am {
    font-size: 40px;
}

.title_ru {
    font-size: 45px;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #c2c7cb;
}

.whyThisCourse:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.25px 1px;
    background: linear-gradient(15deg, #F4B41A 0%, rgba(255, 255, 255, 0) 53.33%, rgba(244, 180, 26, 0) 100%);
    mask:
        linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
        linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}


.whyThisCourse ul li {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    font-size: 18px;
    color: rgba(194, 199, 203, 1);
}

@media only screen and (max-width: 848px) {
    .title {
        font-size: 30px

    }

    .title_am {
        font-size: 22px

    }

    .whyThisCourse {
        margin-bottom: 40px
    }

    .whyThisCourse .optionsContainer {
        flex-direction: column;
    }
}

@media only screen and (max-width: 400px) {
    .text {
        font-size: 16px;
    }

    .whyThisCourse {
        padding: 30px 20px;
    }

    .whyThisCourse .column {
        gap: 15px;
    }

    .title {
        margin-bottom: 30px;
    }
}