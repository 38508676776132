.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 34, 45, 0.5);
    z-index: 100;
}

.modal {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    position: relative;
    background: rgba(18, 34, 45, 1);
    border-radius: 32px;
    z-index: 3;
    padding: 30px 0;
}

.boxShadow {
    box-shadow: 0 0 30px 0 #FFC0384D;
}

.modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 41px;
    text-align: center;
    color: #FFC038;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.close {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 25px;
    right: 30px;
    color: #AFB9C0;
}

.description {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 80%;
    margin-top: -10px;
}

.link {
    border-radius: 12px;
    border: 1px solid #FFC038;
    background: #FFC038;
    padding:10px 30px;
    cursor: pointer;
    font-size:20px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #12222D;
    white-space: nowrap;
}

.link:hover {
    background: #FFC850;
}

.link:active{
    background: #FFB81E;
}
