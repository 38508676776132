.container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.cont {
    width: 48%;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    position: relative;
    padding: 20px 25px;
    z-index: 1;
    row-gap: 40px;
}

.cont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-20deg, #F4B41A 0%, rgba(255, 255, 255, 0) 100%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.title{
    color: #FFF;
    font-family: var(--main-font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
}
.botm{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.students {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #BCD3DB;
}

.avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.dataTime{
    display: flex;
    padding: 10px 20px;
    column-gap: 10px;
    border-radius: 32px;
    background: #143E59;
    align-items: center;
}

.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.progres{
    color: #BCD3DB;
    font-size: 20px;
    font-family: var(--main-font-family);

}

.first{
    color:#BCD3DB;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.sec{
    color: #EBEBEB;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 1500px) {
    .title{
        font-size: 24px;
    }
    .sec{font-size: 12px;}
    .first{
        font-size: 14px;
    }
    .students{
        font-size: 14px;
    }
    .progres{
        font-size: 18px;
    }
}
@media only screen and (max-width: 1250px) {
    .title{
        font-size: 20px;
    }
    .sec{font-size: 10px;margin-top: 2px}
    .first{
        font-size: 12px;
    }
    .students{
        font-size: 12px;
    }
    .avatar{
        gap: 10px;
    }
}