    .points{
    color:  #FFC038;
    font-family: var(--main-font-family), serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.container{
    margin-top: 60px;
}
.cont{
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 25px;
}
.arry{
    border-radius: 12px;
    border: 1px solid  #F4B41A;
    background: #243745;
    display: inline-flex;
    padding: 14px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

}
.text{
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pnt{
    color:  #BCD3DB;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
}