.textarea {
    width: 100%;
    height: 100px;
    caret-color: #FFFFFF ;
    resize: none;
    outline: none;
    padding: 10px 13px;
    border: 1.2px solid #243745;
    border-radius: 12px;
    background: #243745;
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #EBEBEB;
}

.textarea::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #728796;
}

.textarea:focus {
    border: 1.2px solid #FFC038 !important;
}

.textarea:hover {
    border: 1.2px solid #728796;
}

.textarea::-webkit-scrollbar {
    width: 5px;
}

.textarea::-webkit-scrollbar-thumb {
    background-color: #728796;
    border-radius: 15px;
}

.textarea::-webkit-scrollbar-thumb:hover {
    background-color: #F4B41A;
}

