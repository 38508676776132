.primary, .bigPrimary {
    border-radius: 12px;
    border: 1px solid #FFC038;
    background: #FFC038;
    cursor: pointer;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #12222D;
    white-space: nowrap;
}

.primary, .secondary {
    padding: 10px 17px;
    font-size: 17px;
}

.primary:disabled, .secondary:disabled,
.bigPrimary:disabled,.bigSecondary:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.bigPrimary {
    padding: 13px 18px;
    font-size: 18px;
}

.secondary, .bigSecondary {
    text-align: center;
    border: 1px #FFFFFF solid;
    background: transparent;
    color: #FFFFFF;
    cursor: pointer;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    border-radius: 12px;
    line-height: 26px;
    letter-spacing: 0.02em;
    white-space: nowrap;
}

.bigSecondary {
    padding: 13px 22px;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.secondary:hover,
.bigSecondary:hover {
    background: rgba(256,256,256, 0.06);
}

.secondary:active,
.bigSecondary:active {
    background: rgba(256,256,256, 0.12);
}

.primary:hover,
.bigPrimary:hover {
    background: #FFC850;
}

.primary:active,
.bigPrimary:active{
    background: #FFB81E;
}

.arrow {
    width: 21px;
    height: 16px;
    padding-right: 10px;
}

@media only screen and (max-width: 1024px) {
    .primary, .secondary {
        padding: 10px 14px;
        font-size: 14px;
    }

    .bigPrimary {
        padding: 11px 17px;
        font-size: 15px;
    }

    .bigSecondary {
        padding: 11px 20px;
        font-size: 15px;
    }

    .arrow {
        width: 17px;
        height: 12px;
        padding-right: 7px;
    }
}

@media only screen and (max-width: 850px) {
    .primary, .secondary {
        padding: 5px 9px;
        font-size: 12px;
    }

    .bigPrimary {
        padding: 10px 14px;
        font-size: 13px;
    }

    .bigSecondary {
        padding: 10px 19px;
        font-size: 13px;
    }

    .arrow {
        width: 14px;
        height: 10px;
        padding-right: 7px;
    }
}


