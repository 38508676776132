.container {
    padding: 50px 0 30px;
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    gap: 17px;
    flex-wrap: wrap;
}

.topPadding {
    padding-top: 0;
}

.marginTop {
    margin-top: 50px;
}

.avatar {
    position: relative;
    width: 190px;
    border-radius: 50%;
    margin: 0 40px 15px;
    z-index: 10;
}

.hoverAvatar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 190px;
    border-radius: 50%;
    padding: 10px;
    z-index: 10;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(18, 34, 45, 0.16) 0%, rgba(18, 34, 45, 0.8) 100%);;
}

.changePhoto {
    width: 130px;
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: white;
}

.remove {
    width: 80px;
    position: absolute;
    top: 135px;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: white;
}

.changePhoto:hover, .remove:hover {
    color: #FFC850;
}

.line {
    position: absolute;
    top: 123px;
    left: 50%;
    transform: translateX(-50%);
    width: 138px;
    border: 1px solid #FFC038;
}

.containerSettings {
    justify-content: flex-start;
    gap: 40px 5%;
    padding: 0 40px 10px;
}

.inputSettings {
    width: 30%;
}

.label {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.caption {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: #AFB9C0;
    white-space: nowrap;

}

.bigcont {
    padding-bottom: 50px;
}

.input {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: #243745;
    border: 1.2px solid transparent;
    font-family: var(--main-font-family), sans-serif;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 300;
    color: #EBEBEB;
}

.input:hover {
    border: 1.2px solid #728796;
}

.input:focus {
    border: 1.2px solid #FFC038;
}

.input::placeholder {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #728796;
    letter-spacing: 0.01em;
}

.error {
    font-family: var(--main-font-family), sans-serif;
    font-size: 13px;
    padding-top: 5px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #CF6679;
}

.errorinp, .phoneerr {
    border: 1.2px solid #CF6679 !important;
}

.noterrinp,.phonenot {
    border: 1.2px solid #66CF8A !important;
}

.phone {
    border-radius: 8px;
}

.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}

.radio{
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.button {
    width: 100%;
    display: flex;
    justify-content: end;
}

.inputcont {
    width: 48%;
    height: 120px;
}

.eye {
    width: 21px;
    margin-left: -33px;
    margin-bottom: -5px;
    cursor: pointer;
}

.check {
    display: flex;
    justify-content: center;
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #DCDDDD;
}

.link {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFC038;
    text-decoration: underline;
}

.password {
    padding-left: 70px;
    padding-right: 40px;
}

.lock {
    width: 60px;
    height: 50px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 7px 0 0 7px;
    background: #263D4D;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lockimg {
    width: 25px;
    height: 25px;
}

.drop {
    position: absolute;
    left: 30px;
    top: 63px;
    width: 250px;
    height: 200px;
    background: #2F495C;
    border-radius: 0 12px 12px 12px;
    z-index:20;
}

.triangle {
    position: absolute;
    top: -20px;
    width: 25px;
}

.list {
    position: absolute;
    top: 8%;
    left: 13%;
}

.list li::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url("/public/images/listSquare.png");
    background-size: cover;
    margin-right: 10px;
}


.item {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #EBEBEB;
    padding: 6px 0;
    transition: 0.5s;
}

.contsec {
    justify-content: space-between;
    display: flex;
}

.contthird {
    justify-content: space-between;
    display: flex;
}

.user {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    padding: 10px;
    z-index: 2;
    position: relative;
    cursor: pointer;
    background-position: center !important;
    background-size: cover !important;
}

.user::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 90%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.changed {
    font-family: var(--main-font-family), sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #728796;
    padding: 10px 0 0 25px;
}

.err {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #CF6679;
    display: flex;
    align-items: center;
    padding: 25px 0;
}

.errorImg {
    width: 32px;
    height: auto;
    padding-right: 8px;
}

.emailVerification {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 20px;
    gap: 25px;
}

.mailVerification {
    font-weight: 600;
    letter-spacing: 0.01em;
}

.headingVerification {
    font-family: var(--main-font-family), sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFC038;
}

.textVerification {
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    max-width: 600px;
    color: #EBEBEB;
}

.linkVerification {
    font-family: var(--main-font-family),sans-serif;
    font-size: 17px;
    font-weight: 200;
    color: #FFC038;
}

.spamVerification {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #AFB9C0 ;
    padding-top: 25px;
    margin-bottom: -15px;
}

.timerVerification {
    font-family: var(--main-font-family), sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #FFC038;
    margin-top: 15px;
}

.buttonVerification {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
}

@media only screen and (max-width: 1200px){
    .container {
        padding: 35px 0;
    }

    .radio {
        gap: 0;
        padding: 15px 0;
    }
}

    @media only screen and (max-width: 1060px) {
        .container {
            justify-content: center;
        }

        .inputcont {
            width: 100%;
        }

        .contsec, .contthird {
            padding-bottom: 50px;
        }

        .container {
            padding-bottom: 10px;
        }
    }

@media only screen and (max-width: 800px) {
    .inputcont {
        height: 90px;
    }

    .error {
        padding-top: 0;
    }

    .contsec {
        padding-bottom: 30px;
    }

    .container {
        padding-bottom: 25px;
    }


}

@media only screen and (max-width: 470px) {

    .input,.caption,.input::placeholder{
        font-size: 16px;
    }

 .link, .text {
     font-size: 14px;
 }

 .eye {
     width: 17px;
 }

    .lock {
        height: 48px;
    }
}