
.tit {
    color: #FFF;
    font-family: Teko, serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}
.btndiv{
    margin-top:20px;
    margin-bottom:20px;
    width:100%;
    display: flex;
    justify-content: center;
    gap:50px;

}
.tagOption {
    width: 170px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    cursor: pointer;
    border: 2px solid #AFB9C0;
    color: #AFB9C0;
    background: none;
}

.selected {
    border: 2px solid #FFC038;
    color: #FFC038;
}
.editor{
    padding-top:30px;
    width: 96%;
    margin: 0 auto;
    /*border: 2px solid red;*/
}
.cont{
    border-radius: 32px;
    border: 1px solid rgba(255, 192, 56, 0.33);
    background:  #12222D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 32px;
}
.inpDiv{
    position: relative;
    width: 100%;
    padding-inline:150px ;
    margin-top: 32px;
}
.name{
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}
.name >span{
    color:  #AFB9C0;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.controler{
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    justify-content: space-between;
    column-gap:20px ;
    padding: 20px 26px;
}

.videosContainer {
    position: relative;
    border-radius: 12px;
    background:  #143E59;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 26px;
}

.placeholder {
    display: flex;
    align-items: center;
    gap: 10px;
}

.label{
    position: relative;
}

.controler > div{
    display: flex;
    column-gap: 20px;
}

.img{
    width: 26px;
    height: 26px;
}

.ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/

}
.li{
    width: 100%;
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.images{
    width: 35px;
    height: 25px;
    padding-left: 10px;
    cursor: pointer;
}

.file{
    display: none;
}

.lil{
    width: 100%;
    color: #FFF;
    font-family: var(--main-font-family), serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

}

.input{
    width: 100%;
    color: #ffffff;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}
.area{
    width: 100%;
    color: #ffffff;
    height: 150px;
    resize: none;
    font-family: var(--main-font-family), serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
}
.arrow {
    color: #728796;
    font-size: 23px;
    margin-left: -30px;
    margin-bottom: -5px;
    cursor: pointer;
    transition: 0.2s;
}

.arrowhov {
    rotate: -180deg;
}
.btns{
    margin-top: 30px;
}
.quizadd {
    cursor: pointer;
    width: 100%;
    color: #FFC038;
    font-family: var(--main-font-family), serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dropdown {
    max-height: 155px;
    overflow-y: auto;
    background: #143E59;
    border-radius: 12px;
    margin-top: 10px;
}

.dropdown::-webkit-scrollbar{
    width: 8px;
}

.dropdown::-webkit-scrollbar-thumb{
    height: 30px;
    border-radius: 5px;
}

.element{
    font-family: var(--main-font-family), sans-serif;
    font-size: 17px;
    color: #EBEBEB;
    margin-top: 7px;
    cursor: pointer;
    padding: 5px 11px 5px 20px;
}

.element:hover{
    color: #f8bf06;
}
