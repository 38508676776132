.head {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    line-height: 0.9;
    padding-top: 4%;
    color: #FFC038;
}

.head_am {
    font-size: 40px;
}

.head_en {
    font-size: 57px;
}

.head_ru {
    font-size: 45px;
}

.sliderBlock{
    padding: 50px 45px 30px;
    margin: 0 auto;
}


.link {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #AFB9C0;
    padding: 10px 75px 50px 0;
    text-decoration: none;
}

.link:hover {
    color: #FFCB5C;
}

.link:active {
    color: #FFB81E;
}

.linkcont {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.coursesContainer {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
}

@media only screen and (max-width: 1300px) {
    .linkcont {
        justify-content: center;
        padding: 45px 0 0 40px;
    }
}

@media only screen and (max-width: 600px) {
    .sliderBlock {
        padding: 25px 10px 10px;
    }

    .link {
        font-size: 16px;
    }

    .head_am {
        font-size: 22px !important;
    }
    .head {
        font-size: 30px;
    }
}
