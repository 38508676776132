.wrapper  {
    overflow-x: auto;
    padding-bottom: 10px;
}


.wrapper::-webkit-scrollbar {
    height: 5px;
}

.wrapper::-webkit-scrollbar-track {
    background: #152836;

}

.wrapper::-webkit-scrollbar-thumb {
    background-color: #FFC038;
    border-radius: 5px;
}

.wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #243745;
}

.view {
    font-family: var(--main-font-family), sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #AFB9C0;
    border-radius: 25px;
    padding: 8px 0;
    border: #AFB9C0 solid 1px;
    cursor: pointer;
    background: none;
    width: 100px;
}

.view:hover {
    color: #FFC038;
    border: #FFC038 1px solid;
}

.active {
    background: transparent;
    color: #FFC038;
    border: #FFC038 1px solid;
}

.label {
    font-family: var(--main-font-family), sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #FFC038;
}

.arrow {
    font-size: 36px;
    color: #FFC038;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.arrow:hover {
    color: #FFC850;
    transform: scale(1.3);
    transition: 0.2s ease-in-out;
}

.toolbar {
    display: flex;
    flex-direction: column;
    gap: 45px;
    position: absolute;
    right: 2%;
    width: 79%;
}

.viewCont {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.end {
    justify-content: flex-end;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 25px;
}

.labelCont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 110px;
}

.plus {
    font-size: 26px;
    align-self: center;
}

@media only screen and (max-width: 850px) {

    .toolbar, .buttons {
        width: 100%;
    }

    .toolbar {
        right: 0;
        gap: 30px;
        padding-top: 10px;
    }

    .buttons {
        justify-content: center;
        gap: 20px;
    }

    .label {
        font-size: 26px;
        text-align: center;
    }

    .arrow {
        font-size: 28px;
    }

    .labelCont {
        gap: 45px;
        width: 100%;
    }

    .view {
        font-size: 14px;
        width: 20%;
    }
}

@media only screen and (max-width: 450px) {
    .buttons {
        justify-content: space-around;
        gap: 10px;
        padding: 0 5px;
    }

    .view {
        font-size: 14px;
        width: 30%;
    }

    .label {
        font-size: 22px;
    }

    .labelCont {
        gap: 30px;
    }
}



