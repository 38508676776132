.textspan{
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #FFFFFF;
}
.titleDiv{
    width: 95%;
    color:#FFFFFF;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    /*background: #162A38;*/
    padding: 21px 31px 20px 30px;
    border-radius: 45px;
    /*display: flex;*/
    /*align-items: center;*/
    /*text-align: left;*/
    /*margin-bottom: 40px;*/
    /*margin-top: 60px;*/
    /*column-gap: 14px;*/
}
.titdiv{
    width: 95%;
    color:#FFFFFF;
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    background: #162A38;
    padding: 7px 31px 7px 30px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 60px;
    column-gap: 14px;
}
.correctAnswer {
    font-size: 22px;
    text-align: left;
    border-radius: 42px;
    border: 1px solid #F4B41A;
    filter: drop-shadow(0px 0px 30px rgba(255, 192, 56, 0.10));
    background: #0B1A25;
    padding: 15px 31px;
    color: #EBEBEB;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 15px;
}
.indexbut{
    font-family: var(--english-font-teko), serif;
    font-size: 32px;
    font-weight: 500;
}
.currentIndex{
    color: #FFC038;
    font-family: var(--english-font-teko), serif;
    font-size: 64px;
    margin-top: 10px;
}

.only{
    width: 100%;
    display: flex;
}

.courscont{
    width: 98%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}
.courscont::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 115%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}
.ansver{
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.btn{
    font-size: 22px;
    text-align: left;
    background: #162A38;
    padding: 15px 31px;
    border: none;
    border-radius: 42px ;
    color: #EBEBEB;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 15px;
}
.textin{
    font-family: var(--main-font-family), sans-serif;
    margin-top: -2px;
    font-size: 22px;
}
.divquest{
    margin-top: 90px;
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
}
.que{
    font-family: var(--main-font-family), sans-serif;
    font-size: 22px;
    color: #12222D;
    background: #FFC038;
    padding: 10px 35px;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #FFC038

}


.selected {
    background-color: #a2874c;
}
.que:hover{
    border: 1px solid #FFC850;
    background:  #FFCB5C
}
.que:active{
    border: 1px solid #FFB30E;
    background: #FFB81E
}
.points{
    position: absolute;
    bottom: 47px;
    left: 83px;
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    color: #728796;
    font-weight: 500;
    line-height: 28.8px;
}

