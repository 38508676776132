.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
}

.certificate {
    grid-column: 1 span;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 32px;
    padding: 20px 30px 35px;
    position: relative;
    z-index: 2;
}

.courseName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}

.certificateCourseName {
    font-family: var(--main-font-family), sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: #143E59;
    letter-spacing: 0.02em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.certificate::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px  1.5px;
    background: linear-gradient(-10deg, #F4B41A 0%, rgba(255, 255, 255, 0) 120%, rgba(244, 180, 26, 0) 100%);
    mask:
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%) content-box,
            linear-gradient(rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.certImg {
    width: 100%;
    height: auto;
    aspect-ratio: 7/5
}

.title {
    font-family: var(--main-font-family), sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #AFB9C0;
}

.downloadAndType {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 5px
}

.text {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 1.35;
    color: white;
}

.information {
    font-family: var(--main-font-family), sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #AFB9C0;
    padding-top: 20px;
}

.userName {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 100px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.02em;
    color: #12222D;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.certificateComponent {
    position: relative;
}

.date {
    font-family: var(--main-font-family), sans-serif;
    font-size: 45px;
    font-weight: 500;
    color: #143E59;
    position: absolute;
}

.download {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.downloadIcon {
    font-size: 22px;
}

.noCertificate {
    padding: 70px 70px 75px 20px;
    border-radius: 32px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.noCertificate::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 32px;
    padding: 1.5px 1.5px;
    background: linear-gradient(0deg, #F4B41A 0%, rgba(255, 255, 255, 0) 110%, rgba(244, 180, 26, 0) 100%);
    mask: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%) content-box, linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    mask-composite: exclude;
    z-index: -1;
}

.image {
    max-width: 380px;
    height: auto;
    padding-right: 50px;
}

.textCont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 65%;
}

.name {
    font-family: var(--english-font-teko), sans-serif;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.textNoCertificate {
    font-family: var(--english-font-teko), sans-serif;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.textNoCertificate_en {
    font-size: 45px;
}

.textNoCertificate_am {
    font-size: 38px;
}

.yellow {
    color: #FFC038;
}